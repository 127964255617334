.fade {
  @include transition($transition-fade);

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
  &.in {
    display: block;
  }
}

// .collapse {
//   display: none;

//   &.in {
//     display: block;
//   }
// }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);
}
