.player-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  overflow-y: hidden;
  text-align: left;
  z-index: 10;
  background-color: rgba(121, 180, 76, 0.9);
  color: #ffffff !important;
  padding: 10px;
}

.player-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.player-img {
  display: block;
  width: 100%;
  height: auto;
}