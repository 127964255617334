@import url(//fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp);
.player-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  overflow-y: hidden;
  text-align: left;
  z-index: 10;
  background-color: rgba(121, 180, 76, 0.9);
  color: #ffffff !important;
  padding: 10px;
}

.player-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.player-img {
  display: block;
  width: 100%;
  height: auto;
}
.custom-dot {
  fill: #FF5A8A;
  r: 5;
  stroke: #FFF;
  stroke-width: 2px;
}
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #55A8E6;
  --indigo: #6610f2;
  --purple: #CC14F5;
  --pink: #EC6592;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #FAD732;
  --green: #1EE831;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFFFFF;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ff5a8a;
  --secondary: #6a6a6a;
  --success: #00a97a;
  --info: #0092E4;
  --warning: #F57C00;
  --danger: #F44336;
  --inverse: #690E2C;
  --primary-light: #ff8dae;
  --primary-dark: #ff2766;
  --secondary-light: #848484;
  --secondary-dark: #515151;
  --success-light: #00dc9f;
  --success-dark: #007655;
  --info-light: #18acff;
  --info-dark: #0071b1;
  --warning-light: #ff9529;
  --warning-dark: #c26200;
  --danger-light: #f77066;
  --danger-dark: #ea1c0d;
  --green-light: #4ced5b;
  --green-dark: #14bf24;
  --pink-light: #f292b2;
  --pink-dark: #e63872;
  --purple-light: #d745f7;
  --purple-dark: #a909cd;
  --inverse-light: #96143f;
  --inverse-dark: #3c0819;
  --yellow-light: #fbe164;
  --yellow-dark: #f3ca06;
  --primary: #ff5a8a;
  --secondary: #6a6a6a;
  --success: #00a97a;
  --info: #0092E4;
  --warning: #F57C00;
  --danger: #F44336;
  --light: #f8f9fa;
  --primary-light: #ff8dae;
  --primary-dark: #ff2766;
  --secondary-light: #848484;
  --secondary-dark: #515151;
  --purple-light: #d745f7;
  --purple: #CC14F5;
  --purple-dark: #a909cd;
  --inverse: #690E2C;
  --pink: #EC6592;
  --green: #1EE831;
  --blue: #55A8E6;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.52857;
  color: #656565;
  text-align: left;
  background-color: #fafbfc; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  color: #ff5a8a;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #ff0e54; }

a:not([href]):not([tabindex]) {
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #515151;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  margin-bottom: 5px; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1; }

h1,
.h1 {
  font-size: 2.5rem; }

h2,
.h2 {
  font-size: 2rem; }

h3,
.h3 {
  font-size: 1.75rem; }

h4,
.h4 {
  font-size: 1.5rem; }

h5,
.h5 {
  font-size: 1.25rem; }

h6,
.h6 {
  font-size: 0.8rem !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

blockquote {
  padding: 0.5rem 1rem;
  margin: 0 0 1rem;
  font-size: 1.25rem;
  border-left: 5px solid #edf1f2; }
  blockquote p:last-child,
  blockquote ul:last-child,
  blockquote ol:last-child {
    margin-bottom: 0; }
  blockquote footer,
  blockquote small,
  blockquote .small {
    display: block;
    font-size: 80%;
    line-height: 1.52857;
    color: #6c757d; }
    blockquote footer:before,
    blockquote small:before,
    blockquote .small:before {
      content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.125rem;
  background-color: #fafbfc;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #EC6592;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #656565; }
  .table th,
  .table td {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid #eee; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eee; }
  .table tbody + tbody {
    border-top: 2px solid #eee; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #eee; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eee; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafbfc; }

.table-hover tbody tr:hover {
  color: #656565;
  background-color: #fafbfc; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffd1de; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ffa9c2; }

.table-hover .table-primary:hover {
  background-color: #ffb8cc; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #ffb8cc; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d5d5d5; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b2b2b2; }

.table-hover .table-secondary:hover {
  background-color: #c8c8c8; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8c8c8; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8e7da; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ad2ba; }

.table-hover .table-success:hover {
  background-color: #a5e1d0; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a5e1d0; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8e0f7; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7ac6f1; }

.table-hover .table-info:hover {
  background-color: #a1d6f4; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a1d6f4; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcdab8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fabb7a; }

.table-hover .table-warning:hover {
  background-color: #fbcda0; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fbcda0; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f99d96; }

.table-hover .table-danger:hover {
  background-color: #fbb3af; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fbb3af; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: #ffdfe8; }

.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: #ffc4d5; }

.table-hover .table-primary-light:hover {
  background-color: #ffc6d6; }
  .table-hover .table-primary-light:hover > td,
  .table-hover .table-primary-light:hover > th {
    background-color: #ffc6d6; }

.table-primary-dark,
.table-primary-dark > th,
.table-primary-dark > td {
  background-color: #ffc3d4; }

.table-primary-dark th,
.table-primary-dark td,
.table-primary-dark thead th,
.table-primary-dark tbody + tbody {
  border-color: #ff8faf; }

.table-hover .table-primary-dark:hover {
  background-color: #ffaac2; }
  .table-hover .table-primary-dark:hover > td,
  .table-hover .table-primary-dark:hover > th {
    background-color: #ffaac2; }

.table-secondary-light,
.table-secondary-light > th,
.table-secondary-light > td {
  background-color: gainsboro; }

.table-secondary-light th,
.table-secondary-light td,
.table-secondary-light thead th,
.table-secondary-light tbody + tbody {
  border-color: #bfbfbf; }

.table-hover .table-secondary-light:hover {
  background-color: #cfcfcf; }
  .table-hover .table-secondary-light:hover > td,
  .table-hover .table-secondary-light:hover > th {
    background-color: #cfcfcf; }

.table-secondary-dark,
.table-secondary-dark > th,
.table-secondary-dark > td {
  background-color: #cecece; }

.table-secondary-dark th,
.table-secondary-dark td,
.table-secondary-dark thead th,
.table-secondary-dark tbody + tbody {
  border-color: #a4a4a4; }

.table-hover .table-secondary-dark:hover {
  background-color: #c1c1c1; }
  .table-hover .table-secondary-dark:hover > td,
  .table-hover .table-secondary-dark:hover > th {
    background-color: #c1c1c1; }

.table-purple-light,
.table-purple-light > th,
.table-purple-light > td {
  background-color: #f4cbfd; }

.table-purple-light th,
.table-purple-light td,
.table-purple-light thead th,
.table-purple-light tbody + tbody {
  border-color: #ea9efb; }

.table-hover .table-purple-light:hover {
  background-color: #efb2fc; }
  .table-hover .table-purple-light:hover > td,
  .table-hover .table-purple-light:hover > th {
    background-color: #efb2fc; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #f1bdfc; }

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #e485fa; }

.table-hover .table-purple:hover {
  background-color: #eca5fb; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #eca5fb; }

.table-purple-dark,
.table-purple-dark > th,
.table-purple-dark > td {
  background-color: #e7baf1; }

.table-purple-dark th,
.table-purple-dark td,
.table-purple-dark thead th,
.table-purple-dark tbody + tbody {
  border-color: #d37fe5; }

.table-hover .table-purple-dark:hover {
  background-color: #e0a5ed; }
  .table-hover .table-purple-dark:hover > td,
  .table-hover .table-purple-dark:hover > th {
    background-color: #e0a5ed; }

.table-inverse,
.table-inverse > th,
.table-inverse > td {
  background-color: #d5bcc4; }

.table-inverse th,
.table-inverse td,
.table-inverse thead th,
.table-inverse tbody + tbody {
  border-color: #b18291; }

.table-hover .table-inverse:hover {
  background-color: #cbacb6; }
  .table-hover .table-inverse:hover > td,
  .table-hover .table-inverse:hover > th {
    background-color: #cbacb6; }

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #fad4e0; }

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody + tbody {
  border-color: #f5afc6; }

.table-hover .table-pink:hover {
  background-color: #f7bdd0; }
  .table-hover .table-pink:hover > td,
  .table-hover .table-pink:hover > th {
    background-color: #f7bdd0; }

.table-green,
.table-green > th,
.table-green > td {
  background-color: #c0f9c5; }

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #8af394; }

.table-hover .table-green:hover {
  background-color: #a9f7b0; }
  .table-hover .table-green:hover > td,
  .table-hover .table-green:hover > th {
    background-color: #a9f7b0; }

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #cfe7f8; }

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #a7d2f2; }

.table-hover .table-blue:hover {
  background-color: #b9dcf5; }
  .table-hover .table-blue:hover > td,
  .table-hover .table-blue:hover > th {
    background-color: #b9dcf5; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #fafbfc; }

.table-hover .table-active:hover {
  background-color: #eaeef2; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #eaeef2; }

.table .thead-dark th {
  color: #FFFFFF;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #eee; }

.table-dark {
  color: #FFFFFF;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFFFFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

@media screen and (max-width: 767px) {
  .table-responsive > .table {
    margin-bottom: 0; }
    .table-responsive > .table > thead > tr > th,
    .table-responsive > .table > thead > tr > td,
    .table-responsive > .table > tbody > tr > th,
    .table-responsive > .table > tbody > tr > td,
    .table-responsive > .table > tfoot > tr > th,
    .table-responsive > .table > tfoot > tr > td {
      white-space: nowrap; } }

.form-control {
  display: block;
  width: 100%;
  height: 2.1875rem;
  padding: 0.375rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.52857;
  color: #495057;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #DDE6E9;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #FFFFFF;
    border-color: #66afe9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 90, 138, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #b7bac9;
    opacity: 1; }
  .form-control::placeholder {
    color: #b7bac9;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #F5F5F5;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #FFFFFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.52857; }

.col-form-label-lg {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.3125rem + 1px);
  padding-bottom: calc(0.3125rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.52857;
  color: #656565;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc( 1.5em + 0.625rem + 2px);
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc( 1.5em + 1.25rem + 2px);
  padding: 0.625rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.375rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #515151; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00a97a; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.52857;
  color: #FFFFFF;
  background-color: rgba(0, 169, 122, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00a97a;
  padding-right: calc( 1.52857em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: center right calc( 0.38214em + 0.1875rem);
  background-size: calc( 0.76429em + 0.375rem) calc( 0.76429em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #00a97a;
    box-shadow: 0 0 0 0.2rem rgba(0, 169, 122, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc( 1.52857em + 0.75rem);
  background-position: top calc( 0.38214em + 0.1875rem) right calc( 0.38214em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #00a97a;
  padding-right: calc( (1em + 0.75rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, none #FFFFFF no-repeat center right 2rem/calc( 0.76429em + 0.375rem) calc( 0.76429em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #00a97a;
    box-shadow: 0 0 0 0.2rem rgba(0, 169, 122, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00a97a; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00a97a; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #00a97a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00dc9f;
  background-color: #00dc9f; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 169, 122, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00a97a; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00a97a; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00a97a;
  box-shadow: 0 0 0 0.2rem rgba(0, 169, 122, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #F44336; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.52857;
  color: #FFFFFF;
  background-color: rgba(244, 67, 54, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F44336;
  padding-right: calc( 1.52857em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: center right calc( 0.38214em + 0.1875rem);
  background-size: calc( 0.76429em + 0.375rem) calc( 0.76429em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #F44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc( 1.52857em + 0.75rem);
  background-position: top calc( 0.38214em + 0.1875rem) right calc( 0.38214em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #F44336;
  padding-right: calc( (1em + 0.75rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, none #FFFFFF no-repeat center right 2rem/calc( 0.76429em + 0.375rem) calc( 0.76429em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #F44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F44336; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #F44336; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #F44336; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f77066;
  background-color: #f77066; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #F44336; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #F44336; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #F44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #656565;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1rem;
  font-size: 1rem;
  line-height: 1.52857;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #656565;
    text-decoration: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #FFFFFF;
  background-color: #ff5a8a;
  border-color: #ff5a8a; }
  .btn-primary:hover {
    color: #FFFFFF;
    background-color: #ff346f;
    border-color: #ff2766; }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFFFFF;
    background-color: #ff5a8a;
    border-color: #ff5a8a; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ff2766;
    border-color: #ff1a5d; }

.btn-secondary {
  color: #FFFFFF;
  background-color: #6a6a6a;
  border-color: #6a6a6a; }
  .btn-secondary:hover {
    color: #FFFFFF;
    background-color: #575757;
    border-color: #515151; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFFFFF;
    background-color: #6a6a6a;
    border-color: #6a6a6a; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #515151;
    border-color: #4a4a4a; }

.btn-success {
  color: #FFFFFF;
  background-color: #00a97a;
  border-color: #00a97a; }
  .btn-success:hover {
    color: #FFFFFF;
    background-color: #00835e;
    border-color: #007655; }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFFFFF;
    background-color: #00a97a;
    border-color: #00a97a; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #007655;
    border-color: #00694c; }

.btn-info {
  color: #FFFFFF;
  background-color: #0092E4;
  border-color: #0092E4; }
  .btn-info:hover {
    color: #FFFFFF;
    background-color: #007abe;
    border-color: #0071b1; }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFFFFF;
    background-color: #0092E4;
    border-color: #0092E4; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0071b1;
    border-color: #0069a4; }

.btn-warning {
  color: #FFFFFF;
  background-color: #F57C00;
  border-color: #F57C00; }
  .btn-warning:hover {
    color: #FFFFFF;
    background-color: #cf6900;
    border-color: #c26200; }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #FFFFFF;
    background-color: #F57C00;
    border-color: #F57C00; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #FFFFFF;
    background-color: #c26200;
    border-color: #b55c00; }

.btn-danger {
  color: #FFFFFF;
  background-color: #F44336;
  border-color: #F44336; }
  .btn-danger:hover {
    color: #FFFFFF;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFFFFF;
    background-color: #F44336;
    border-color: #F44336; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ea1c0d;
    border-color: #de1b0c; }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }

.btn-primary-light {
  color: #212529;
  background-color: #ff8dae;
  border-color: #ff8dae; }
  .btn-primary-light:hover {
    color: #212529;
    background-color: #ff6793;
    border-color: #ff5a8a; }
  .btn-primary-light.disabled, .btn-primary-light:disabled {
    color: #212529;
    background-color: #ff8dae;
    border-color: #ff8dae; }
  .btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ff5a8a;
    border-color: #ff4d81; }

.btn-primary-dark {
  color: #FFFFFF;
  background-color: #ff2766;
  border-color: #ff2766; }
  .btn-primary-dark:hover {
    color: #FFFFFF;
    background-color: #ff014b;
    border-color: #f30047; }
  .btn-primary-dark.disabled, .btn-primary-dark:disabled {
    color: #FFFFFF;
    background-color: #ff2766;
    border-color: #ff2766; }
  .btn-primary-dark:not(:disabled):not(.disabled):active, .btn-primary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-primary-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #f30047;
    border-color: #e60043; }

.btn-secondary-light {
  color: #FFFFFF;
  background-color: #848484;
  border-color: #848484; }
  .btn-secondary-light:hover {
    color: #FFFFFF;
    background-color: #707070;
    border-color: #6a6a6a; }
  .btn-secondary-light.disabled, .btn-secondary-light:disabled {
    color: #FFFFFF;
    background-color: #848484;
    border-color: #848484; }
  .btn-secondary-light:not(:disabled):not(.disabled):active, .btn-secondary-light:not(:disabled):not(.disabled).active,
  .show > .btn-secondary-light.dropdown-toggle {
    color: #FFFFFF;
    background-color: #6a6a6a;
    border-color: #646464; }

.btn-secondary-dark {
  color: #FFFFFF;
  background-color: #515151;
  border-color: #515151; }
  .btn-secondary-dark:hover {
    color: #FFFFFF;
    background-color: #3d3d3d;
    border-color: #373737; }
  .btn-secondary-dark.disabled, .btn-secondary-dark:disabled {
    color: #FFFFFF;
    background-color: #515151;
    border-color: #515151; }
  .btn-secondary-dark:not(:disabled):not(.disabled):active, .btn-secondary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-secondary-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #373737;
    border-color: #313131; }

.btn-purple-light {
  color: #FFFFFF;
  background-color: #d745f7;
  border-color: #d745f7; }
  .btn-purple-light:hover {
    color: #FFFFFF;
    background-color: #cf20f6;
    border-color: #cc14f5; }
  .btn-purple-light.disabled, .btn-purple-light:disabled {
    color: #FFFFFF;
    background-color: #d745f7;
    border-color: #d745f7; }
  .btn-purple-light:not(:disabled):not(.disabled):active, .btn-purple-light:not(:disabled):not(.disabled).active,
  .show > .btn-purple-light.dropdown-toggle {
    color: #FFFFFF;
    background-color: #cc14f5;
    border-color: #c80af2; }

.btn-purple {
  color: #FFFFFF;
  background-color: #CC14F5;
  border-color: #CC14F5; }
  .btn-purple:hover {
    color: #FFFFFF;
    background-color: #b409d9;
    border-color: #a909cd; }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #FFFFFF;
    background-color: #CC14F5;
    border-color: #CC14F5; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #FFFFFF;
    background-color: #a909cd;
    border-color: #9f08c1; }

.btn-purple-dark {
  color: #FFFFFF;
  background-color: #a909cd;
  border-color: #a909cd; }
  .btn-purple-dark:hover {
    color: #FFFFFF;
    background-color: #8b07a9;
    border-color: #81079c; }
  .btn-purple-dark.disabled, .btn-purple-dark:disabled {
    color: #FFFFFF;
    background-color: #a909cd;
    border-color: #a909cd; }
  .btn-purple-dark:not(:disabled):not(.disabled):active, .btn-purple-dark:not(:disabled):not(.disabled).active,
  .show > .btn-purple-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #81079c;
    border-color: #770690; }

.btn-inverse {
  color: #FFFFFF;
  background-color: #690E2C;
  border-color: #690E2C; }
  .btn-inverse:hover {
    color: #FFFFFF;
    background-color: #470a1e;
    border-color: #3c0819; }
  .btn-inverse.disabled, .btn-inverse:disabled {
    color: #FFFFFF;
    background-color: #690E2C;
    border-color: #690E2C; }
  .btn-inverse:not(:disabled):not(.disabled):active, .btn-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-inverse.dropdown-toggle {
    color: #FFFFFF;
    background-color: #3c0819;
    border-color: #310714; }

.btn-pink {
  color: #FFFFFF;
  background-color: #EC6592;
  border-color: #EC6592; }
  .btn-pink:hover {
    color: #FFFFFF;
    background-color: #e8437a;
    border-color: #e63872; }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #FFFFFF;
    background-color: #EC6592;
    border-color: #EC6592; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #FFFFFF;
    background-color: #e63872;
    border-color: #e52c6a; }

.btn-green {
  color: #212529;
  background-color: #1EE831;
  border-color: #1EE831; }
  .btn-green:hover {
    color: #FFFFFF;
    background-color: #15cb26;
    border-color: #14bf24; }
  .btn-green.disabled, .btn-green:disabled {
    color: #212529;
    background-color: #1EE831;
    border-color: #1EE831; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #FFFFFF;
    background-color: #14bf24;
    border-color: #12b422; }

.btn-blue {
  color: #212529;
  background-color: #55A8E6;
  border-color: #55A8E6; }
  .btn-blue:hover {
    color: #FFFFFF;
    background-color: #3497e1;
    border-color: #2991df; }
  .btn-blue.disabled, .btn-blue:disabled {
    color: #212529;
    background-color: #55A8E6;
    border-color: #55A8E6; }
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #FFFFFF;
    background-color: #2991df;
    border-color: #208bdb; }

.btn-outline-primary {
  color: #ff5a8a;
  border-color: #ff5a8a; }
  .btn-outline-primary:hover {
    color: #FFFFFF;
    background-color: #ff5a8a;
    border-color: #ff5a8a; }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ff5a8a;
    border-color: #ff5a8a; }

.btn-outline-secondary {
  color: #6a6a6a;
  border-color: #6a6a6a; }
  .btn-outline-secondary:hover {
    color: #FFFFFF;
    background-color: #6a6a6a;
    border-color: #6a6a6a; }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #6a6a6a;
    border-color: #6a6a6a; }

.btn-outline-success {
  color: #00a97a;
  border-color: #00a97a; }
  .btn-outline-success:hover {
    color: #FFFFFF;
    background-color: #00a97a;
    border-color: #00a97a; }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #FFFFFF;
    background-color: #00a97a;
    border-color: #00a97a; }

.btn-outline-info {
  color: #0092E4;
  border-color: #0092E4; }
  .btn-outline-info:hover {
    color: #FFFFFF;
    background-color: #0092E4;
    border-color: #0092E4; }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #FFFFFF;
    background-color: #0092E4;
    border-color: #0092E4; }

.btn-outline-warning {
  color: #F57C00;
  border-color: #F57C00; }
  .btn-outline-warning:hover {
    color: #FFFFFF;
    background-color: #F57C00;
    border-color: #F57C00; }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #FFFFFF;
    background-color: #F57C00;
    border-color: #F57C00; }

.btn-outline-danger {
  color: #F44336;
  border-color: #F44336; }
  .btn-outline-danger:hover {
    color: #FFFFFF;
    background-color: #F44336;
    border-color: #F44336; }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #FFFFFF;
    background-color: #F44336;
    border-color: #F44336; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-outline-primary-light {
  color: #ff8dae;
  border-color: #ff8dae; }
  .btn-outline-primary-light:hover {
    color: #212529;
    background-color: #ff8dae;
    border-color: #ff8dae; }
  .btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
    background-color: transparent; }
  .btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light.dropdown-toggle {
    color: #212529;
    background-color: #ff8dae;
    border-color: #ff8dae; }

.btn-outline-primary-dark {
  color: #ff2766;
  border-color: #ff2766; }
  .btn-outline-primary-dark:hover {
    color: #FFFFFF;
    background-color: #ff2766;
    border-color: #ff2766; }
  .btn-outline-primary-dark.disabled, .btn-outline-primary-dark:disabled {
    background-color: transparent; }
  .btn-outline-primary-dark:not(:disabled):not(.disabled):active, .btn-outline-primary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #ff2766;
    border-color: #ff2766; }

.btn-outline-secondary-light {
  color: #848484;
  border-color: #848484; }
  .btn-outline-secondary-light:hover {
    color: #FFFFFF;
    background-color: #848484;
    border-color: #848484; }
  .btn-outline-secondary-light.disabled, .btn-outline-secondary-light:disabled {
    background-color: transparent; }
  .btn-outline-secondary-light:not(:disabled):not(.disabled):active, .btn-outline-secondary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary-light.dropdown-toggle {
    color: #FFFFFF;
    background-color: #848484;
    border-color: #848484; }

.btn-outline-secondary-dark {
  color: #515151;
  border-color: #515151; }
  .btn-outline-secondary-dark:hover {
    color: #FFFFFF;
    background-color: #515151;
    border-color: #515151; }
  .btn-outline-secondary-dark.disabled, .btn-outline-secondary-dark:disabled {
    background-color: transparent; }
  .btn-outline-secondary-dark:not(:disabled):not(.disabled):active, .btn-outline-secondary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #515151;
    border-color: #515151; }

.btn-outline-purple-light {
  color: #d745f7;
  border-color: #d745f7; }
  .btn-outline-purple-light:hover {
    color: #FFFFFF;
    background-color: #d745f7;
    border-color: #d745f7; }
  .btn-outline-purple-light.disabled, .btn-outline-purple-light:disabled {
    background-color: transparent; }
  .btn-outline-purple-light:not(:disabled):not(.disabled):active, .btn-outline-purple-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple-light.dropdown-toggle {
    color: #FFFFFF;
    background-color: #d745f7;
    border-color: #d745f7; }

.btn-outline-purple {
  color: #CC14F5;
  border-color: #CC14F5; }
  .btn-outline-purple:hover {
    color: #FFFFFF;
    background-color: #CC14F5;
    border-color: #CC14F5; }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #FFFFFF;
    background-color: #CC14F5;
    border-color: #CC14F5; }

.btn-outline-purple-dark {
  color: #a909cd;
  border-color: #a909cd; }
  .btn-outline-purple-dark:hover {
    color: #FFFFFF;
    background-color: #a909cd;
    border-color: #a909cd; }
  .btn-outline-purple-dark.disabled, .btn-outline-purple-dark:disabled {
    background-color: transparent; }
  .btn-outline-purple-dark:not(:disabled):not(.disabled):active, .btn-outline-purple-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple-dark.dropdown-toggle {
    color: #FFFFFF;
    background-color: #a909cd;
    border-color: #a909cd; }

.btn-outline-inverse {
  color: #690E2C;
  border-color: #690E2C; }
  .btn-outline-inverse:hover {
    color: #FFFFFF;
    background-color: #690E2C;
    border-color: #690E2C; }
  .btn-outline-inverse.disabled, .btn-outline-inverse:disabled {
    background-color: transparent; }
  .btn-outline-inverse:not(:disabled):not(.disabled):active, .btn-outline-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-outline-inverse.dropdown-toggle {
    color: #FFFFFF;
    background-color: #690E2C;
    border-color: #690E2C; }

.btn-outline-pink {
  color: #EC6592;
  border-color: #EC6592; }
  .btn-outline-pink:hover {
    color: #FFFFFF;
    background-color: #EC6592;
    border-color: #EC6592; }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #FFFFFF;
    background-color: #EC6592;
    border-color: #EC6592; }

.btn-outline-green {
  color: #1EE831;
  border-color: #1EE831; }
  .btn-outline-green:hover {
    color: #212529;
    background-color: #1EE831;
    border-color: #1EE831; }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #212529;
    background-color: #1EE831;
    border-color: #1EE831; }

.btn-outline-blue {
  color: #55A8E6;
  border-color: #55A8E6; }
  .btn-outline-blue:hover {
    color: #212529;
    background-color: #55A8E6;
    border-color: #55A8E6; }
  .btn-outline-blue.disabled, .btn-outline-blue:disabled {
    background-color: transparent; }
  .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #212529;
    background-color: #55A8E6;
    border-color: #55A8E6; }

.btn-link {
  font-weight: 400;
  color: #ff5a8a;
  text-decoration: none; }
  .btn-link:hover {
    color: #ff0e54;
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.625rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.375rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapse.in {
  display: block; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #656565;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem; }
  .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.52857;
    color: #212529;
    white-space: nowrap; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #cccccc;
  cursor: not-allowed; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFFFFF;
    text-decoration: none;
    background-color: #ff5a8a; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #cccccc;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.open > .dropdown-menu {
  display: block; }

.open > a {
  outline: 0; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.46875rem;
  padding-left: 0.46875rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.52857;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #DDE6E9;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc( 1.5em + 1.25rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.625rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.375rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc( 1.5em + 0.625rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.52857rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFFFFF;
    border-color: #ff5a8a;
    background-color: #ff5a8a; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(255, 90, 138, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #66afe9; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFFFFF;
    background-color: #ffdae4;
    border-color: #ffdae4; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #F5F5F5; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.26429rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #FFFFFF;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.26429rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #ff5a8a;
  background-color: #ff5a8a; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFFFFF' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 90, 138, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(255, 90, 138, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 90, 138, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.26429rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc( 1rem - 4px);
    height: calc( 1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #FFFFFF;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(255, 90, 138, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: 2.1875rem;
  padding: 0.375rem 2rem 0.375rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.52857;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  background-color: #FFFFFF;
  border: 1px solid #DDE6E9;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 90, 138, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #FFFFFF; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc( 1.5em + 0.625rem + 2px);
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  padding-left: 0.625rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc( 1.5em + 1.25rem + 2px);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.25rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2.1875rem;
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 2.1875rem;
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #66afe9;
    box-shadow: 0 0 0 0.2rem rgba(255, 90, 138, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #F5F5F5; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2.1875rem;
  padding: 0.375rem 1rem;
  font-weight: 400;
  line-height: 1.52857;
  color: #495057;
  background-color: #FFFFFF;
  border: 1px solid #DDE6E9;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc( 1.52857em + 0.75rem);
    padding: 0.375rem 1rem;
    line-height: 1.52857;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fafbfc, 0 0 0 0.2rem rgba(255, 90, 138, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fafbfc, 0 0 0 0.2rem rgba(255, 90, 138, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fafbfc, 0 0 0 0.2rem rgba(255, 90, 138, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #ff5a8a;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #ff5a8a;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #ff5a8a;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.tabpanel > .nav-tabs > .nav-item > .nav-link {
  font-weight: bold !important; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #ff5a8a; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.30893rem;
  padding-bottom: 0.30893rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFFFFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFFFFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFFFFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFFFFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc( 0.25rem - 1px) calc( 0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc( 0.25rem - 1px) calc( 0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc( 0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc( 0.25rem - 1px);
  border-top-right-radius: calc( 0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc( 0.25rem - 1px);
  border-bottom-left-radius: calc( 0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fafafa;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #c1c2c3;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #656565; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ff5a8a;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #ff0e54;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 90, 138, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFFFFF;
  background-color: #ff5a8a;
  border-color: #ff5a8a; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #FFFFFF;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFFFFF;
  background-color: #ff5a8a; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFFFFF;
    background-color: #ff2766; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 90, 138, 0.5); }

.badge-secondary {
  color: #FFFFFF;
  background-color: #6a6a6a; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFFFFF;
    background-color: #515151; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(106, 106, 106, 0.5); }

.badge-success {
  color: #FFFFFF;
  background-color: #00a97a; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFFFFF;
    background-color: #007655; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 169, 122, 0.5); }

.badge-info {
  color: #FFFFFF;
  background-color: #0092E4; }
  a.badge-info:hover, a.badge-info:focus {
    color: #FFFFFF;
    background-color: #0071b1; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 146, 228, 0.5); }

.badge-warning {
  color: #FFFFFF;
  background-color: #F57C00; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #FFFFFF;
    background-color: #c26200; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 124, 0, 0.5); }

.badge-danger {
  color: #FFFFFF;
  background-color: #F44336; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFFFFF;
    background-color: #ea1c0d; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-primary-light {
  color: #212529;
  background-color: #ff8dae; }
  a.badge-primary-light:hover, a.badge-primary-light:focus {
    color: #212529;
    background-color: #ff5a8a; }
  a.badge-primary-light:focus, a.badge-primary-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 141, 174, 0.5); }

.badge-primary-dark {
  color: #FFFFFF;
  background-color: #ff2766; }
  a.badge-primary-dark:hover, a.badge-primary-dark:focus {
    color: #FFFFFF;
    background-color: #f30047; }
  a.badge-primary-dark:focus, a.badge-primary-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 39, 102, 0.5); }

.badge-secondary-light {
  color: #FFFFFF;
  background-color: #848484; }
  a.badge-secondary-light:hover, a.badge-secondary-light:focus {
    color: #FFFFFF;
    background-color: #6a6a6a; }
  a.badge-secondary-light:focus, a.badge-secondary-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(132, 132, 132, 0.5); }

.badge-secondary-dark {
  color: #FFFFFF;
  background-color: #515151; }
  a.badge-secondary-dark:hover, a.badge-secondary-dark:focus {
    color: #FFFFFF;
    background-color: #373737; }
  a.badge-secondary-dark:focus, a.badge-secondary-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(81, 81, 81, 0.5); }

.badge-purple-light {
  color: #FFFFFF;
  background-color: #d745f7; }
  a.badge-purple-light:hover, a.badge-purple-light:focus {
    color: #FFFFFF;
    background-color: #cc14f5; }
  a.badge-purple-light:focus, a.badge-purple-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(215, 69, 247, 0.5); }

.badge-purple {
  color: #FFFFFF;
  background-color: #CC14F5; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #FFFFFF;
    background-color: #a909cd; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(204, 20, 245, 0.5); }

.badge-purple-dark {
  color: #FFFFFF;
  background-color: #a909cd; }
  a.badge-purple-dark:hover, a.badge-purple-dark:focus {
    color: #FFFFFF;
    background-color: #81079c; }
  a.badge-purple-dark:focus, a.badge-purple-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(169, 9, 205, 0.5); }

.badge-inverse {
  color: #FFFFFF;
  background-color: #690E2C; }
  a.badge-inverse:hover, a.badge-inverse:focus {
    color: #FFFFFF;
    background-color: #3c0819; }
  a.badge-inverse:focus, a.badge-inverse.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 14, 44, 0.5); }

.badge-pink {
  color: #FFFFFF;
  background-color: #EC6592; }
  a.badge-pink:hover, a.badge-pink:focus {
    color: #FFFFFF;
    background-color: #e63872; }
  a.badge-pink:focus, a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(236, 101, 146, 0.5); }

.badge-green {
  color: #212529;
  background-color: #1EE831; }
  a.badge-green:hover, a.badge-green:focus {
    color: #212529;
    background-color: #14bf24; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(30, 232, 49, 0.5); }

.badge-blue {
  color: #212529;
  background-color: #55A8E6; }
  a.badge-blue:hover, a.badge-blue:focus {
    color: #212529;
    background-color: #2991df; }
  a.badge-blue:focus, a.badge-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(85, 168, 230, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #fcfcfc;
  border-radius: 0.375rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #852f48;
  background-color: #ffdee8;
  border-color: #ffd1de; }
  .alert-primary hr {
    border-top-color: #ffb8cc; }
  .alert-primary .alert-link {
    color: #5f2234; }

.alert-secondary {
  color: #373737;
  background-color: #e1e1e1;
  border-color: #d5d5d5; }
  .alert-secondary hr {
    border-top-color: #c8c8c8; }
  .alert-secondary .alert-link {
    color: #1e1e1e; }

.alert-success {
  color: #00583f;
  background-color: #cceee4;
  border-color: #b8e7da; }
  .alert-success hr {
    border-top-color: #a5e1d0; }
  .alert-success .alert-link {
    color: #00251a; }

.alert-info {
  color: #004c77;
  background-color: #cce9fa;
  border-color: #b8e0f7; }
  .alert-info hr {
    border-top-color: #a1d6f4; }
  .alert-info .alert-link {
    color: #002b44; }

.alert-warning {
  color: #7f4000;
  background-color: #fde5cc;
  border-color: #fcdab8; }
  .alert-warning hr {
    border-top-color: #fbcda0; }
  .alert-warning .alert-link {
    color: #4c2600; }

.alert-danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7; }
  .alert-danger hr {
    border-top-color: #fbb3af; }
  .alert-danger .alert-link {
    color: #551713; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-primary-light {
  color: #85495b;
  background-color: #ffe8ef;
  border-color: #ffdfe8; }
  .alert-primary-light hr {
    border-top-color: #ffc6d6; }
  .alert-primary-light .alert-link {
    color: #643744; }

.alert-primary-dark {
  color: #851435;
  background-color: #ffd4e0;
  border-color: #ffc3d4; }
  .alert-primary-dark hr {
    border-top-color: #ffaac2; }
  .alert-primary-dark .alert-link {
    color: #590d23; }

.alert-secondary-light {
  color: #444444;
  background-color: #e6e6e6;
  border-color: gainsboro; }
  .alert-secondary-light hr {
    border-top-color: #cfcfcf; }
  .alert-secondary-light .alert-link {
    color: #2b2b2b; }

.alert-secondary-dark {
  color: #2a2a2a;
  background-color: gainsboro;
  border-color: #cecece; }
  .alert-secondary-dark hr {
    border-top-color: #c1c1c1; }
  .alert-secondary-dark .alert-link {
    color: #111111; }

.alert-purple-light {
  color: #702480;
  background-color: #f7dafd;
  border-color: #f4cbfd; }
  .alert-purple-light hr {
    border-top-color: #efb2fc; }
  .alert-purple-light .alert-link {
    color: #4d1958; }

.alert-purple {
  color: #6a0a7f;
  background-color: #f5d0fd;
  border-color: #f1bdfc; }
  .alert-purple hr {
    border-top-color: #eca5fb; }
  .alert-purple .alert-link {
    color: #430650; }

.alert-purple-dark {
  color: #58056b;
  background-color: #eecef5;
  border-color: #e7baf1; }
  .alert-purple-dark hr {
    border-top-color: #e0a5ed; }
  .alert-purple-dark .alert-link {
    color: #30033a; }

.alert-inverse {
  color: #370717;
  background-color: #e1cfd5;
  border-color: #d5bcc4; }
  .alert-inverse hr {
    border-top-color: #cbacb6; }
  .alert-inverse .alert-link {
    color: #0a0104; }

.alert-pink {
  color: #7b354c;
  background-color: #fbe0e9;
  border-color: #fad4e0; }
  .alert-pink hr {
    border-top-color: #f7bdd0; }
  .alert-pink .alert-link {
    color: #572636; }

.alert-green {
  color: #107919;
  background-color: #d2fad6;
  border-color: #c0f9c5; }
  .alert-green hr {
    border-top-color: #a9f7b0; }
  .alert-green .alert-link {
    color: #0a4c10; }

.alert-blue {
  color: #2c5778;
  background-color: #ddeefa;
  border-color: #cfe7f8; }
  .alert-blue hr {
    border-top-color: #b9dcf5; }
  .alert-blue .alert-link {
    color: #1e3c53; }

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em; }
  .label:empty {
    display: none; }
  .btn .label {
    position: relative;
    top: -1px; }

a.label:hover, a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer; }

.label-primary {
  background-color: #ff5a8a; }
  .label-primary[href]:hover, .label-primary[href]:focus {
    background-color: #ff2766; }

.label-secondary {
  background-color: #6a6a6a; }
  .label-secondary[href]:hover, .label-secondary[href]:focus {
    background-color: #515151; }

.label-success {
  background-color: #00a97a; }
  .label-success[href]:hover, .label-success[href]:focus {
    background-color: #007655; }

.label-info {
  background-color: #0092E4; }
  .label-info[href]:hover, .label-info[href]:focus {
    background-color: #0071b1; }

.label-warning {
  background-color: #F57C00; }
  .label-warning[href]:hover, .label-warning[href]:focus {
    background-color: #c26200; }

.label-danger {
  background-color: #F44336; }
  .label-danger[href]:hover, .label-danger[href]:focus {
    background-color: #ea1c0d; }

.label-light {
  background-color: #f8f9fa; }
  .label-light[href]:hover, .label-light[href]:focus {
    background-color: #dae0e5; }

.label-primary-light {
  background-color: #ff8dae; }
  .label-primary-light[href]:hover, .label-primary-light[href]:focus {
    background-color: #ff5a8a; }

.label-primary-dark {
  background-color: #ff2766; }
  .label-primary-dark[href]:hover, .label-primary-dark[href]:focus {
    background-color: #f30047; }

.label-secondary-light {
  background-color: #848484; }
  .label-secondary-light[href]:hover, .label-secondary-light[href]:focus {
    background-color: #6a6a6a; }

.label-secondary-dark {
  background-color: #515151; }
  .label-secondary-dark[href]:hover, .label-secondary-dark[href]:focus {
    background-color: #373737; }

.label-purple-light {
  background-color: #d745f7; }
  .label-purple-light[href]:hover, .label-purple-light[href]:focus {
    background-color: #cc14f5; }

.label-purple {
  background-color: #CC14F5; }
  .label-purple[href]:hover, .label-purple[href]:focus {
    background-color: #a909cd; }

.label-purple-dark {
  background-color: #a909cd; }
  .label-purple-dark[href]:hover, .label-purple-dark[href]:focus {
    background-color: #81079c; }

.label-inverse {
  background-color: #690E2C; }
  .label-inverse[href]:hover, .label-inverse[href]:focus {
    background-color: #3c0819; }

.label-pink {
  background-color: #EC6592; }
  .label-pink[href]:hover, .label-pink[href]:focus {
    background-color: #e63872; }

.label-green {
  background-color: #1EE831; }
  .label-green[href]:hover, .label-green[href]:focus {
    background-color: #14bf24; }

.label-blue {
  background-color: #55A8E6; }
  .label-blue[href]:hover, .label-blue[href]:focus {
    background-color: #2991df; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #ff5a8a;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-success {
  background-color: #5cb85c !important; }

.progress-bar-info {
  background-color: #5bc0de !important; }

.progress-bar-warning {
  background-color: #f0ad4e !important; }

.progress-bar-danger {
  background-color: #d9534f !important; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #656565;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  margin-bottom: -1px;
  background-color: #FFFFFF;
  border: 1px solid #e4eaec; }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #FFFFFF; }
  .list-group-item.active {
    z-index: 2;
    color: #FFFFFF;
    background-color: #ff5a8a;
    border-color: #ff5a8a; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #852f48;
  background-color: #ffd1de; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #852f48;
    background-color: #ffb8cc; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #852f48;
    border-color: #852f48; }

.list-group-item-secondary {
  color: #373737;
  background-color: #d5d5d5; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #373737;
    background-color: #c8c8c8; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #373737;
    border-color: #373737; }

.list-group-item-success {
  color: #00583f;
  background-color: #b8e7da; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #00583f;
    background-color: #a5e1d0; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #00583f;
    border-color: #00583f; }

.list-group-item-info {
  color: #004c77;
  background-color: #b8e0f7; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #004c77;
    background-color: #a1d6f4; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #004c77;
    border-color: #004c77; }

.list-group-item-warning {
  color: #7f4000;
  background-color: #fcdab8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7f4000;
    background-color: #fbcda0; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7f4000;
    border-color: #7f4000; }

.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7f231c;
    background-color: #fbb3af; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7f231c;
    border-color: #7f231c; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-primary-light {
  color: #85495b;
  background-color: #ffdfe8; }
  .list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
    color: #85495b;
    background-color: #ffc6d6; }
  .list-group-item-primary-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #85495b;
    border-color: #85495b; }

.list-group-item-primary-dark {
  color: #851435;
  background-color: #ffc3d4; }
  .list-group-item-primary-dark.list-group-item-action:hover, .list-group-item-primary-dark.list-group-item-action:focus {
    color: #851435;
    background-color: #ffaac2; }
  .list-group-item-primary-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #851435;
    border-color: #851435; }

.list-group-item-secondary-light {
  color: #444444;
  background-color: gainsboro; }
  .list-group-item-secondary-light.list-group-item-action:hover, .list-group-item-secondary-light.list-group-item-action:focus {
    color: #444444;
    background-color: #cfcfcf; }
  .list-group-item-secondary-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #444444;
    border-color: #444444; }

.list-group-item-secondary-dark {
  color: #2a2a2a;
  background-color: #cecece; }
  .list-group-item-secondary-dark.list-group-item-action:hover, .list-group-item-secondary-dark.list-group-item-action:focus {
    color: #2a2a2a;
    background-color: #c1c1c1; }
  .list-group-item-secondary-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #2a2a2a;
    border-color: #2a2a2a; }

.list-group-item-purple-light {
  color: #702480;
  background-color: #f4cbfd; }
  .list-group-item-purple-light.list-group-item-action:hover, .list-group-item-purple-light.list-group-item-action:focus {
    color: #702480;
    background-color: #efb2fc; }
  .list-group-item-purple-light.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #702480;
    border-color: #702480; }

.list-group-item-purple {
  color: #6a0a7f;
  background-color: #f1bdfc; }
  .list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
    color: #6a0a7f;
    background-color: #eca5fb; }
  .list-group-item-purple.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #6a0a7f;
    border-color: #6a0a7f; }

.list-group-item-purple-dark {
  color: #58056b;
  background-color: #e7baf1; }
  .list-group-item-purple-dark.list-group-item-action:hover, .list-group-item-purple-dark.list-group-item-action:focus {
    color: #58056b;
    background-color: #e0a5ed; }
  .list-group-item-purple-dark.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #58056b;
    border-color: #58056b; }

.list-group-item-inverse {
  color: #370717;
  background-color: #d5bcc4; }
  .list-group-item-inverse.list-group-item-action:hover, .list-group-item-inverse.list-group-item-action:focus {
    color: #370717;
    background-color: #cbacb6; }
  .list-group-item-inverse.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #370717;
    border-color: #370717; }

.list-group-item-pink {
  color: #7b354c;
  background-color: #fad4e0; }
  .list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
    color: #7b354c;
    background-color: #f7bdd0; }
  .list-group-item-pink.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #7b354c;
    border-color: #7b354c; }

.list-group-item-green {
  color: #107919;
  background-color: #c0f9c5; }
  .list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
    color: #107919;
    background-color: #a9f7b0; }
  .list-group-item-green.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #107919;
    border-color: #107919; }

.list-group-item-blue {
  color: #2c5778;
  background-color: #cfe7f8; }
  .list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
    color: #2c5778;
    background-color: #b9dcf5; }
  .list-group-item-blue.list-group-item-action.active {
    color: #FFFFFF;
    background-color: #2c5778;
    border-color: #2c5778; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #FFFFFF;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.52857;
  font-size: 1.1rem;
  font-weight: bold; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }
  .modal-footer > :not(:first-child) {
    margin-left: 0.25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 900px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-90w {
  max-width: 90% !important; }

.modal-75w {
  max-width: 75% !important; }

.modal-50w {
  max-width: 50% !important; }

.modal-40w {
  max-width: 40% !important; }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.52857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.52857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.375rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FFFFFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.375rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FFFFFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FFFFFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.375rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FFFFFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #656565; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFFFFF;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFFFFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFFFFF' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFFFFF;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #ff5a8a !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ff2766 !important; }

.bg-secondary {
  background-color: #6a6a6a !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #515151 !important; }

.bg-success {
  background-color: #00a97a !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #007655 !important; }

.bg-info {
  background-color: #0092E4 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0071b1 !important; }

.bg-warning {
  background-color: #F57C00 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c26200 !important; }

.bg-danger {
  background-color: #F44336 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1c0d !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-primary-light {
  background-color: #ff8dae !important; }

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #ff5a8a !important; }

.bg-primary-dark {
  background-color: #ff2766 !important; }

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #f30047 !important; }

.bg-secondary-light {
  background-color: #848484 !important; }

a.bg-secondary-light:hover, a.bg-secondary-light:focus,
button.bg-secondary-light:hover,
button.bg-secondary-light:focus {
  background-color: #6a6a6a !important; }

.bg-secondary-dark {
  background-color: #515151 !important; }

a.bg-secondary-dark:hover, a.bg-secondary-dark:focus,
button.bg-secondary-dark:hover,
button.bg-secondary-dark:focus {
  background-color: #373737 !important; }

.bg-purple-light {
  background-color: #d745f7 !important; }

a.bg-purple-light:hover, a.bg-purple-light:focus,
button.bg-purple-light:hover,
button.bg-purple-light:focus {
  background-color: #cc14f5 !important; }

.bg-purple {
  background-color: #CC14F5 !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #a909cd !important; }

.bg-purple-dark {
  background-color: #a909cd !important; }

a.bg-purple-dark:hover, a.bg-purple-dark:focus,
button.bg-purple-dark:hover,
button.bg-purple-dark:focus {
  background-color: #81079c !important; }

.bg-inverse {
  background-color: #690E2C !important; }

a.bg-inverse:hover, a.bg-inverse:focus,
button.bg-inverse:hover,
button.bg-inverse:focus {
  background-color: #3c0819 !important; }

.bg-pink {
  background-color: #EC6592 !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #e63872 !important; }

.bg-green {
  background-color: #1EE831 !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #14bf24 !important; }

.bg-blue {
  background-color: #55A8E6 !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #2991df !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ff5a8a !important; }

.border-secondary {
  border-color: #6a6a6a !important; }

.border-success {
  border-color: #00a97a !important; }

.border-info {
  border-color: #0092E4 !important; }

.border-warning {
  border-color: #F57C00 !important; }

.border-danger {
  border-color: #F44336 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-primary-light {
  border-color: #ff8dae !important; }

.border-primary-dark {
  border-color: #ff2766 !important; }

.border-secondary-light {
  border-color: #848484 !important; }

.border-secondary-dark {
  border-color: #515151 !important; }

.border-purple-light {
  border-color: #d745f7 !important; }

.border-purple {
  border-color: #CC14F5 !important; }

.border-purple-dark {
  border-color: #a909cd !important; }

.border-inverse {
  border-color: #690E2C !important; }

.border-pink {
  border-color: #EC6592 !important; }

.border-green {
  border-color: #1EE831 !important; }

.border-blue {
  border-color: #55A8E6 !important; }

.border-white {
  border-color: #FFFFFF !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.375rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFFF !important; }

.text-primary {
  color: #ff5a8a !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #ff0e54 !important; }

.text-secondary {
  color: #6a6a6a !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #444444 !important; }

.text-success {
  color: #00a97a !important; }

a.text-success:hover, a.text-success:focus {
  color: #005d43 !important; }

.text-info {
  color: #0092E4 !important; }

a.text-info:hover, a.text-info:focus {
  color: #006198 !important; }

.text-warning {
  color: #F57C00 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #a95500 !important; }

.text-danger {
  color: #F44336 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #d2190b !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-primary-light {
  color: #ff8dae !important; }

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #ff4178 !important; }

.text-primary-dark {
  color: #ff2766 !important; }

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #da003f !important; }

.text-secondary-light {
  color: #848484 !important; }

a.text-secondary-light:hover, a.text-secondary-light:focus {
  color: #5d5d5d !important; }

.text-secondary-dark {
  color: #515151 !important; }

a.text-secondary-dark:hover, a.text-secondary-dark:focus {
  color: #2a2a2a !important; }

.text-purple-light {
  color: #d745f7 !important; }

a.text-purple-light:hover, a.text-purple-light:focus {
  color: #be0ae6 !important; }

.text-purple {
  color: #CC14F5 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #9508b5 !important; }

.text-purple-dark {
  color: #a909cd !important; }

a.text-purple-dark:hover, a.text-purple-dark:focus {
  color: #6d0684 !important; }

.text-inverse {
  color: #690E2C !important; }

a.text-inverse:hover, a.text-inverse:focus {
  color: #260510 !important; }

.text-pink {
  color: #EC6592 !important; }

a.text-pink:hover, a.text-pink:focus {
  color: #e42162 !important; }

.text-green {
  color: #1EE831 !important; }

a.text-green:hover, a.text-green:focus {
  color: #11a81f !important; }

.text-blue {
  color: #55A8E6 !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #1f84d0 !important; }

.text-body {
  color: #656565 !important; }

.text-muted {
  color: #515151 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-pink-70 {
  color: rgba(236, 101, 146, 0.7) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFFFFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #eee; }
  .table .thead-dark th {
    color: inherit;
    border-color: #eee; } }

.panel {
  margin-bottom: 1rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.panel-body {
  padding: 15px;
  flex: 1 1; }
  .panel-body::after {
    display: block;
    clear: both;
    content: ""; }

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: -0.75rem;
  border-top-right-radius: -0.75rem; }
  .panel-heading > .dropdown .dropdown-toggle {
    color: inherit; }

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit; }
  .panel-title > a,
  .panel-title > small,
  .panel-title > .small,
  .panel-title > small > a,
  .panel-title > .small > a {
    color: inherit; }

.panel-footer {
  padding: 10px 15px;
  background-color: #fafafa;
  border-top: 1px solid #eee;
  border-bottom-right-radius: -0.75rem;
  border-bottom-left-radius: -0.75rem; }

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0; }
  .panel > .list-group .list-group-item,
  .panel > .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0; }
  .panel > .list-group:first-child .list-group-item:first-child,
  .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: -0.75rem;
    border-top-right-radius: -0.75rem; }
  .panel > .list-group:last-child .list-group-item:last-child,
  .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: -0.75rem;
    border-bottom-left-radius: -0.75rem; }

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0; }

.list-group + .panel-footer {
  border-top-width: 0; }

.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0; }
  .panel > .table caption,
  .panel > .table-responsive > .table caption,
  .panel > .panel-collapse > .table caption {
    padding-left: 15px;
    padding-right: 15px; }

.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-left-radius: -0.75rem;
  border-top-right-radius: -0.75rem; }
  .panel > .table:first-child > thead:first-child > tr:first-child,
  .panel > .table:first-child > tbody:first-child > tr:first-child,
  .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
  .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
    border-top-left-radius: -0.75rem;
    border-top-right-radius: -0.75rem; }
    .panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
    .panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
      border-top-left-radius: -0.75rem; }
    .panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
    .panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
    .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
    .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
    .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
      border-top-right-radius: -0.75rem; }

.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: -0.75rem;
  border-bottom-left-radius: -0.75rem; }
  .panel > .table:last-child > tbody:last-child > tr:last-child,
  .panel > .table:last-child > tfoot:last-child > tr:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
  .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
    border-bottom-left-radius: -0.75rem;
    border-bottom-right-radius: -0.75rem; }
    .panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
    .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
      border-bottom-left-radius: -0.75rem; }
    .panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
    .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
    .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
    .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
      border-bottom-right-radius: -0.75rem; }

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #eee; }

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0; }

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0; }
  .panel > .table-bordered > thead > tr > th:first-child,
  .panel > .table-bordered > thead > tr > td:first-child,
  .panel > .table-bordered > tbody > tr > th:first-child,
  .panel > .table-bordered > tbody > tr > td:first-child,
  .panel > .table-bordered > tfoot > tr > th:first-child,
  .panel > .table-bordered > tfoot > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .panel > .table-bordered > thead > tr > th:last-child,
  .panel > .table-bordered > thead > tr > td:last-child,
  .panel > .table-bordered > tbody > tr > th:last-child,
  .panel > .table-bordered > tbody > tr > td:last-child,
  .panel > .table-bordered > tfoot > tr > th:last-child,
  .panel > .table-bordered > tfoot > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .panel > .table-bordered > thead > tr:first-child > td,
  .panel > .table-bordered > thead > tr:first-child > th,
  .panel > .table-bordered > tbody > tr:first-child > td,
  .panel > .table-bordered > tbody > tr:first-child > th,
  .panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
  .panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
  .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
  .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
    border-bottom: 0; }
  .panel > .table-bordered > tbody > tr:last-child > td,
  .panel > .table-bordered > tbody > tr:last-child > th,
  .panel > .table-bordered > tfoot > tr:last-child > td,
  .panel > .table-bordered > tfoot > tr:last-child > th,
  .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
  .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0; }

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0; }

.panel-group {
  margin-bottom: 1rem; }
  .panel-group .panel {
    margin-bottom: 0;
    border-radius: 0.25rem; }
    .panel-group .panel + .panel {
      margin-top: 5px; }
  .panel-group .panel-heading {
    border-bottom: 0; }
    .panel-group .panel-heading + .panel-collapse > .panel-body,
    .panel-group .panel-heading + .panel-collapse > .list-group {
      border-top: 1px solid #eee; }
  .panel-group .panel-footer {
    border-top: 0; }
    .panel-group .panel-footer + .panel-collapse .panel-body {
      border-bottom: 1px solid #eee; }

.panel-default {
  border-color: #cfdbe2; }
  .panel-default > .panel-heading {
    color: #444;
    background-color: #fff;
    border-color: #cfdbe2; }
    .panel-default > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-default > .panel-heading .badge {
      color: #fff;
      background-color: #444; }
  .panel-default > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-primary {
  border-color: #cfdbe2; }
  .panel-primary > .panel-heading {
    color: #fff;
    background-color: #ff5a8a;
    border-color: #cfdbe2; }
    .panel-primary > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-primary > .panel-heading .badge {
      color: #ff5a8a;
      background-color: #fff; }
  .panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-success {
  border-color: #cfdbe2; }
  .panel-success > .panel-heading {
    color: #fff;
    background-color: #00a97a;
    border-color: #cfdbe2; }
    .panel-success > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-success > .panel-heading .badge {
      color: #00a97a;
      background-color: #fff; }
  .panel-success > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-info {
  border-color: #cfdbe2; }
  .panel-info > .panel-heading {
    color: #fff;
    background-color: #0092E4;
    border-color: #cfdbe2; }
    .panel-info > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-info > .panel-heading .badge {
      color: #0092E4;
      background-color: #fff; }
  .panel-info > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-warning {
  border-color: #cfdbe2; }
  .panel-warning > .panel-heading {
    color: #fff;
    background-color: #F57C00;
    border-color: #cfdbe2; }
    .panel-warning > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-warning > .panel-heading .badge {
      color: #F57C00;
      background-color: #fff; }
  .panel-warning > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-danger {
  border-color: #cfdbe2; }
  .panel-danger > .panel-heading {
    color: #fff;
    background-color: #F44336;
    border-color: #cfdbe2; }
    .panel-danger > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-danger > .panel-heading .badge {
      color: #F44336;
      background-color: #fff; }
  .panel-danger > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

/*
* {
  @include box-sizing(border-box);
}
*:before,
*:after {
  @include box-sizing(border-box);
}


// Body reset

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// Links

a {
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    @include tab-focus;
  }
}

// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
  margin: 0;
}

// Images

img {
  vertical-align: middle;
}
*/
.img-responsive {
  max-width: 100%;
  height: auto; }

/*
// Rounded corners
.img-rounded {
  border-radius: $border-radius-large;
}

// Image thumbnails
//
// Heads up! This is mixin-ed into thumbnails.less for `.thumbnail`.
.img-thumbnail {
  padding: $thumbnail-padding;
  line-height: $line-height-base;
  background-color: $thumbnail-bg;
  border: 1px solid $thumbnail-border;
  border-radius: $thumbnail-border-radius;
  @include transition(all 0.2s ease-in-out);

  // Keep them at most 100% wide
  @include img-responsive(inline-block);
}
*/
.img-circle {
  border-radius: 50%; }

/*
hr {
  margin-top: $line-height-computed;
  margin-bottom: $line-height-computed;
  border: 0;
  border-top: 1px solid $hr-border;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
  cursor: pointer;
}
*/
/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.btn-default .badge,
.btn-primary .badge,
.btn-primary-light .badge,
.btn-secondary .badge,
.btn-success .badge,
.btn-info .badge,
.btn-warning .badge,
.btn-danger .badge {
  text-shadow: none; }

.btn:active, .btn.active {
  background-image: none; }

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #e9ecef; }

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #fff;
  background-color: #ff4178; }

.visible-xs {
  display: none !important; }

.visible-sm {
  display: none !important; }

.visible-md {
  display: none !important; }

.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

.visible-print {
  display: none !important; }

@media print {
  .visible-print {
    display: block !important; }
  table.visible-print {
    display: table !important; }
  tr.visible-print {
    display: table-row !important; }
  th.visible-print,
  td.visible-print {
    display: table-cell !important; } }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

@charset "UTF-8";
/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.6
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */
/* ========================================================================
     Component: layout
 ========================================================================== */
html {
  /* $replace rtl */
  direction: ltr;
  height: 100%;
  touch-action: manipulation;
  font-size: 16px; }

html,
body,
#app {
  height: 100%;
  background: #fafbfc !important; }

@media print {
  #launcher {
    display: none; } }

.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow-x: hidden; }
  .wrapper .aside-container {
    position: absolute;
    width: 220px;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 111;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    background-color: #fff; }
    .wrapper .aside-container:hover {
      z-index: 116; }
    .wrapper .aside-container .aside-inner {
      padding-top: 48px;
      height: 100%;
      width: 220px;
      overflow: hidden; }
    .wrapper .aside-container .nav-floating {
      left: inherit;
      margin-left: 220px;
      z-index: 1110;
      min-width: 250px;
      overflow: auto; }
  .wrapper .section-container {
    position: relative;
    height: 100%;
    margin-left: 0;
    z-index: 111;
    background-color: #fafbfc;
    margin-bottom: 60px !important; }
  .wrapper .footer-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    border-top: 1px solid #e4eaec;
    padding: 20px;
    z-index: 109;
    font-size: 0.875rem; }
    .wrapper .footer-container p {
      margin: 0; }

.modal-open .wrapper {
  z-index: 0; }

.content-wrapper {
  padding: 15px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: -1px; }
  .content-wrapper .unwrap {
    margin: -15px; }
    @media (min-width: 768px) {
      .content-wrapper .unwrap {
        margin: -20px; } }
  .content-wrapper .content-heading {
    /*
        display: flex;
        align-items: center;
        */
    font-size: 1.2rem;
    line-height: 1.1;
    color: #929292;
    margin: -15px;
    margin-bottom: 20px;
    padding: 15px;
    font-weight: normal;
    background-color: #fafbfc;
    border-bottom: 1px solid #cfdbe2; }
    .content-wrapper .content-heading small {
      font-size: 12px; }
  .content-wrapper .container,
  .content-wrapper .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  @media (min-width: 768px) {
    .content-wrapper {
      padding: 20px; }
      .content-wrapper .content-heading {
        margin: -20px;
        margin-bottom: 20px;
        padding: 20px; }
        .content-wrapper .content-heading button,
        .content-wrapper .content-heading .btn {
          margin: 0; } }

@media (min-width: 768px) {
  body {
    min-height: 100%; }
  .wrapper .section-container,
  .wrapper .footer-container {
    margin-left: 220px; }
  .wrapper .section-container.has-sidebar-right {
    margin-right: 240px; }
    .wrapper .section-container.has-sidebar-right + .offsidebar {
      z-index: 1; } }

@media (max-width: 767.98px) {
  .wrapper .aside-container {
    margin-left: -220px; }
  .aside-toggled .wrapper .section-container,
  .aside-toggled .wrapper .footer-container {
    margin-left: 220px; }
  .aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .wrapper {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .csstransforms3d .wrapper .section-container,
    .csstransforms3d .wrapper .footer-container {
      margin-left: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
    .csstransforms3d .wrapper .aside-container {
      margin-left: 0;
      -webkit-transform: translate3d(-220px, 0, 0);
              transform: translate3d(-220px, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .csstransforms3d .aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-toggled .wrapper .footer-container {
    -webkit-transform: translate3d(220px, 0, 0);
            transform: translate3d(220px, 0, 0); }
  .csstransforms3d .aside-toggled .wrapper .aside-container {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@media (max-width: 767.98px) {
  .aside-collapsed .wrapper .aside-container {
    margin-left: -70px; }
  .aside-collapsed.aside-toggled .wrapper .section-container,
  .aside-collapsed.aside-toggled .wrapper .footer-container {
    margin-left: 70px; }
  .aside-collapsed.aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .aside-collapsed .wrapper {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .csstransforms3d .aside-collapsed .wrapper .section-container,
    .csstransforms3d .aside-collapsed .wrapper .footer-container {
      margin-left: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
    .csstransforms3d .aside-collapsed .wrapper .aside-container {
      margin-left: 0;
      -webkit-transform: translate3d(-70px, 0, 0);
              transform: translate3d(-70px, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .footer-container {
    -webkit-transform: translate3d(70px, 0, 0);
            transform: translate3d(70px, 0, 0); }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .aside-container {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.aside-collapsed {
  overflow-y: auto; }
  .aside-collapsed .wrapper .aside-container,
  .aside-collapsed .wrapper .aside-container .aside-inner {
    width: 70px; }
  .aside-collapsed .wrapper .aside-container .nav-floating {
    margin-left: 70px; }

@media (min-width: 768px) {
  .aside-collapsed .wrapper .section-container,
  .aside-collapsed .wrapper .footer-container {
    margin-left: 70px; } }

@media (max-width: 767.98px) {
  .aside-collapsed-text .wrapper .aside-container {
    margin-left: -90px; }
  .aside-collapsed-text.aside-toggled .wrapper .section-container,
  .aside-collapsed-text.aside-toggled .wrapper .footer-container {
    margin-left: 90px; }
  .aside-collapsed-text.aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .aside-collapsed-text .wrapper {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .csstransforms3d .aside-collapsed-text .wrapper .section-container,
    .csstransforms3d .aside-collapsed-text .wrapper .footer-container {
      margin-left: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
    .csstransforms3d .aside-collapsed-text .wrapper .aside-container {
      margin-left: 0;
      -webkit-transform: translate3d(-90px, 0, 0);
              transform: translate3d(-90px, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .footer-container {
    -webkit-transform: translate3d(90px, 0, 0);
            transform: translate3d(90px, 0, 0); }
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .aside-container {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.aside-collapsed-text {
  overflow-y: auto; }
  .aside-collapsed-text .wrapper .aside-container,
  .aside-collapsed-text .wrapper .aside-container .aside-inner {
    width: 90px; }
  .aside-collapsed-text .wrapper .aside-container .nav-floating {
    margin-left: 90px; }

@media (min-width: 768px) {
  .aside-collapsed-text .wrapper .section-container,
  .aside-collapsed-text .wrapper .footer-container {
    margin-left: 90px; } }

@media (max-width: 1140px) {
  .layout-fixed.aside-toggled, .layout-fixed.offsidebar-open {
    overflow-y: hidden; } }

.layout-fixed .wrapper {
  /* only applied to sidebar */ }
  .layout-fixed .wrapper .topnavbar-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 121; }
  .layout-fixed .wrapper .aside-container,
  .layout-fixed .wrapper .offsidebar {
    position: fixed; }
  .layout-fixed .wrapper .aside-container {
    /* safari fix */
    height: 1px;
    min-height: 100%;
    /* ios blanks space fix */ }
    .layout-fixed .wrapper .aside-container .aside-inner {
      position: fixed;
      top: 0;
      bottom: 0; }
  .layout-fixed .wrapper .section-container {
    margin-top: 48px; }

/* IE10+ hack: safari fix breaks ie so we need to target ie only to restore */
_:-ms-lang(x),
.layout-fixed .wrapper .aside-container .aside-inner {
  position: static !important; }

@media (min-width: 992px) {
  .layout-boxed {
    overflow: auto !important; }
    .layout-boxed .wrapper {
      margin: 0 auto;
      overflow: hidden;
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.25); }
      .layout-boxed .wrapper .offsidebar {
        position: absolute !important; }
      .layout-boxed .wrapper .aside-container {
        left: inherit; }
      .layout-boxed .wrapper,
      .layout-boxed .wrapper .topnavbar-wrapper {
        width: 970px; }
    .layout-boxed.layout-fixed .wrapper .aside-container .aside-inner {
      left: inherit; } }

@media (min-width: 1200px) {
  .layout-boxed .wrapper,
  .layout-boxed .wrapper .topnavbar-wrapper {
    width: 1140px; } }

.sidebar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 220px;
  z-index: 112; }
  .aside-collapsed .sidebar-backdrop {
    left: 70px; }
  .aside-collapsed-text .sidebar-backdrop {
    left: 90px; }

/* ========================================================================
   Component: layout-extra
 ========================================================================== */
.hidden-footer .wrapper .footer-container {
  display: none; }

.hidden-footer .wrapper .section-container {
  margin-bottom: 0 !important; }

.layout-fs .wrapper .section-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 60px;
  top: 48px;
  height: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow: hidden; }
  .layout-fs .wrapper .section-container .content-wrapper {
    width: 100%;
    height: 100%;
    padding: 0; }
    .layout-fs .wrapper .section-container .content-wrapper > * {
      width: 100%;
      height: 100%; }

.layout-fs.hidden-footer .wrapper .section-container {
  bottom: 0; }

.content-wrapper {
  padding: 15px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: -1px; }
  .content-wrapper > .unwrap {
    margin: -15px; }
  .content-wrapper > h3,
  .content-wrapper > .content-heading {
    line-height: 1.1;
    color: #282828;
    margin: -15px;
    margin-bottom: 20px;
    padding: 15px;
    font-weight: normal;
    background-color: #fafbfc;
    border-bottom: 0;
    min-height: 60px; }
    .content-wrapper > h3 > small,
    .content-wrapper > .content-heading > small {
      display: block;
      font-size: 12px;
      color: #515151; }
  .content-wrapper > button,
  .content-wrapper > .btn {
    margin: 10px 10px 0 0; }
  .content-wrapper .container,
  .content-wrapper .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  @media (min-width: 768px) {
    .content-wrapper {
      padding: 20px; }
      .content-wrapper > h3,
      .content-wrapper > .content-heading {
        margin: -20px;
        margin-bottom: 20px;
        padding: 20px; }
      .content-wrapper > button,
      .content-wrapper > .btn {
        margin: 0; } }

.layout-h .wrapper .section-container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .layout-h .wrapper .section-container .content-wrapper .content-heading {
    display: none; }
    .layout-h .wrapper .section-container .content-wrapper .content-heading + .breadcrumb {
      display: none; }

.layout-h .wrapper .offsidebar {
  margin-top: 48px; }

.layout-h .wrapper .footer-container {
  margin: 0; }

.layout-h.layout-fixed .wrapper .section-container,
.layout-h.layout-fixed .wrapper .offsidebar {
  margin-top: 48px; }

.aside-float .wrapper {
  box-shadow: 0 0 0 #000; }
  .aside-float .wrapper .footer-container {
    border: 0; }
  .aside-float .wrapper .aside-container {
    padding: 15px 0;
    background: transparent; }
    .aside-float .wrapper .aside-container .aside-inner {
      height: 100%;
      top: 15px;
      bottom: 15px;
      left: inherit; }
    .aside-float .wrapper .aside-container .sidebar {
      border: 1px solid #e4eaec; }
    .aside-float .wrapper .aside-container .sidebar:after {
      display: none; }
  @media (min-width: 768px) {
    .aside-float .wrapper .section-container {
      padding-left: 15px; }
    .aside-float .wrapper .footer-container {
      left: 15px; } }
  .aside-float .wrapper .content-wrapper {
    border: 0; }
    .aside-float .wrapper .content-wrapper .content-heading {
      padding-top: 30px;
      border: 0;
      background-color: transparent; }
    .aside-float .wrapper .content-wrapper .unwrap {
      margin: 0 !important; }

.aside-float.aside-toggled .wrapper .aside-container {
  transition: delay(0.05s); }
  .aside-float.aside-toggled .wrapper .aside-container .aside-inner {
    margin-left: 15px; }

.aside-float.aside-toggled .wrapper .section-container {
  padding-left: 15px; }

.aside-float.aside-toggled .wrapper .footer-container {
  left: 15px; }

@media (min-width: 768px) {
  .aside-float.aside-collapsed .topnavbar .navbar-header {
    width: 100px; }
  .aside-float.aside-collapsed-text .topnavbar .navbar-header {
    width: 120px; }
  .aside-float.layout-fs .wrapper .section-container .content-wrapper {
    padding: 0 0 0 20px; }
  .aside-float .wrapper .aside-container .aside-inner {
    margin-left: 15px; } }

@media (min-width: 992px) {
  .aside-float.layout-boxed .wrapper .aside-container .aside-inner {
    margin-left: 0; }
  .aside-float.layout-boxed .wrapper .section-container {
    padding-left: 0; } }

.aside-float.aside-toggled.layout-fs .wrapper .section-container .content-wrapper {
  padding: 0 0 0 20px; }

/* ========================================================================
   Component: layout-animation.less
 ========================================================================== */
.wrapper .aside-container {
  transition: width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1); }
  @media (prefers-reduced-motion: reduce) {
    .wrapper .aside-container {
      transition: none; } }

.aside-inner,
.navbar-header,
.sidebar > .sidebar-nav > li {
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.wrapper .section-container {
  transition: margin-left 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.sidebar > .sidebar-nav .badge {
  -webkit-animation: fadeInRight 1s;
          animation: fadeInRight 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.aside-collapsed .sidebar > .sidebar-nav .badge,
.aside-collapsed-text .sidebar > .sidebar-nav .badge {
  -webkit-animation: fadeIn 1s;
          animation: fadeIn 1s; }

.aside-collapsed .sidebar > .sidebar-nav .label,
.aside-collapsed-text .sidebar > .sidebar-nav .label {
  -webkit-animation: fadeIn 1s;
          animation: fadeIn 1s; }

.sidebar .sidebar-nav > li > a {
  -webkit-animation: fadeInLeft 0.5s;
          animation: fadeInLeft 0.5s; }

.sidebar > .sidebar-nav > .nav-heading,
.sidebar > .sidebar-nav > li > a > span,
.navbar-brand .brand-logo {
  -webkit-animation: fadeIn 1s;
          animation: fadeIn 1s; }

.sidebar li > a,
.sidebar li > .nav-item,
.sidebar > .sidebar-nav > .nav-heading {
  white-space: nowrap; }

.aside-collapsed .user-block-picture,
.aside-collapsed-text .user-block-picture {
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.aside-collapsed .user-block,
.aside-collapsed-text .user-block {
  transition: padding 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

/* ========================================================================
     Component: top-navbar
 ========================================================================== */
.topnavbar {
  -webkit-backface-visibility: hidden;
  /* fixes chrome jump */
  margin-bottom: 0;
  border-radius: 0;
  z-index: 1050;
  border: 0;
  padding: 0;
  min-height: 48px;
  background-color: #ff2766;
  background-image: linear-gradient(to bottom, #ff5a8a 0%, #ff2766 100%);
  background-repeat: repeat-x; }
  @media (min-width: 768px) {
    .topnavbar .navbar-header {
      width: 220px;
      text-align: center; }
      .topnavbar .navbar-header .navbar-brand {
        width: 100%; } }

.topnavbar {
  position: relative; }
  .topnavbar .navbar-header {
    background-color: transparent; }
  .topnavbar .navbar-header {
    position: relative;
    z-index: 11;
    padding-left: 2rem; }
    @media (min-width: 768px) {
      .topnavbar .navbar-header {
        padding-left: 0; } }
    .topnavbar .navbar-header .navbar-brand {
      padding: 0; }
    .topnavbar .navbar-header .brand-logo > img,
    .topnavbar .navbar-header .brand-logo-collapsed > img {
      margin: 0 auto; }
    .topnavbar .navbar-header .brand-logo {
      display: block;
      padding: 10px 15px; }
    .topnavbar .navbar-header .brand-logo-collapsed {
      display: none;
      padding: 6px 15px; }
  .topnavbar .dropdown {
    position: static; }
    .topnavbar .dropdown .dropdown-menu {
      position: absolute;
      margin-top: 0;
      top: auto;
      left: 0;
      right: 0; }
  @media (min-width: 576px) {
    .topnavbar .dropdown {
      position: relative; }
      .topnavbar .dropdown .dropdown-menu {
        top: 47px;
        left: 0;
        right: auto; }
      .topnavbar .dropdown .dropdown-menu-right {
        right: 0;
        left: auto; } }
  .topnavbar .navbar-nav > .nav-item > .navbar-text {
    color: #fff; }
  .topnavbar .navbar-nav > .nav-item > .nav-link {
    padding: 1.1rem .95rem;
    font-size: .85rem; }
  .topnavbar .navbar-nav > .nav-item > .nav-link,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link {
    color: #fff; }
    .topnavbar .navbar-nav > .nav-item > .nav-link:hover, .topnavbar .navbar-nav > .nav-item > .nav-link:focus,
    .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
    .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
      color: #c00038; }
  .topnavbar .navbar-nav > .nav-item.active > .nav-link, .topnavbar .navbar-nav > .nav-item.active > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.active > .nav-link:focus,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
    display: inline-block;
    box-shadow: 0 -3px 0 rgba(0, 0, 0, 0.125) inset;
    background-color: transparent;
    transition: all 0.2s; }
    @media (prefers-reduced-motion: reduce) {
      .topnavbar .navbar-nav > .nav-item.active > .nav-link, .topnavbar .navbar-nav > .nav-item.active > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.active > .nav-link:focus,
      .topnavbar .navbar-nav > .nav-item.show > .nav-link,
      .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
      .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
        transition: none; } }
  .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    font-size: 16px;
    line-height: 48px;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    transition: color 0.3s ease; }
    @media (prefers-reduced-motion: reduce) {
      .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        transition: none; } }
    @media (min-width: 768px) {
      .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        color: #fff; } }
  @media (max-width: 767.98px) {
    .topnavbar .navbar-text {
      margin: 10px; } }

.layout-h .topnavbar .navbar-header {
  display: flex;
  width: 100%;
  padding: 0;
  text-align: left; }
  @media (min-width: 992px) {
    .layout-h .topnavbar .navbar-header {
      width: auto; } }
  .layout-h .topnavbar .navbar-header .navbar-brand {
    min-height: 48px; }

.layout-h .topnavbar .navbar-toggler {
  border: 0;
  margin-left: auto; }
  .layout-h .topnavbar .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>"); }

@media (max-width: 991.98px) {
  .layout-h .topnavbar .dropdown-menu {
    left: 0 !important;
    right: 0 !important; } }

.layout-h .topnavbar .navbar-form {
  left: 0; }

@media (max-width: 767.98px) {
  .sidebar-toggle {
    position: absolute !important;
    top: 2px;
    left: 0;
    z-index: 3001; }
    .sidebar-toggle > em {
      color: white; } }

.topnavbar .navbar-form {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  height: 48px;
  z-index: 9001;
  transition: all .3s;
  border: 0;
  border-bottom: 1px solid #e1e2e3; }
  .topnavbar .navbar-form .form-group {
    height: 100%;
    width: 100%; }
  .topnavbar .navbar-form .form-control {
    height: 100%;
    border: 0;
    border-radius: 0;
    width: 100%; }
  .topnavbar .navbar-form.open {
    top: 0; }
  .topnavbar .navbar-form .navbar-form-close {
    position: absolute;
    height: 30px;
    cursor: pointer;
    top: 50%;
    right: 0;
    margin-top: -15px;
    line-height: 30px;
    margin-right: 10px;
    color: #c1c2c3;
    font-size: 1.5em;
    pointer-events: auto; }

@media (min-width: 576px) {
  .topnavbar .navbar-form {
    left: 220px; } }

@media (min-width: 992px) {
  .topnavbar .navbar-nav > .nav-item.show > .nav-link, .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
    box-shadow: 0 -3px 0 #ff0850 inset;
    transition: all 0.2s; } }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .topnavbar .navbar-nav > .nav-item.show > .nav-link, .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
      transition: none; } }

@media (min-width: 768px) {
  .aside-collapsed .topnavbar .navbar-header .brand-logo {
    display: none; }
  .aside-collapsed .topnavbar .navbar-header .brand-logo-collapsed {
    display: block; }
  .aside-collapsed .topnavbar .navbar-header {
    width: 70px; }
  .aside-collapsed .topnavbar .navbar-form {
    left: 70px; } }

@media (min-width: 768px) {
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo {
    display: none; }
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo-collapsed {
    display: block; }
  .aside-collapsed-text .topnavbar .navbar-header {
    width: 90px; }
  .aside-collapsed-text .topnavbar .navbar-form {
    left: 90px; } }

/* ========================================================================
     Component: sidebar
 ========================================================================== */
.sidebar {
  height: 100%;
  padding-bottom: 20px;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }
  .sidebar:after {
    content: "";
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    -webkit-transform: translateZ(0px); }
  .sidebar .nav-heading {
    padding: 12px 15px;
    color: #919da8;
    font-size: 13px;
    letter-spacing: 0.035em;
    pointer-events: none;
    cursor: default; }
  .sidebar .disabled-link {
    cursor: default;
    color: #A6A7A8; }
    .sidebar .disabled-link:focus, .sidebar .disabled-link:hover {
      text-decoration: none;
      outline: none;
      color: #A6A7A8; }

.sidebar-nav {
  position: relative;
  font-size: 14px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }
  .sidebar-nav > .nav-heading:first-child {
    padding-top: 20px; }
  .sidebar-nav > li {
    display: block;
    border-left: 3px solid transparent;
    transition: border-left-color 0.4s ease; }
    @media (prefers-reduced-motion: reduce) {
      .sidebar-nav > li {
        transition: none; } }
    .sidebar-nav > li > a,
    .sidebar-nav > li > .nav-item {
      position: relative;
      display: block;
      padding: 12px 24px;
      color: #515253;
      letter-spacing: 0.025em;
      font-weight: normal;
      cursor: pointer; }
      .sidebar-nav > li > a:focus, .sidebar-nav > li > a:hover,
      .sidebar-nav > li > .nav-item:focus,
      .sidebar-nav > li > .nav-item:hover {
        text-decoration: none;
        outline: none;
        color: #ff2766; }
      .sidebar-nav > li > a > em,
      .sidebar-nav > li > .nav-item > em {
        width: 1.8em;
        display: inline-block;
        font-style: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        color: inherits; }
    .sidebar-nav > li.active,
    .sidebar-nav > li.active > a,
    .sidebar-nav > li.active > .nav-item,
    .sidebar-nav > li.active .sidebar-nav, .sidebar-nav > li.open,
    .sidebar-nav > li.open > a,
    .sidebar-nav > li.open > .nav-item,
    .sidebar-nav > li.open .sidebar-nav {
      background-color: #fcfcfc;
      color: #ff2766; }
    .sidebar-nav > li.active > .nav-item > em,
    .sidebar-nav > li.active > a > em, .sidebar-nav > li.open > .nav-item > em,
    .sidebar-nav > li.open > a > em {
      color: #ff2766; }
    .sidebar-nav > li.active {
      border-left-color: #ff2766; }
    .sidebar-nav > li .disabled-link {
      cursor: default;
      color: #A6A7A8; }
      .sidebar-nav > li .disabled-link:focus, .sidebar-nav > li .disabled-link:hover {
        text-decoration: none;
        outline: none;
        color: #A6A7A8; }
      .sidebar-nav > li .disabled-link > em {
        width: 1.8em;
        display: inline-block;
        font-style: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        color: #A6A7A8; }
  .sidebar-nav .badge {
    display: block; }

@media only screen and (min-width: 1025px) {
  .sidebar:not(.show-scrollbar) {
    margin-right: -17px;
    overflow-y: scroll; } }

@media only screen and (min-width: 1025px) {
  .sidebar > .sidebar-nav .label {
    margin: 2px 0 0 0; } }

.sidebar-subnav {
  background-color: #fff; }
  .sidebar-subnav > .sidebar-subnav-header {
    color: #515253;
    display: none;
    padding: 10px 20px;
    font-weight: bold; }
  .sidebar-subnav > li {
    border-left: 0 !important; }
    .sidebar-subnav > li > a,
    .sidebar-subnav > li > .nav-item {
      display: block;
      position: relative;
      padding: 10px 20px;
      padding-left: 53px;
      font-weight: normal;
      background-color: transparent !important;
      color: #515253; }
      .sidebar-subnav > li > a:focus, .sidebar-subnav > li > a:hover,
      .sidebar-subnav > li > .nav-item:focus,
      .sidebar-subnav > li > .nav-item:hover {
        color: #ff2766; }
      .sidebar-subnav > li > a > em,
      .sidebar-subnav > li > .nav-item > em {
        display: inline-block;
        width: 1.8em;
        margin: 0 0 0 -2em; }
    .sidebar-subnav > li.active > a,
    .sidebar-subnav > li.active > .nav-item {
      color: #ff2766; }
      .sidebar-subnav > li.active > a:after,
      .sidebar-subnav > li.active > .nav-item:after {
        border-color: #ff2766;
        background-color: #ff2766; }
    .sidebar-subnav > li .sidebar-nav > li {
      padding-left: 5px; }
  .sidebar-subnav.nav-floating {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-left: -1px; }
    .sidebar-subnav.nav-floating,
    .sidebar-subnav.nav-floating .collapse,
    .sidebar-subnav.nav-floating .sidebar-subnav {
      height: auto !important;
      display: block !important;
      visibility: visible !important; }
      .sidebar-subnav.nav-floating.opening,
      .sidebar-subnav.nav-floating .collapse.opening,
      .sidebar-subnav.nav-floating .sidebar-subnav.opening {
        -webkit-animation: none !important;
                animation: none !important; }
    .sidebar-subnav.nav-floating > .sidebar-subnav-header {
      display: block; }
    .sidebar-subnav.nav-floating li > a,
    .sidebar-subnav.nav-floating li > .nav-item {
      padding-left: 20px; }
      .sidebar-subnav.nav-floating li > a em,
      .sidebar-subnav.nav-floating li > .nav-item em {
        margin-left: 0; }

@media (min-width: 768px) {
  .sidebar > .sidebar-nav .badge {
    margin: 2px 0 0 0; } }

.aside-collapsed .sidebar,
.aside-collapsed-text .sidebar {
  overflow-x: hidden; }
  .aside-collapsed .sidebar > .sidebar-nav .sidebar-nav,
  .aside-collapsed .sidebar > .sidebar-nav > .nav-heading,
  .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item > span,
  .aside-collapsed .sidebar > .sidebar-nav > li > a > span,
  .aside-collapsed-text .sidebar > .sidebar-nav .sidebar-nav,
  .aside-collapsed-text .sidebar > .sidebar-nav > .nav-heading,
  .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > span,
  .aside-collapsed-text .sidebar > .sidebar-nav > li > a > span {
    display: none !important; }
  .aside-collapsed .sidebar > .sidebar-nav > li,
  .aside-collapsed-text .sidebar > .sidebar-nav > li {
    width: 69px; }
    .aside-collapsed .sidebar > .sidebar-nav > li > a,
    .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item,
    .aside-collapsed-text .sidebar > .sidebar-nav > li > a,
    .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item {
      text-indent: -3px;
      padding: 20px 0;
      text-align: center; }
      .aside-collapsed .sidebar > .sidebar-nav > li > a > em,
      .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item > em,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > a > em,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > em {
        font-size: 1.6em;
        width: auto; }
      .aside-collapsed .sidebar > .sidebar-nav > li > a:focus,
      .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item:focus,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > a:focus,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item:focus {
        background-color: transparent; }
        .aside-collapsed .sidebar > .sidebar-nav > li > a:focus > em,
        .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item:focus > em,
        .aside-collapsed-text .sidebar > .sidebar-nav > li > a:focus > em,
        .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item:focus > em {
          color: inherit; }
  .aside-collapsed .sidebar .sidebar-nav .badge,
  .aside-collapsed-text .sidebar .sidebar-nav .badge {
    position: absolute;
    top: 10px;
    right: 5px;
    text-indent: 0; }

.aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > span,
.aside-collapsed-text .sidebar > .sidebar-nav > li > a > span {
  display: block !important;
  font-size: 12px; }

.aside-collapsed-text .sidebar > .sidebar-nav > li {
  width: 88px; }
  .aside-collapsed-text .sidebar > .sidebar-nav > li > a {
    padding: 14px 0; }
    .aside-collapsed-text .sidebar > .sidebar-nav > li > a > em {
      font-size: 1.4em; }

/* ========================================================================
     Component: offsidebar
 ========================================================================== */
.offsidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 235px;
  margin-top: 48px;
  border-left: 1px solid #cccccc;
  background-color: #fff;
  color: #656565;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: -1; }
  .offsidebar > .list-group {
    min-height: 100%;
    overflow: hidden;
    -webkit-transform: translateZ(0px); }
  .offsidebar .progress {
    border: 0; }
  .offsidebar .tab-content {
    padding: 0;
    border: 0; }
  .offsidebar .nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
    .offsidebar .nav-tabs > .nav-item > .nav-link {
      background-color: transparent;
      border: 0;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 0;
      color: #515151; }
      .offsidebar .nav-tabs > .nav-item > .nav-link.active {
        color: #ff5a8a; }
  @media (min-width: 768px) {
    .offsidebar {
      margin-top: 48px; } }

.offsidebar-open .offsidebar {
  overflow-y: auto;
  z-index: 116; }

@media (min-width: 768px) {
  .offsidebar-open {
    overflow-y: auto; } }

.offsidebar {
  right: -235px; }

.no-csstransforms3d .offsidebar-open .offsidebar {
  right: 0; }

/* Transformation ready devices*/
.csstransforms3d .offsidebar {
  right: 0;
  -webkit-transform: translate3d(235px, 0, 0);
          transform: translate3d(235px, 0, 0);
  transition: z-index 0s linear .3s, -webkit-transform .3s ease;
  transition: transform .3s ease, z-index 0s linear .3s;
  transition: transform .3s ease, z-index 0s linear .3s, -webkit-transform .3s ease; }

.csstransforms3d .offsidebar-open .offsidebar {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

/* ========================================================================
     Component: user-block
 ========================================================================== */
.has-user-block {
  display: block;
  overflow: hidden;
  border: 0 !important; }

.user-block {
  position: relative;
  padding: 25px 0 10px;
  cursor: pointer; }
  .user-block::after {
    display: block;
    clear: both;
    content: ""; }
  .user-block > .user-block-picture {
    position: relative;
    width: 50px;
    margin: 0 auto; }
    .user-block > .user-block-picture > img {
      max-width: 100%;
      height: auto; }
  .user-block .user-block-info {
    padding-top: 15px;
    text-align: center;
    white-space: nowrap; }
    .user-block .user-block-info .user-block-name,
    .user-block .user-block-info .user-block-role {
      display: block; }
    .user-block .user-block-info .user-block-name {
      color: #7d848f; }
    .user-block .user-block-info .user-block-role {
      font-size: 12px;
      color: #aaa; }

.user-block-status {
  position: relative; }
  .user-block-status > .circle {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #fff; }

.aside-collapsed .user-block,
.aside-collapsed-text .user-block {
  padding: 15px 0 14px;
  margin: 0;
  text-align: center; }
  .aside-collapsed .user-block > .user-block-picture,
  .aside-collapsed-text .user-block > .user-block-picture {
    float: none;
    margin: 0 auto;
    width: 50px; }
    .aside-collapsed .user-block > .user-block-picture > .user-block-status,
    .aside-collapsed-text .user-block > .user-block-picture > .user-block-status {
      display: block; }
  .aside-collapsed .user-block .user-block-info,
  .aside-collapsed-text .user-block .user-block-info {
    display: none; }

.setting-color {
  padding: 0 5px; }
  .setting-color > label {
    display: block;
    position: relative;
    margin: 0 10px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer; }
    .setting-color > label:first-child {
      margin-left: 0; }
    .setting-color > label:last-child {
      margin-right: 0; }
    .setting-color > label > .color {
      display: block;
      height: 18px; }
    .setting-color > label > .split {
      display: block; }
      .setting-color > label > .split::after {
        display: block;
        clear: both;
        content: ""; }
      .setting-color > label > .split > .color {
        display: block;
        height: 37.5px; }
        .setting-color > label > .split > .color:first-child {
          float: left;
          width: 70%; }
        .setting-color > label > .split > .color:last-child {
          float: right;
          width: 30%; }
    .setting-color > label > .icon-check {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -20px;
      margin-left: -10px;
      text-align: center;
      font-size: 1.33333333em;
      vertical-align: -15%;
      color: #fff;
      opacity: 0; }
    .setting-color > label > input[type="radio"] {
      position: absolute;
      opacity: 0;
      visibility: hidden; }
      .setting-color > label > input[type="radio"]:checked + .icon-check {
        opacity: 1 !important; }

/* ========================================================================
     Component: panels
 ========================================================================== */
.panel .table {
  margin-bottom: 0;
  border: 0; }

.panel.panel-transparent {
  border: 0;
  background-color: transparent;
  box-shadow: 0 0 0 #000; }
  .panel.panel-transparent .panel-heading,
  .panel.panel-transparent .panel-body {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0; }

.panel-flat {
  margin: 0 !important;
  border: 0; }

.panel-group .panel-flat {
  box-shadow: 0 0 0 #000; }
  .panel-group .panel-flat:first-child {
    border-radius: 4px 4px 0 0; }
  .panel-group .panel-flat:last-child {
    border-radius: 0 0 4px 4px; }

.panel-heading > a[data-tool] {
  display: inline-block;
  color: #fff;
  width: 2em;
  text-align: center; }

.panel-default .panel-heading > a[data-tool] {
  color: #c1c2c3; }

.panel-heading > .label.pull-right {
  margin-top: 3px; }
  .panel-heading > .label.pull-right + .label.pull-right {
    margin-right: 10px; }

.panel-heading.panel-heading-collapsed {
  border-radius: -0.75rem; }

.panel-footer .pagination {
  margin: 0; }

.panel-footer .radial-bar {
  margin-bottom: 0; }

.panel-footer p {
  margin-bottom: 0; }

.panel-heading-active {
  background-color: #00a97a !important;
  font-weight: bold;
  font-size: 16px; }
  .panel-heading-active > a {
    padding: 15px 0;
    color: #fff !important;
    transition: padding 0.5s ease; }
    @media (prefers-reduced-motion: reduce) {
      .panel-heading-active > a {
        transition: none; } }
  .panel-heading-active + .panel-collapse:after {
    content: "";
    display: block;
    width: 60%;
    margin: 0 auto;
    height: 0;
    border-bottom: 1px solid #e1e2e3; }

.panel-inverse {
  border-color: #cfdbe2; }
  .panel-inverse > .panel-heading {
    color: #fff;
    background-color: #690E2C;
    border-color: #690E2C; }
    .panel-inverse > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-inverse > .panel-heading .badge {
      color: #690E2C;
      background-color: #fff; }
  .panel-inverse > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-green {
  border-color: #cfdbe2; }
  .panel-green > .panel-heading {
    color: #fff;
    background-color: #1EE831;
    border-color: #1EE831; }
    .panel-green > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-green > .panel-heading .badge {
      color: #1EE831;
      background-color: #fff; }
  .panel-green > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-pink {
  border-color: #cfdbe2; }
  .panel-pink > .panel-heading {
    color: #fff;
    background-color: #EC6592;
    border-color: #EC6592; }
    .panel-pink > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-pink > .panel-heading .badge {
      color: #EC6592;
      background-color: #fff; }
  .panel-pink > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-purple {
  border-color: #cfdbe2; }
  .panel-purple > .panel-heading {
    color: #fff;
    background-color: #CC14F5;
    border-color: #CC14F5; }
    .panel-purple > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-purple > .panel-heading .badge {
      color: #CC14F5;
      background-color: #fff; }
  .panel-purple > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

.panel-dark {
  border-color: #cfdbe2; }
  .panel-dark > .panel-heading {
    color: #fff;
    background-color: #474747;
    border-color: #474747; }
    .panel-dark > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #cfdbe2; }
    .panel-dark > .panel-heading .badge {
      color: #474747;
      background-color: #fff; }
  .panel-dark > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #cfdbe2; }

/* ========================================================================
     Component: Page loader
 ========================================================================== */
.page-loader {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  padding-top: 30px;
  text-align: center; }

@media (min-width: 768px) {
  .section-container .page-loader {
    top: 48px;
    left: 220px; }
  .aside-collapsed .section-container .page-loader {
    left: 70px; }
  .aside-collapsed-text .section-container .page-loader {
    left: 90px; } }

/* ========================================================================
     Component: typo
 ========================================================================== */
body {
  font-family: "Source Sans Pro", sans-serif;
  color: #656565;
  font-size: 0.875rem; }

h1,
h2,
h3,
h4 {
  font-weight: bold; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.65625rem;
  margin-top: 0.65625rem; }

h1,
.h1 {
  font-size: 2.25rem; }

h2,
.h2 {
  font-size: 1.875rem; }

h3,
.h3 {
  font-size: 1.5rem; }

h4,
.h4 {
  font-size: 1.125rem; }

h5,
.h5 {
  font-size: 0.875rem; }

h6,
.h6 {
  font-size: 1rem; }

p {
  margin-bottom: 0.65rem; }

/* ========================================================================
     Component: bootstrap-reset
 ========================================================================== */
*:not(.react-grid-Cell):focus {
  outline: 0 !important; }

a {
  outline: none !important; }
  a.text-muted:hover, a.text-muted:focus {
    color: #373737; }

hr {
  border-top: 1px solid #e4eaec; }

[data-now] {
  display: inline-block; }

.badge {
  padding: 0.1875rem 0.4375rem;
  font-size: 0.75rem;
  border-radius: 0.625rem; }
  .badge.badge-warning {
    color: #fff !important; }
  .badge.badge-secondary {
    background-color: #474747;
    color: #fff; }

.list-group {
  line-height: 1.3; }
  .list-group .list-group-item {
    padding: 10px;
    color: #555; }
    .list-group .list-group-item.active {
      color: #fff; }
      .list-group .list-group-item.active .badge {
        background-color: #fff;
        color: #ff5a8a; }
    .card > .list-group .list-group-item {
      border: 0; }

.list-group + .card-footer {
  border-top: 0; }

.page-header {
  padding-bottom: 0.59375rem;
  margin: 2.625rem 0 1.3125rem;
  border-bottom: 1px solid #e4eaec; }

.card {
  border-color: transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }
  .card .card-header {
    border-bottom: 0;
    padding: 0.625rem 0.9375rem; }
    .card .card-header .card-title {
      margin-bottom: 0;
      font-size: 1rem; }
    .card .card-header a {
      text-decoration: none !important; }

.card-body {
  padding: 0.9375rem; }

.card-footer {
  padding: 0.625rem 0.9375rem; }

.card-default {
  border-top-width: 3px;
  border-color: #cfdbe2; }
  .card-default .card-header {
    background-color: #fff; }

.well {
  border: 1px solid #DDE6E9; }

.jumbotron {
  border: 1px solid #DDE6E9;
  background-color: #fff; }
  @media (min-width: 768px) {
    .jumbotron {
      padding: 2rem 1rem; } }

.nav-tabs > .nav-item > .nav-link {
  font-weight: bold;
  color: #656565;
  background-color: #F5F5F5;
  margin: 0;
  border: 1px solid #DDE6E9;
  border-radius: 0;
  padding: 10px 20px; }
  .nav-tabs > .nav-item > .nav-link.active {
    background-color: #fff; }
    .nav-tabs > .nav-item > .nav-link.active, .nav-tabs > .nav-item > .nav-link.active:hover, .nav-tabs > .nav-item > .nav-link.active:focus {
      border-bottom-color: #fff; }

.nav-tabs > .nav-item {
  cursor: pointer; }

.tab-content {
  padding: 10px 20px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: #DDE6E9; }
  .nav-pills + .tab-content {
    border: 0;
    padding: 0; }
  .p-0 .tab-content {
    padding: 0 !important; }

.btn {
  border-radius: 3px;
  font-size: 13px;
  -webkit-appearance: none;
  outline: none !important;
  transition: all 0.1s; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn.btn-link {
    box-shadow: none;
    border: 0; }
  .input-group .btn {
    font-size: 14px;
    border-color: #DDE6E9; }
  .input-group .form-control-sm + .input-group-btn .btn {
    font-size: 0.8125rem;
    padding: 0.3125rem 0.625rem;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.btn.btn-warning {
  color: #fff; }

.btn.btn-outline-warning:hover, .btn.btn-outline-warning:focus, .btn.btn-outline-warning:active {
  color: #fff; }

.btn-secondary, .calendar-app .fc-button {
  background-color: #fff;
  border-color: #eaeaea;
  color: #333; }
  .btn-secondary:hover, .calendar-app .fc-button:hover {
    background-color: #f5f5f5; }
  .btn-secondary:not(:disabled):not(.disabled):active, .calendar-app .fc-button:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .calendar-app .fc-button:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle,
  .calendar-app .show > .dropdown-toggle.fc-button {
    background-color: #f5f5f5;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-outline-secondary {
  border-color: #eaeaea !important;
  color: #333; }
  .btn-outline-secondary:hover {
    color: #333;
    background-color: #f5f5f5 !important; }
  .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 234, 234, 0.5); }

span.btn {
  -webkit-appearance: none !important; }

.form-control {
  box-shadow: 0 0 0 #000 !important;
  font-size: 0.875rem; }
  .form-control:focus {
    border-color: #66afe9; }

.form-control-sm,
select.form-control-sm {
  font-size: 0.75rem;
  height: 31px; }

.custom-select {
  -webkit-appearance: none; }

.custom-control-label::before,
.custom-control-label::after {
  top: 0.19rem; }

.custom-control-label::before {
  border: 1px solid #DDE6E9; }

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px; }
  fieldset .form-group {
    margin-bottom: 0; }

.input-group-text {
  font-size: 0.875rem; }

@media (max-width: 767.98px) {
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none; } }

.table-responsive {
  overflow-y: hidden; }

.table > thead > tr > th {
  border-bottom-width: 0; }

.table > tbody + tbody {
  border-bottom-width: 1px; }

.table .form-control {
  max-width: 100%; }

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px; }

.progress {
  border-radius: 0.2rem;
  border: 1px solid #e9ecef;
  background-color: #fff; }

.popover {
  box-shadow: 0 0 0 #000;
  border-color: #eee;
  border-bottom: 2px solid #e4eaec;
  border-radius: 0.2rem; }
  .popover .popover-title {
    border: 0; }

.nav.nav-pills .active > a {
  background-color: #ff5a8a; }

.dropdown-menu {
  border-radius: 0.2rem;
  font-size: 14px;
  padding: 0.3125rem 0;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;
  border-color: #e1e1e1; }
  .dropdown-menu .dropdown-item {
    line-height: 1.52857143;
    padding: 0.1875rem 1.25rem; }

.dropdown-header {
  color: #a1a2a3; }

.navbar-top .navbar-nav > .active > a {
  color: #999; }
  .navbar-top .navbar-nav > .active > a:hover, .navbar-top .navbar-nav > .active > a:focus {
    color: #d1d2d3; }

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #666; }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #222; }

.carousel .carousel-indicators {
  bottom: 0; }

.carousel .carousel-control.left, .carousel .carousel-control.right {
  background-image: none; }

.carousel .carousel-control em {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px; }

.alert-primary-light {
  color: #fff;
  background-color: #ff8dae;
  border-color: #ff8dae; }
  .alert-primary-light hr {
    border-top-color: #ff749c; }
  .alert-primary-light .alert-link {
    color: #e6e6e6; }

.alert-primary {
  color: #fff;
  background-color: #ff5a8a;
  border-color: #ff5a8a; }
  .alert-primary hr {
    border-top-color: #ff4178; }
  .alert-primary .alert-link {
    color: #e6e6e6; }

.alert-primary-dark {
  color: #fff;
  background-color: #ff2766;
  border-color: #ff2766; }
  .alert-primary-dark hr {
    border-top-color: #ff0e54; }
  .alert-primary-dark .alert-link {
    color: #e6e6e6; }

.alert-secondary-light {
  color: #fff;
  background-color: #848484;
  border-color: #848484; }
  .alert-secondary-light hr {
    border-top-color: #777777; }
  .alert-secondary-light .alert-link {
    color: #e6e6e6; }

.alert-secondary {
  color: #fff;
  background-color: #6a6a6a;
  border-color: #6a6a6a; }
  .alert-secondary hr {
    border-top-color: #5d5d5d; }
  .alert-secondary .alert-link {
    color: #e6e6e6; }

.alert-secondary-dark {
  color: #fff;
  background-color: #515151;
  border-color: #515151; }
  .alert-secondary-dark hr {
    border-top-color: #444444; }
  .alert-secondary-dark .alert-link {
    color: #e6e6e6; }

.alert-success {
  color: #fff;
  background-color: #00a97a;
  border-color: #00a97a; }
  .alert-success hr {
    border-top-color: #009068; }
  .alert-success .alert-link {
    color: #e6e6e6; }

.alert-info {
  color: #fff;
  background-color: #0092E4;
  border-color: #0092E4; }
  .alert-info hr {
    border-top-color: #0082cb; }
  .alert-info .alert-link {
    color: #e6e6e6; }

.alert-warning {
  color: #fff;
  background-color: #F57C00;
  border-color: #F57C00; }
  .alert-warning hr {
    border-top-color: #dc6f00; }
  .alert-warning .alert-link {
    color: #e6e6e6; }

.alert-danger {
  color: #fff;
  background-color: #F44336;
  border-color: #F44336; }
  .alert-danger hr {
    border-top-color: #f32c1e; }
  .alert-danger .alert-link {
    color: #e6e6e6; }

.alert-purple-light {
  color: #fff;
  background-color: #d745f7;
  border-color: #d745f7; }
  .alert-purple-light hr {
    border-top-color: #d12cf6; }
  .alert-purple-light .alert-link {
    color: #e6e6e6; }

.alert-purple {
  color: #fff;
  background-color: #CC14F5;
  border-color: #CC14F5; }
  .alert-purple hr {
    border-top-color: #be0ae6; }
  .alert-purple .alert-link {
    color: #e6e6e6; }

.alert-purple-dark {
  color: #fff;
  background-color: #a909cd;
  border-color: #a909cd; }
  .alert-purple-dark hr {
    border-top-color: #9508b5; }
  .alert-purple-dark .alert-link {
    color: #e6e6e6; }

.alert-inverse {
  color: #fff;
  background-color: #690E2C;
  border-color: #690E2C; }
  .alert-inverse hr {
    border-top-color: #530b23; }
  .alert-inverse .alert-link {
    color: #e6e6e6; }

.alert-pink {
  color: #fff;
  background-color: #EC6592;
  border-color: #EC6592; }
  .alert-pink hr {
    border-top-color: #e94e82; }
  .alert-pink .alert-link {
    color: #e6e6e6; }

.alert-green {
  color: #fff;
  background-color: #1EE831;
  border-color: #1EE831; }
  .alert-green hr {
    border-top-color: #16d728; }
  .alert-green .alert-link {
    color: #e6e6e6; }

.alert-blue {
  color: #fff;
  background-color: #55A8E6;
  border-color: #55A8E6; }
  .alert-blue hr {
    border-top-color: #3f9de3; }
  .alert-blue .alert-link {
    color: #e6e6e6; }

.alert-secondary {
  color: #656565;
  border-color: #e4eaec; }

.topnavbar,
.navbar,
.navbar .dropdown-menu {
  -webkit-filter: none !important;
          filter: none !important; }

.panel .panel-heading {
  border-bottom: 0;
  font-size: 14px; }
  .panel .panel-heading a {
    text-decoration: none !important; }

.panel.panel-default {
  border-color: #cfdbe2; }

/* ========================================================================
     Component: bootstrap-custom
 ========================================================================== */
.container-sm {
  max-width: 540px;
  width: auto; }

.container-md {
  max-width: 720px;
  width: auto; }

.container-lg {
  max-width: 960px;
  width: auto; }

.row-flush {
  margin: 0; }
  .row-flush > .col,
  .row-flush > [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

.breadcrumb {
  font-weight: normal;
  border-radius: 0;
  color: #515151;
  padding: 10px 20px; }

.content-heading .breadcrumb {
  font-size: 0.8125rem;
  margin-bottom: 0; }

.content-heading + .breadcrumb {
  margin: -25px -25px 20px -20px;
  background-color: #fafbfc;
  border-top: 1px solid #cfdbe2;
  border-bottom: 1px solid #cfdbe2; }

.progress-sm {
  height: 15px; }

.progress-xs {
  height: 8px; }

.badge-inverse {
  color: #fff;
  background-color: #690E2C; }
  a.badge-inverse:hover, a.badge-inverse:focus {
    color: #fff;
    background-color: #3c0819; }
  a.badge-inverse:focus, a.badge-inverse.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(105, 14, 44, 0.5); }

.badge-green {
  color: #212529;
  background-color: #1EE831; }
  a.badge-green:hover, a.badge-green:focus {
    color: #212529;
    background-color: #14bf24; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(30, 232, 49, 0.5); }

.badge-pink {
  color: #fff;
  background-color: #EC6592; }
  a.badge-pink:hover, a.badge-pink:focus {
    color: #fff;
    background-color: #e63872; }
  a.badge-pink:focus, a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(236, 101, 146, 0.5); }

.badge-purple {
  color: #fff;
  background-color: #CC14F5; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #fff;
    background-color: #a909cd; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(204, 20, 245, 0.5); }

.alert-purple {
  color: #fff;
  background-color: #CC14F5;
  border-color: #CC14F5; }
  .alert-purple hr {
    border-top-color: #be0ae6; }
  .alert-purple .alert-link {
    color: #e6e6e6; }

.alert-green {
  color: #fff;
  background-color: #1EE831;
  border-color: #1EE831; }
  .alert-green hr {
    border-top-color: #16d728; }
  .alert-green .alert-link {
    color: #e6e6e6; }

.alert-pink {
  color: #fff;
  background-color: #EC6592;
  border-color: #EC6592; }
  .alert-pink hr {
    border-top-color: #e94e82; }
  .alert-pink .alert-link {
    color: #e6e6e6; }

.alert-inverse {
  color: #fff;
  background-color: #690E2C;
  border-color: #690E2C; }
  .alert-inverse hr {
    border-top-color: #530b23; }
  .alert-inverse .alert-link {
    color: #e6e6e6; }

.form-control-rounded {
  border-radius: 100px; }

/* ========================================================================
     Component: button-extra
 ========================================================================== */
.btn-inverse {
  color: #fff;
  background-color: #690E2C;
  border-color: #690E2C; }
  .btn-inverse:hover {
    color: #fff;
    background-color: #470a1e;
    border-color: #3c0819; }
  .btn-inverse.disabled, .btn-inverse:disabled {
    color: #fff;
    background-color: #690E2C;
    border-color: #690E2C; }
  .btn-inverse:not(:disabled):not(.disabled):active, .btn-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-inverse.dropdown-toggle {
    color: #fff;
    background-color: #3c0819;
    border-color: #310714; }

.btn-green {
  color: #212529;
  background-color: #1EE831;
  border-color: #1EE831; }
  .btn-green:hover {
    color: #fff;
    background-color: #15cb26;
    border-color: #14bf24; }
  .btn-green.disabled, .btn-green:disabled {
    color: #212529;
    background-color: #1EE831;
    border-color: #1EE831; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #14bf24;
    border-color: #12b422; }

.btn-purple {
  color: #fff;
  background-color: #CC14F5;
  border-color: #CC14F5; }
  .btn-purple:hover {
    color: #fff;
    background-color: #b409d9;
    border-color: #a909cd; }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #CC14F5;
    border-color: #CC14F5; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #a909cd;
    border-color: #9f08c1; }

.btn-pink {
  color: #fff;
  background-color: #EC6592;
  border-color: #EC6592; }
  .btn-pink:hover {
    color: #fff;
    background-color: #e8437a;
    border-color: #e63872; }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background-color: #EC6592;
    border-color: #EC6592; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #e63872;
    border-color: #e52c6a; }

.btn-gray-darker {
  color: #fff;
  background-color: #474747;
  border-color: #474747; }
  .btn-gray-darker:hover {
    color: #fff;
    background-color: #343434;
    border-color: #2e2e2e; }
  .btn-gray-darker.disabled, .btn-gray-darker:disabled {
    color: #fff;
    background-color: #474747;
    border-color: #474747; }
  .btn-gray-darker:not(:disabled):not(.disabled):active, .btn-gray-darker:not(:disabled):not(.disabled).active,
  .show > .btn-gray-darker.dropdown-toggle {
    color: #fff;
    background-color: #2e2e2e;
    border-color: #272727; }

.btn-flat {
  border-bottom-width: 1px;
  border-radius: 0;
  box-shadow: 0 0 0 #000; }

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.btn-xl {
  padding: 20px 16px;
  font-size: 18px; }

.btn-square {
  border-radius: 0; }

.btn-pill-left, .btn-oval {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 18px; }

.btn-pill-right, .btn-oval {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 18px; }

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0; }

.btn-label {
  position: relative;
  background: transparent;
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  padding: 0.375rem 1rem;
  left: -1rem;
  border-radius: 0.25rem 0 0 0.25rem; }
  .btn-label.btn-label-right {
    left: auto;
    right: -1rem;
    border-radius: 0 0.25rem 0.25rem 0; }

.btn-lg .btn-label {
  padding: 0.625rem 1.25rem;
  left: -1.25rem;
  border-radius: 0.375rem 0 0 0.375rem; }
  .btn-lg .btn-label.btn-label-right {
    left: auto;
    right: -1.25rem;
    border-radius: 0 0.375rem 0.375rem 0; }

.btn-sm .btn-label, .calendar-app .fc-button .btn-label {
  padding: 0.3125rem 0.625rem;
  left: -0.625rem;
  border-radius: 0.2rem 0 0 0.2rem; }
  .btn-sm .btn-label.btn-label-right, .calendar-app .fc-button .btn-label.btn-label-right {
    left: auto;
    right: -0.625rem;
    border-radius: 0 0.2rem 0.2rem 0; }

.btn-xs .btn-label {
  padding: 1px 5px;
  left: -5px;
  border-radius: 0.2rem 0 0 0.2rem; }
  .btn-xs .btn-label.btn-label-right {
    left: auto;
    right: -5px;
    border-radius: 0 0.2rem 0.2rem 0; }

.btn-fw {
  min-width: 80px; }
  .btn-fw.btn-sm, .calendar-app .btn-fw.fc-button {
    min-width: 40px; }
  .btn-fw.btn-md {
    min-width: 60px; }
  .btn-fw.btn-lg {
    min-width: 140px; }

.btn-circle {
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 12px;
  line-height: 35px;
  border-radius: 500px;
  padding: 0;
  border: 0; }
  .btn-circle > * {
    line-height: inherit !important; }
  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    font-size: 18px;
    line-height: 50px; }

.btn-outline-inverse {
  color: #690E2C;
  border-color: #690E2C; }
  .btn-outline-inverse:hover {
    color: #fff;
    background-color: #690E2C;
    border-color: #690E2C; }
  .btn-outline-inverse.disabled, .btn-outline-inverse:disabled {
    background-color: transparent; }
  .btn-outline-inverse:not(:disabled):not(.disabled):active, .btn-outline-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-outline-inverse.dropdown-toggle {
    color: #fff;
    background-color: #690E2C;
    border-color: #690E2C; }

.btn-outline-green {
  color: #1EE831;
  border-color: #1EE831; }
  .btn-outline-green:hover {
    color: #212529;
    background-color: #1EE831;
    border-color: #1EE831; }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #212529;
    background-color: #1EE831;
    border-color: #1EE831; }

.btn-outline-purple {
  color: #CC14F5;
  border-color: #CC14F5; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #CC14F5;
    border-color: #CC14F5; }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #CC14F5;
    border-color: #CC14F5; }

.btn-outline-pink {
  color: #EC6592;
  border-color: #EC6592; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #EC6592;
    border-color: #EC6592; }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #EC6592;
    border-color: #EC6592; }

.btn-outline-default {
  border-color: #eaeaea;
  color: #333 !important; }

/* ========================================================================
     Component: placeholder
 ========================================================================== */
.box-placeholder {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px dashed #ddd;
  background: #fafafa;
  color: #444; }

.box-placeholder > :last-child {
  margin-bottom: 0; }

.box-placeholder-lg {
  padding-top: 80px;
  padding-bottom: 80px; }

/* ========================================================================
     Component: cards
 ========================================================================== */
.card {
  margin-bottom: 1.25rem; }

.card .table {
  margin-bottom: 0; }
  .card .table > thead > tr > th {
    border-top: 0; }

.card.card-transparent {
  border: 0;
  background-color: transparent;
  box-shadow: 0 0 0 #000; }
  .card.card-transparent .card-header,
  .card.card-transparent .card-body {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0; }

.card-flat {
  margin: 0 !important;
  border: 0; }

.card-columns-2 {
  -webkit-column-count: 1;
          column-count: 1; }
  @media (min-width: 768px) {
    .card-columns-2 {
      -webkit-column-count: 2;
              column-count: 2; } }

.card-tool {
  display: inline-block;
  color: #fff;
  text-align: center; }
  .card-tool:hover {
    cursor: pointer; }
  .card-tool > em + em {
    margin-left: 12px; }

.card-default .card-header > .card-tool {
  color: #c1c2c3; }

.card-header > .badge.float-right {
  margin-top: 3px; }
  .card-header > .badge.float-right + .badge.float-right {
    margin-right: 10px; }

.card-footer .pagination {
  margin: 0; }

.card-footer .radial-bar {
  margin-bottom: 0; }

.card-footer p {
  margin-bottom: 0; }

/* ========================================================================
     Component: circles
 ========================================================================== */
.circle {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 500px;
  margin: 0 .5em;
  background-color: #ddd;
  vertical-align: baseline;
  border: 2px solid transparent; }
  .circle.text-left {
    margin-left: 0; }
  .circle.text-right {
    margin-right: 0; }

.circle-sm {
  width: 5px;
  height: 5px; }

.circle-lg {
  width: 11px;
  height: 11px; }

.circle-xl {
  width: 18px;
  height: 18px; }

/* ========================================================================
     Component: dropdown-extras
 ========================================================================== */
.dropdown-list .dropdown-item {
  padding: 0; }

.dropdown-list > .dropdown-menu {
  padding: 0;
  min-width: 220px; }

.dropdown-list .list-group {
  margin: 0; }

.dropdown-list .list-group-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0; }
  .dropdown-list .list-group-item:first-child {
    border-top: 0; }
  .dropdown-list .list-group-item:last-child {
    border-bottom: 0; }

.dropdown > a {
  position: relative; }
  .dropdown > a > .badge {
    position: absolute;
    top: 10px;
    right: 0;
    padding: 2px 5px; }

.dropdown-menu-right-forced {
  right: 0 !important;
  left: auto !important; }

.dropdown-toggle-nocaret:after {
  display: none; }

/* ========================================================================
     Component: half-float
 ========================================================================== */
.half-float {
  position: relative;
  margin-bottom: 69px; }
  .half-float .half-float-bottom,
  .half-float .half-float-top {
    position: absolute;
    left: 50%;
    bottom: -64px;
    width: 128px;
    height: 128px;
    margin-left: -64px;
    z-index: 2; }
  .half-float .half-float-top {
    bottom: auto;
    top: -64px; }

/* ========================================================================
     Component: animate
 ========================================================================== */
@-webkit-keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

@keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

.fadeOutUpShort {
  -webkit-animation-name: fadeOutUpShort;
  animation-name: fadeOutUpShort; }

@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUpShort {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort; }

@-webkit-keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

@keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

.zoomInShort {
  -webkit-animation-name: zoomInShort;
  animation-name: zoomInShort; }

@-webkit-keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

@keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

.zoomBack {
  -webkit-animation-name: zoomBack;
  animation-name: zoomBack; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

.rag-fadeIn-enter {
  -webkit-animation: fadeIn 0.5s;
          animation: fadeIn 0.5s; }

.rag-fadeIn-exit {
  display: none; }

.rag-fadeInRight-enter {
  -webkit-animation: fadeInRight 0.5s;
          animation: fadeInRight 0.5s; }

.rag-fadeInRight-exit {
  display: none; }

.rag-fadeInLeft-enter {
  -webkit-animation: fadeInLeft 0.5s;
          animation: fadeInLeft 0.5s; }

.rag-fadeInLeft-exit {
  display: none; }

/* ========================================================================
     Component: slim-scroll
 ========================================================================== */
[data-scrollable] {
  display: block; }

.slimScrollBar {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.35) !important;
  border: 0 !important;
  border-radius: 1px !important; }

.slimScrollRail {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.15) !important;
  border: 0 !important;
  border-radius: 0 !important;
  bottom: 0; }

/* ========================================================================
     Component: inputs
 ========================================================================== */
/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 *    - Switch
 *    - Note editor
 */
.c-checkbox,
.c-radio {
  margin-right: 4px; }
  .c-checkbox *,
  .c-radio * {
    cursor: pointer; }
  .c-checkbox input,
  .c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important; }
  .c-checkbox span,
  .c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid #ccc;
    margin-right: 5px;
    text-align: center; }
    .c-checkbox span:before,
    .c-radio span:before {
      margin-left: 1px; }
  .c-checkbox:hover span,
  .c-radio:hover span {
    border-color: #ff5a8a; }
  .form-inline .c-checkbox span, .form-inline
  .c-radio span {
    margin-left: 0; }
  .c-checkbox.c-checkbox-rounded span, .c-checkbox.c-radio-rounded span,
  .c-radio.c-checkbox-rounded span,
  .c-radio.c-radio-rounded span {
    border-radius: 500px; }

/* override for radio */
.c-radio span {
  border-radius: 500px; }

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 10px;
  line-height: 18px;
  vertical-align: middle; }

.c-checkbox,
.c-radio {
  /* Checked state */
  /* override for radio */
  /* Disable state */
  /* override for radio */
  /* Focus state */ }
  .c-checkbox input[type="checkbox"]:checked + span:before,
  .c-checkbox input[type="radio"]:checked + span:before,
  .c-radio input[type="checkbox"]:checked + span:before,
  .c-radio input[type="radio"]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color 0.3s ease-out; }
  .c-checkbox input[type="checkbox"]:checked + span,
  .c-checkbox input[type="radio"]:checked + span,
  .c-radio input[type="checkbox"]:checked + span,
  .c-radio input[type="radio"]:checked + span {
    border-color: #ff5a8a;
    background-color: #ff5a8a; }
  .c-checkbox input[type="radio"]:checked + span,
  .c-radio input[type="radio"]:checked + span {
    background-color: #fff; }
    .c-checkbox input[type="radio"]:checked + span:before,
    .c-radio input[type="radio"]:checked + span:before {
      color: #ff5a8a; }
  .c-checkbox input[type="checkbox"]:disabled + span,
  .c-checkbox input[type="radio"]:disabled + span,
  .c-radio input[type="checkbox"]:disabled + span,
  .c-radio input[type="radio"]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-checkbox input[type="radio"]:disabled + span,
  .c-radio input[type="radio"]:disabled + span {
    background-color: #fff !important; }
    .c-checkbox input[type="radio"]:disabled + span:before,
    .c-radio input[type="radio"]:disabled + span:before {
      color: #ddd; }
  .c-checkbox input[type="checkbox"]:focus + span,
  .c-checkbox input[type="radio"]:focus + span,
  .c-radio input[type="checkbox"]:focus + span,
  .c-radio input[type="radio"]:focus + span {
    box-shadow: 0 0 0 0.2rem rgba(255, 90, 138, 0.25); }

.c-radio.c-radio-nofont {
  /* override for radio */
  /* Disable state */
  /* override for radio */ }
  .c-radio.c-radio-nofont span:before {
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 500px; }
  .c-radio.c-radio-nofont input[type="radio"]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color 0.3s ease-out; }
  .c-radio.c-radio-nofont input[type="radio"]:checked + span {
    border-color: #ff5a8a;
    background-color: #ff5a8a; }
  .c-radio.c-radio-nofont input[type="radio"]:checked + span {
    background-color: #fff; }
    .c-radio.c-radio-nofont input[type="radio"]:checked + span:before {
      background-color: #ff5a8a; }
  .c-radio.c-radio-nofont input[type="radio"]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-radio.c-radio-nofont input[type="radio"]:disabled + span {
    background-color: #fff !important; }
    .c-radio.c-radio-nofont input[type="radio"]:disabled + span:before {
      background-color: #ddd; }

.switch .form-control {
  padding-top: 7px;
  margin-bottom: 0; }

.switch * {
  cursor: pointer; }

.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1; }

.switch {
  display: flex;
  align-items: center;
  margin: 0; }
  .switch span {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 100px;
    transition: all 0.5s;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset; }
  .switch span:after {
    content: "";
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #ddd;
    border-radius: 400px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.2s; }
  .switch.switch-lg span {
    width: 50px;
    height: 25px; }
  .switch.switch-lg span:after {
    height: 23px;
    width: 23px; }
  .switch.switch-sm span {
    width: 30px;
    height: 15px; }
  .switch.switch-sm span:after {
    height: 13px;
    width: 13px; }

.switch input:checked + span {
  background-color: #ff5a8a;
  border-color: #ff5a8a;
  transition: all 0.5s; }

.switch input:checked + span:after {
  left: 50%;
  transition: all 0.2s; }

.switch input:disabled + span {
  background-color: #f1f1f1;
  cursor: not-allowed; }

.switch input:focus + span {
  box-shadow: 0 0 0 0.2rem rgba(255, 90, 138, 0.25); }

.note-editor {
  background-image: linear-gradient(#eee 0.1em, transparent 0.1em);
  background-size: 100% 20px;
  background-color: #fff;
  line-height: 20px;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 1px;
  border: none; }
  .note-editor.note-editor-margin {
    background-image: linear-gradient(90deg, transparent 49px, #ffc88f 49px, #ffc88f 51px, transparent 51px), linear-gradient(#eee 0.1em, transparent 0.1em);
    padding-left: 55px; }

/* ========================================================================
         Component: utils
 ========================================================================== */
.m0 {
  margin: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.m {
  margin: 10px !important; }

.ml, .mh {
  margin-left: 10px !important; }

.mr, .mh {
  margin-right: 10px !important; }

.mt, .mv {
  margin-top: 10px !important; }

.mb, .mv {
  margin-bottom: 10px !important; }

.m-sm {
  margin: 5px !important; }

.ml-sm, .mh-sm {
  margin-left: 5px !important; }

.mr-sm, .mh-sm {
  margin-right: 5px !important; }

.mt-sm, .mv-sm {
  margin-top: 5px !important; }

.mb-sm, .mv-sm {
  margin-bottom: 5px !important; }

.m-lg {
  margin: 15px !important; }

.ml-lg, .mh-lg {
  margin-left: 15px !important; }

.mr-lg, .mh-lg {
  margin-right: 15px !important; }

.mt-lg, .mv-lg {
  margin-top: 15px !important; }

.mb-lg, .mv-lg {
  margin-bottom: 15px !important; }

.m-xl {
  margin: 30px !important; }

.ml-xl {
  margin-left: 30px !important; }

.mr-xl {
  margin-right: 30px !important; }

.mt-xl {
  margin-top: 30px !important; }

.mb-xl {
  margin-bottom: 30px !important; }

.p0 {
  padding: 0 !important; }

.pl0, .ph0 {
  padding-left: 0 !important; }

.pr0, .ph0 {
  padding-right: 0 !important; }

.pt0, .pv0 {
  padding-top: 0 !important; }

.pb0, .pv0 {
  padding-bottom: 0 !important; }

.p {
  padding: 10px !important; }

.pl, .ph {
  padding-left: 10px !important; }

.pr, .ph {
  padding-right: 10px !important; }

.pt, .pv {
  padding-top: 10px !important; }

.pb, .pv {
  padding-bottom: 10px !important; }

.p-sm {
  padding: 5px !important; }

.pl-sm, .ph-sm {
  padding-left: 5px !important; }

.pr-sm, .ph-sm {
  padding-right: 5px !important; }

.pt-sm, .pv-sm {
  padding-top: 5px !important; }

.pb-sm, .pv-sm {
  padding-bottom: 5px !important; }

.p-lg {
  padding: 15px !important; }

.pl-lg, .ph-lg {
  padding-left: 15px !important; }

.pr-lg, .ph-lg {
  padding-right: 15px !important; }

.pt-lg, .pv-lg {
  padding-top: 15px !important; }

.pb-lg, .pv-lg {
  padding-bottom: 15px !important; }

.p-xl {
  padding: 30px !important; }

.pl-xl, .ph-xl {
  padding-left: 30px !important; }

.pr-xl, .ph-xl {
  padding-right: 30px !important; }

.pt-xl, .pv-xl {
  padding-top: 30px !important; }

.pb-xl, .pv-xl {
  padding-bottom: 30px !important; }

.b0 {
  border-width: 0 !important; }

.bl0 {
  border-left-width: 0 !important; }

.br0 {
  border-right-width: 0 !important; }

.bt0 {
  border-top-width: 0 !important; }

.bb0 {
  border-bottom-width: 0 !important; }

.br, .b,
.ba {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.bl, .b,
.ba {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.bt, .b,
.ba {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.bb, .b,
.ba {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.text-primary {
  color: #ff5a8a !important; }

.text-primary-light {
  color: #ff8dae !important; }

.text-primary-dark {
  color: #ff2766 !important; }

.text-secondary {
  color: #6a6a6a !important; }

.text-secondary-light {
  color: #848484 !important; }

.text-secondary-dark {
  color: #515151 !important; }

.text-success {
  color: #00a97a !important; }

.text-info {
  color: #0092E4 !important; }

.text-warning {
  color: #F57C00 !important; }

.text-danger {
  color: #F44336 !important; }

.text-white {
  color: #fff !important; }

.text-inverse {
  color: #690E2C !important; }

.text-alpha {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-pink {
  color: #EC6592 !important; }

.text-purple {
  color: #CC14F5 !important; }

.text-dark {
  color: #474747 !important; }

.text-alpha-inverse {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-green {
  color: #1EE831 !important; }

.text-yellow {
  color: #FAD732 !important; }

.text-gray-darker {
  color: #474747 !important; }

.text-gray-dark {
  color: #474747 !important; }

.text-gray {
  color: #DDE6E9 !important; }

.text-gray-light {
  color: #e4eaec !important; }

.text-gray-lighter {
  color: #F5F5F5 !important; }

.text-inherit {
  color: inherit !important; }

.text-sm {
  font-size: 0.85rem; }

.text-md {
  font-size: 1.9rem; }

.text-lg {
  font-size: 3rem; }

.text-nowrap {
  white-space: nowrap; }

.text-thin {
  font-weight: 100 !important; }

.text-normal {
  font-weight: normal !important; }

.text-bold {
  font-weight: bold !important; }

.inline {
  display: inline-block !important; }

.block-center {
  margin: 0 auto; }

.bg-primary {
  background-color: #ff5a8a;
  color: #fff !important; }

.bg-primary-light {
  background-color: #ff8dae;
  color: #fff !important; }

.bg-primary-dark {
  color: #fff !important; }

.bg-primary small {
  color: inherit; }

.bg-secondary {
  background-color: #6a6a6a;
  color: #fff !important; }

.bg-secondary-light {
  background-color: #848484;
  color: #fff !important; }

.bg-secondary-dark {
  color: #fff !important; }

.bg-secondary small {
  color: inherit; }

.bg-success {
  background-color: #00a97a;
  color: #fff !important; }

.bg-success-light {
  background-color: #00dc9f;
  color: #fff !important; }

.bg-success-dark {
  color: #fff !important; }

.bg-success small {
  color: inherit; }

.bg-info {
  background-color: #0092E4;
  color: #fff !important; }

.bg-info-light {
  background-color: #18acff;
  color: #fff !important; }

.bg-info-dark {
  color: #fff !important; }

.bg-info small {
  color: inherit; }

.bg-warning {
  background-color: #F57C00;
  color: #fff !important; }

.bg-warning-light {
  background-color: #ff9529;
  color: #fff !important; }

.bg-warning-dark {
  color: #fff !important; }

.bg-warning small {
  color: inherit; }

.bg-danger {
  background-color: #F44336;
  color: #fff !important; }

.bg-danger-light {
  background-color: #f77066;
  color: #fff !important; }

.bg-danger-dark {
  color: #fff !important; }

.bg-danger small {
  color: inherit; }

.bg-green {
  background-color: #1EE831;
  color: #fff !important; }

.bg-green-light {
  background-color: #4ced5b;
  color: #fff !important; }

.bg-green-dark {
  color: #fff !important; }

.bg-green small {
  color: inherit; }

.bg-pink {
  background-color: #EC6592;
  color: #fff !important; }

.bg-pink-light {
  background-color: #f292b2;
  color: #fff !important; }

.bg-pink-dark {
  color: #fff !important; }

.bg-pink small {
  color: inherit; }

.bg-purple {
  background-color: #CC14F5;
  color: #fff !important; }

.bg-purple-light {
  background-color: #d745f7;
  color: #fff !important; }

.bg-purple-dark {
  color: #fff !important; }

.bg-purple small {
  color: inherit; }

.bg-inverse {
  background-color: #690E2C;
  color: #fff !important; }

.bg-inverse-light {
  background-color: #96143f;
  color: #fff !important; }

.bg-inverse-dark {
  color: #fff !important; }

.bg-inverse small {
  color: inherit; }

.bg-yellow {
  background-color: #FAD732;
  color: #fff !important; }

.bg-yellow-light {
  background-color: #fbe164;
  color: #fff !important; }

.bg-yellow-dark {
  color: #fff !important; }

.bg-yellow small {
  color: inherit; }

.bg-white {
  background-color: #fff;
  color: inherit !important; }

.bg-gray-darker {
  background-color: #474747;
  color: #fff !important; }

.bg-gray-dark {
  background-color: #474747;
  color: #fff !important; }

.bg-gray {
  background-color: #DDE6E9;
  color: #656565 !important; }

.bg-gray-light {
  background-color: #e4eaec;
  color: #656565 !important; }

.bg-gray-lighter {
  background-color: #F5F5F5;
  color: #656565 !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-cover {
  background-size: cover; }

.thumb8 {
  width: 8px !important;
  height: 8px !important; }

.thumb16 {
  width: 16px !important;
  height: 16px !important; }

.thumb24 {
  width: 24px !important;
  height: 24px !important; }

.thumb32 {
  width: 32px !important;
  height: 32px !important; }

.thumb48 {
  width: 48px !important;
  height: 48px !important; }

.thumb64 {
  width: 64px !important;
  height: 64px !important; }

.thumb80 {
  width: 80px !important;
  height: 80px !important; }

.thumb96 {
  width: 96px !important;
  height: 96px !important; }

.thumb128 {
  width: 128px !important;
  height: 128px !important; }

.align-middle {
  vertical-align: middle; }

.align-top {
  vertical-align: top; }

.align-bottom {
  vertical-align: bottom; }

.bg-center {
  background-position: center center;
  background-size: cover; }

.list-icon em {
  font-size: 14px;
  width: 40px;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  text-align: center;
  transition: all 0.2s;
  line-height: 30px; }
  @media (prefers-reduced-motion: reduce) {
    .list-icon em {
      transition: none; } }

.list-icon div:hover em {
  -webkit-transform: scale(3, 3);
          transform: scale(3, 3); }

.d-table-fixed {
  table-layout: fixed; }

.wd-xxs {
  width: 60px; }

.wd-xs {
  width: 90px; }

.wd-sm {
  width: 150px; }

.wd-sd {
  width: 200px; }

.wd-md {
  width: 240px; }

.wd-lg {
  width: 280px; }

.wd-xl {
  width: 320px; }

.wd-xxl {
  width: 360px; }

.wd-wide {
  width: 100%; }

.wd-auto {
  width: auto; }

.wd-zero {
  width: 0; }

.clickable {
  cursor: pointer; }

.abs-center-container {
  position: relative; }

.abs-center {
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .abs-center.abs-fixed {
    position: fixed;
    z-index: 999; }
  .abs-center.abs-right {
    left: auto;
    right: 20px;
    text-align: right; }
  .abs-center.abs-left {
    right: auto;
    left: 20px;
    text-align: left; }

@media (max-height: 720px) {
  .abs-center {
    position: relative; } }

.link-unstyled {
  text-decoration: none !important;
  outline: none !important; }

.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%; }

.ie-fix-flex {
  -ms-flex: 0 0 auto; }

.label-yellow {
  background-color: #FAD732; }
  .label-yellow[href]:hover, .label-yellow[href]:focus {
    background-color: #f3ca06; }

.label-dark {
  background-color: #474747; }
  .label-dark[href]:hover, .label-dark[href]:focus {
    background-color: #2e2e2e; }

/* ========================================================================
     Component: print
 ========================================================================== */
@media print {
  .sidebar,
  .topnavbar,
  .offsidebar,
  .btn {
    display: none !important;
    width: 0 !important;
    height: 0 !important; }
  .wrapper,
  .wrapper .section-container,
  .content-wrapper {
    margin: 0 !important;
    /* remove margin used for sidebar and expand the content */
    padding: 0 !important;
    width: 100% !important;
    border: 0;
    /* optional: remove top border used to separate the page heading */ }
  .content-wrapper {
    overflow: hidden !important; }
  /* hide sidebar container */
  .aside-container {
    display: none !important; }
  /* remove space used for the sidebar */
  .footer-container {
    margin: 0 !important; } }

.swal-modal {
  width: 380px;
  border-radius: 16px;
  padding-top: 24px;
  padding-bottom: 24px; }

.swal-title {
  color: #6A6A6A;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Roboto';
  padding: 0px; }

.swal-title:not(:last-child) {
  margin-bottom: 16px; }

.swal-text {
  color: #6A6A6A;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.6;
  text-align: center; }

.swal-footer {
  text-align: center;
  padding: 0px;
  margin-top: 24px; }

.swal-button {
  border-radius: 50px; }

.swal-button--cancel,
.swal-button--close {
  background-color: transparent;
  color: #BDBDBD;
  border: 1px solid #BDBDBD;
  min-width: 100px;
  height: 43px;
  display: flex;
  justify-content: center; }
  .swal-button--cancel:hover,
  .swal-button--close:hover {
    background-color: transparent !important;
    color: #BDBDBD !important;
    border: 1px solid #BDBDBD !important;
    opacity: 0.9; }

.swal-button--confirm {
  background-color: #ff5a8a !important;
  min-width: 100px;
  height: 43px; }
  .swal-button--confirm:hover {
    background-color: #ff5a8a !important;
    opacity: 0.9; }

.swal-icon--info {
  bottom: 4px;
  border-color: #6A6A6A;
  width: 40px;
  height: 40px; }

.swal-icon--info:before {
  width: 4px;
  height: 22px;
  bottom: 4px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #6A6A6A; }

.swal-icon--info:after {
  top: 4px;
  left: 21px;
  width: 4px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  background-color: #6A6A6A; }

.swal-icon {
  margin: 0 auto;
  margin-bottom: 16px;
  border-width: 2px; }

.swal-icon:first-child,
.swal-text:first-child,
.swal-title:first-child {
  margin-top: 0px; }

.swal-icon--error__line--left {
  left: 1px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.swal-icon--error__line {
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #f77066;
  border-radius: 2px;
  top: 38px; }

.swal-icon--error__x-mark {
  position: relative;
  display: block;
  bottom: 19px;
  left: 7px; }

.swal-icon--error {
  border-color: #f77066;
  width: 40px;
  height: 40px; }

.swal-icon--warning {
  border-color: #ff9529;
  width: 40px;
  height: 40px; }

.swal-icon--warning__dot,
.swal-icon--warning__body {
  background-color: #ff9529; }

.swal-icon--warning__dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: -1.825px;
  bottom: -11px; }

.swal-icon--warning__body {
  top: 6px;
  height: 18px;
  width: 3px; }

.swal-icon--success {
  margin-bottom: 0;
  z-index: 0; }

.swal-icon--success__ring {
  width: 40px;
  height: 40px;
  top: 16px;
  left: 16px;
  border: 3px solid #00a97a; }

.swal-icon--success__line--long {
  width: 24px;
  top: 38px;
  right: 24px;
  background-color: #00a97a; }

.swal-icon--success__line--tip {
  width: 14px;
  left: 24px;
  top: 41px;
  background-color: #00a97a; }

.swal-icon--success__line {
  height: 3px; }

/* ========================================================================
     Component: radial-bar
 ========================================================================== */
/* -------------------------------------
 * Bar container
 * ------------------------------------- */
.radial-bar {
  position: relative;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  border: 2px solid #fafafa;
  background-color: transparent;
  margin-bottom: 10px;
  width: 64px;
  height: 64px;
  font-size: 18px; }
  .radial-bar:after,
  .radial-bar > img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    color: #a1a2a3; }
  .radial-bar:after {
    content: attr(data-label);
    background-color: #fff;
    z-index: 101; }
  .radial-bar > img {
    z-index: 102; }
  .radial-bar:after,
  .radial-bar > img {
    width: 51.2px;
    height: 51.2px;
    margin-left: 6.4px;
    margin-top: 6.4px;
    line-height: 51.2px; }
  .radial-bar.radial-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-1 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(93.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-2 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(97.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-3 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(100.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-4 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(104.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-6 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(111.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-7 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(115.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-8 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(118.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-9 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(122.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-11 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(129.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-12 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(133.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-13 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(136.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-14 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(140.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-16 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(147.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-17 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(151.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-18 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(154.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-19 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(158.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-21 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(165.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-22 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(169.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-23 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(172.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-24 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(176.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-26 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(183.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-27 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(187.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-28 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(190.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-29 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(194.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-31 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(201.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-32 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(205.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-33 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(208.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-34 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(212.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-36 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(219.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-37 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(223.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-38 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(226.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-39 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(230.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-41 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(237.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-42 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(241.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-43 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(244.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-44 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(248.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-46 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(255.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-47 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(259.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-48 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(262.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-49 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(266.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-50 {
    background-image: linear-gradient(-90deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-51 {
    background-image: linear-gradient(-86.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-52 {
    background-image: linear-gradient(-82.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-53 {
    background-image: linear-gradient(-79.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-54 {
    background-image: linear-gradient(-75.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-55 {
    background-image: linear-gradient(-72deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-56 {
    background-image: linear-gradient(-68.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-57 {
    background-image: linear-gradient(-64.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-58 {
    background-image: linear-gradient(-61.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-59 {
    background-image: linear-gradient(-57.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-60 {
    background-image: linear-gradient(-54deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-61 {
    background-image: linear-gradient(-50.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-62 {
    background-image: linear-gradient(-46.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-63 {
    background-image: linear-gradient(-43.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-64 {
    background-image: linear-gradient(-39.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-65 {
    background-image: linear-gradient(-36deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-66 {
    background-image: linear-gradient(-32.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-67 {
    background-image: linear-gradient(-28.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-68 {
    background-image: linear-gradient(-25.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-69 {
    background-image: linear-gradient(-21.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-70 {
    background-image: linear-gradient(-18deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-71 {
    background-image: linear-gradient(-14.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-72 {
    background-image: linear-gradient(-10.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-73 {
    background-image: linear-gradient(-7.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-74 {
    background-image: linear-gradient(-3.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-75 {
    background-image: linear-gradient(0deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-76 {
    background-image: linear-gradient(3.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-77 {
    background-image: linear-gradient(7.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-78 {
    background-image: linear-gradient(10.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-79 {
    background-image: linear-gradient(14.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-80 {
    background-image: linear-gradient(18deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-81 {
    background-image: linear-gradient(21.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-82 {
    background-image: linear-gradient(25.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-83 {
    background-image: linear-gradient(28.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-84 {
    background-image: linear-gradient(32.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-85 {
    background-image: linear-gradient(36deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-86 {
    background-image: linear-gradient(39.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-87 {
    background-image: linear-gradient(43.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-88 {
    background-image: linear-gradient(46.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-89 {
    background-image: linear-gradient(50.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-90 {
    background-image: linear-gradient(54deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-91 {
    background-image: linear-gradient(57.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-92 {
    background-image: linear-gradient(61.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-93 {
    background-image: linear-gradient(64.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-94 {
    background-image: linear-gradient(68.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-95 {
    background-image: linear-gradient(72deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-96 {
    background-image: linear-gradient(75.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-97 {
    background-image: linear-gradient(79.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-98 {
    background-image: linear-gradient(82.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-99 {
    background-image: linear-gradient(86.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-100 {
    background-image: linear-gradient(90deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-1 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(93.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-2 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(97.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-3 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(100.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-4 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(104.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-6 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(111.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-7 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(115.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-8 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(118.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-9 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(122.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-11 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(129.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-12 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(133.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-13 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(136.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-14 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(140.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-16 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(147.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-17 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(151.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-18 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(154.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-19 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(158.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-21 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(165.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-22 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(169.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-23 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(172.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-24 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(176.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-26 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(183.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-27 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(187.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-28 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(190.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-29 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(194.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-31 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(201.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-32 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(205.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-33 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(208.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-34 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(212.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-36 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(219.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-37 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(223.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-38 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(226.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-39 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(230.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-41 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(237.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-42 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(241.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-43 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(244.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-44 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(248.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-46 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(255.6deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-47 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(259.2deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-48 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(262.8deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-49 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(266.4deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-50 {
  background-image: linear-gradient(-90deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-51 {
  background-image: linear-gradient(-86.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-52 {
  background-image: linear-gradient(-82.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-53 {
  background-image: linear-gradient(-79.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-54 {
  background-image: linear-gradient(-75.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-55 {
  background-image: linear-gradient(-72deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-56 {
  background-image: linear-gradient(-68.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-57 {
  background-image: linear-gradient(-64.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-58 {
  background-image: linear-gradient(-61.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-59 {
  background-image: linear-gradient(-57.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-60 {
  background-image: linear-gradient(-54deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-61 {
  background-image: linear-gradient(-50.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-62 {
  background-image: linear-gradient(-46.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-63 {
  background-image: linear-gradient(-43.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-64 {
  background-image: linear-gradient(-39.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-65 {
  background-image: linear-gradient(-36deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-66 {
  background-image: linear-gradient(-32.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-67 {
  background-image: linear-gradient(-28.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-68 {
  background-image: linear-gradient(-25.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-69 {
  background-image: linear-gradient(-21.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-70 {
  background-image: linear-gradient(-18deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-71 {
  background-image: linear-gradient(-14.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-72 {
  background-image: linear-gradient(-10.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-73 {
  background-image: linear-gradient(-7.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-74 {
  background-image: linear-gradient(-3.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-75 {
  background-image: linear-gradient(0deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-76 {
  background-image: linear-gradient(3.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-77 {
  background-image: linear-gradient(7.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-78 {
  background-image: linear-gradient(10.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-79 {
  background-image: linear-gradient(14.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-80 {
  background-image: linear-gradient(18deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-81 {
  background-image: linear-gradient(21.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-82 {
  background-image: linear-gradient(25.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-83 {
  background-image: linear-gradient(28.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-84 {
  background-image: linear-gradient(32.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-85 {
  background-image: linear-gradient(36deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-86 {
  background-image: linear-gradient(39.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-87 {
  background-image: linear-gradient(43.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-88 {
  background-image: linear-gradient(46.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-89 {
  background-image: linear-gradient(50.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-90 {
  background-image: linear-gradient(54deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-91 {
  background-image: linear-gradient(57.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-92 {
  background-image: linear-gradient(61.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-93 {
  background-image: linear-gradient(64.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-94 {
  background-image: linear-gradient(68.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-95 {
  background-image: linear-gradient(72deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-96 {
  background-image: linear-gradient(75.6deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-97 {
  background-image: linear-gradient(79.2deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-98 {
  background-image: linear-gradient(82.8deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-99 {
  background-image: linear-gradient(86.4deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-100 {
  background-image: linear-gradient(90deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-1 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(93.6deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-2 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(97.2deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-3 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(100.8deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-4 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(104.4deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-6 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(111.6deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-7 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(115.2deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-8 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(118.8deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-9 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(122.4deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-11 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(129.6deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-12 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(133.2deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-13 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(136.8deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-14 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(140.4deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-16 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(147.6deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-17 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(151.2deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-18 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(154.8deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-19 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(158.4deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-21 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(165.6deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-22 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(169.2deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-23 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(172.8deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-24 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(176.4deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-26 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(183.6deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-27 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(187.2deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-28 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(190.8deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-29 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(194.4deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-31 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(201.6deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-32 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(205.2deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-33 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(208.8deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-34 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(212.4deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-36 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(219.6deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-37 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(223.2deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-38 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(226.8deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-39 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(230.4deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-41 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(237.6deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-42 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(241.2deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-43 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(244.8deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-44 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(248.4deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-46 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(255.6deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-47 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(259.2deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-48 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(262.8deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-49 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(266.4deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-50 {
  background-image: linear-gradient(-90deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-51 {
  background-image: linear-gradient(-86.4deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-52 {
  background-image: linear-gradient(-82.8deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-53 {
  background-image: linear-gradient(-79.2deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-54 {
  background-image: linear-gradient(-75.6deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-55 {
  background-image: linear-gradient(-72deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-56 {
  background-image: linear-gradient(-68.4deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-57 {
  background-image: linear-gradient(-64.8deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-58 {
  background-image: linear-gradient(-61.2deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-59 {
  background-image: linear-gradient(-57.6deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-60 {
  background-image: linear-gradient(-54deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-61 {
  background-image: linear-gradient(-50.4deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-62 {
  background-image: linear-gradient(-46.8deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-63 {
  background-image: linear-gradient(-43.2deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-64 {
  background-image: linear-gradient(-39.6deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-65 {
  background-image: linear-gradient(-36deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-66 {
  background-image: linear-gradient(-32.4deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-67 {
  background-image: linear-gradient(-28.8deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-68 {
  background-image: linear-gradient(-25.2deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-69 {
  background-image: linear-gradient(-21.6deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-70 {
  background-image: linear-gradient(-18deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-71 {
  background-image: linear-gradient(-14.4deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-72 {
  background-image: linear-gradient(-10.8deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-73 {
  background-image: linear-gradient(-7.2deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-74 {
  background-image: linear-gradient(-3.6deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-75 {
  background-image: linear-gradient(0deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-76 {
  background-image: linear-gradient(3.6deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-77 {
  background-image: linear-gradient(7.2deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-78 {
  background-image: linear-gradient(10.8deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-79 {
  background-image: linear-gradient(14.4deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-80 {
  background-image: linear-gradient(18deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-81 {
  background-image: linear-gradient(21.6deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-82 {
  background-image: linear-gradient(25.2deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-83 {
  background-image: linear-gradient(28.8deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-84 {
  background-image: linear-gradient(32.4deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-85 {
  background-image: linear-gradient(36deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-86 {
  background-image: linear-gradient(39.6deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-87 {
  background-image: linear-gradient(43.2deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-88 {
  background-image: linear-gradient(46.8deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-89 {
  background-image: linear-gradient(50.4deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-90 {
  background-image: linear-gradient(54deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-91 {
  background-image: linear-gradient(57.6deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-92 {
  background-image: linear-gradient(61.2deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-93 {
  background-image: linear-gradient(64.8deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-94 {
  background-image: linear-gradient(68.4deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-95 {
  background-image: linear-gradient(72deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-96 {
  background-image: linear-gradient(75.6deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-97 {
  background-image: linear-gradient(79.2deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-98 {
  background-image: linear-gradient(82.8deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-99 {
  background-image: linear-gradient(86.4deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-100 {
  background-image: linear-gradient(90deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-1 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(93.6deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-2 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(97.2deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-3 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(100.8deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-4 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(104.4deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-6 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(111.6deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-7 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(115.2deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-8 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(118.8deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-9 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(122.4deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-11 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(129.6deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-12 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(133.2deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-13 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(136.8deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-14 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(140.4deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-16 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(147.6deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-17 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(151.2deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-18 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(154.8deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-19 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(158.4deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-21 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(165.6deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-22 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(169.2deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-23 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(172.8deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-24 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(176.4deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-26 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(183.6deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-27 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(187.2deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-28 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(190.8deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-29 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(194.4deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-31 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(201.6deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-32 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(205.2deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-33 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(208.8deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-34 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(212.4deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-36 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(219.6deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-37 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(223.2deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-38 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(226.8deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-39 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(230.4deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-41 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(237.6deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-42 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(241.2deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-43 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(244.8deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-44 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(248.4deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-46 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(255.6deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-47 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(259.2deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-48 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(262.8deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-49 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(266.4deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-50 {
  background-image: linear-gradient(-90deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-51 {
  background-image: linear-gradient(-86.4deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-52 {
  background-image: linear-gradient(-82.8deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-53 {
  background-image: linear-gradient(-79.2deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-54 {
  background-image: linear-gradient(-75.6deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-55 {
  background-image: linear-gradient(-72deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-56 {
  background-image: linear-gradient(-68.4deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-57 {
  background-image: linear-gradient(-64.8deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-58 {
  background-image: linear-gradient(-61.2deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-59 {
  background-image: linear-gradient(-57.6deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-60 {
  background-image: linear-gradient(-54deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-61 {
  background-image: linear-gradient(-50.4deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-62 {
  background-image: linear-gradient(-46.8deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-63 {
  background-image: linear-gradient(-43.2deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-64 {
  background-image: linear-gradient(-39.6deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-65 {
  background-image: linear-gradient(-36deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-66 {
  background-image: linear-gradient(-32.4deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-67 {
  background-image: linear-gradient(-28.8deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-68 {
  background-image: linear-gradient(-25.2deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-69 {
  background-image: linear-gradient(-21.6deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-70 {
  background-image: linear-gradient(-18deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-71 {
  background-image: linear-gradient(-14.4deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-72 {
  background-image: linear-gradient(-10.8deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-73 {
  background-image: linear-gradient(-7.2deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-74 {
  background-image: linear-gradient(-3.6deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-75 {
  background-image: linear-gradient(0deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-76 {
  background-image: linear-gradient(3.6deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-77 {
  background-image: linear-gradient(7.2deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-78 {
  background-image: linear-gradient(10.8deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-79 {
  background-image: linear-gradient(14.4deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-80 {
  background-image: linear-gradient(18deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-81 {
  background-image: linear-gradient(21.6deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-82 {
  background-image: linear-gradient(25.2deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-83 {
  background-image: linear-gradient(28.8deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-84 {
  background-image: linear-gradient(32.4deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-85 {
  background-image: linear-gradient(36deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-86 {
  background-image: linear-gradient(39.6deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-87 {
  background-image: linear-gradient(43.2deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-88 {
  background-image: linear-gradient(46.8deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-89 {
  background-image: linear-gradient(50.4deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-90 {
  background-image: linear-gradient(54deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-91 {
  background-image: linear-gradient(57.6deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-92 {
  background-image: linear-gradient(61.2deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-93 {
  background-image: linear-gradient(64.8deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-94 {
  background-image: linear-gradient(68.4deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-95 {
  background-image: linear-gradient(72deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-96 {
  background-image: linear-gradient(75.6deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-97 {
  background-image: linear-gradient(79.2deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-98 {
  background-image: linear-gradient(82.8deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-99 {
  background-image: linear-gradient(86.4deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-100 {
  background-image: linear-gradient(90deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-1 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(93.6deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-2 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(97.2deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-3 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(100.8deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-4 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(104.4deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-6 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(111.6deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-7 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(115.2deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-8 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(118.8deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-9 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(122.4deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-11 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(129.6deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-12 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(133.2deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-13 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(136.8deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-14 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(140.4deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-16 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(147.6deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-17 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(151.2deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-18 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(154.8deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-19 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(158.4deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-21 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(165.6deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-22 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(169.2deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-23 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(172.8deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-24 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(176.4deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-26 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(183.6deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-27 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(187.2deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-28 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(190.8deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-29 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(194.4deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-31 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(201.6deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-32 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(205.2deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-33 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(208.8deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-34 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(212.4deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-36 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(219.6deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-37 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(223.2deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-38 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(226.8deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-39 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(230.4deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-41 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(237.6deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-42 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(241.2deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-43 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(244.8deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-44 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(248.4deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-46 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(255.6deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-47 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(259.2deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-48 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(262.8deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-49 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(266.4deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-50 {
  background-image: linear-gradient(-90deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-51 {
  background-image: linear-gradient(-86.4deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-52 {
  background-image: linear-gradient(-82.8deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-53 {
  background-image: linear-gradient(-79.2deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-54 {
  background-image: linear-gradient(-75.6deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-55 {
  background-image: linear-gradient(-72deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-56 {
  background-image: linear-gradient(-68.4deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-57 {
  background-image: linear-gradient(-64.8deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-58 {
  background-image: linear-gradient(-61.2deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-59 {
  background-image: linear-gradient(-57.6deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-60 {
  background-image: linear-gradient(-54deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-61 {
  background-image: linear-gradient(-50.4deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-62 {
  background-image: linear-gradient(-46.8deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-63 {
  background-image: linear-gradient(-43.2deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-64 {
  background-image: linear-gradient(-39.6deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-65 {
  background-image: linear-gradient(-36deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-66 {
  background-image: linear-gradient(-32.4deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-67 {
  background-image: linear-gradient(-28.8deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-68 {
  background-image: linear-gradient(-25.2deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-69 {
  background-image: linear-gradient(-21.6deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-70 {
  background-image: linear-gradient(-18deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-71 {
  background-image: linear-gradient(-14.4deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-72 {
  background-image: linear-gradient(-10.8deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-73 {
  background-image: linear-gradient(-7.2deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-74 {
  background-image: linear-gradient(-3.6deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-75 {
  background-image: linear-gradient(0deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-76 {
  background-image: linear-gradient(3.6deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-77 {
  background-image: linear-gradient(7.2deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-78 {
  background-image: linear-gradient(10.8deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-79 {
  background-image: linear-gradient(14.4deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-80 {
  background-image: linear-gradient(18deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-81 {
  background-image: linear-gradient(21.6deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-82 {
  background-image: linear-gradient(25.2deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-83 {
  background-image: linear-gradient(28.8deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-84 {
  background-image: linear-gradient(32.4deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-85 {
  background-image: linear-gradient(36deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-86 {
  background-image: linear-gradient(39.6deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-87 {
  background-image: linear-gradient(43.2deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-88 {
  background-image: linear-gradient(46.8deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-89 {
  background-image: linear-gradient(50.4deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-90 {
  background-image: linear-gradient(54deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-91 {
  background-image: linear-gradient(57.6deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-92 {
  background-image: linear-gradient(61.2deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-93 {
  background-image: linear-gradient(64.8deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-94 {
  background-image: linear-gradient(68.4deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-95 {
  background-image: linear-gradient(72deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-96 {
  background-image: linear-gradient(75.6deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-97 {
  background-image: linear-gradient(79.2deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-98 {
  background-image: linear-gradient(82.8deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-99 {
  background-image: linear-gradient(86.4deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-100 {
  background-image: linear-gradient(90deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-1 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(93.6deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-2 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(97.2deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-3 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(100.8deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-4 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(104.4deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-6 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(111.6deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-7 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(115.2deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-8 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(118.8deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-9 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(122.4deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-11 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(129.6deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-12 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(133.2deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-13 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(136.8deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-14 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(140.4deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-16 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(147.6deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-17 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(151.2deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-18 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(154.8deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-19 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(158.4deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-21 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(165.6deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-22 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(169.2deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-23 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(172.8deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-24 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(176.4deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-26 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(183.6deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-27 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(187.2deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-28 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(190.8deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-29 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(194.4deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-31 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(201.6deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-32 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(205.2deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-33 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(208.8deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-34 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(212.4deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-36 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(219.6deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-37 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(223.2deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-38 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(226.8deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-39 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(230.4deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-41 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(237.6deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-42 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(241.2deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-43 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(244.8deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-44 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(248.4deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-46 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(255.6deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-47 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(259.2deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-48 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(262.8deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-49 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(266.4deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-50 {
  background-image: linear-gradient(-90deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-51 {
  background-image: linear-gradient(-86.4deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-52 {
  background-image: linear-gradient(-82.8deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-53 {
  background-image: linear-gradient(-79.2deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-54 {
  background-image: linear-gradient(-75.6deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-55 {
  background-image: linear-gradient(-72deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-56 {
  background-image: linear-gradient(-68.4deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-57 {
  background-image: linear-gradient(-64.8deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-58 {
  background-image: linear-gradient(-61.2deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-59 {
  background-image: linear-gradient(-57.6deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-60 {
  background-image: linear-gradient(-54deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-61 {
  background-image: linear-gradient(-50.4deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-62 {
  background-image: linear-gradient(-46.8deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-63 {
  background-image: linear-gradient(-43.2deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-64 {
  background-image: linear-gradient(-39.6deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-65 {
  background-image: linear-gradient(-36deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-66 {
  background-image: linear-gradient(-32.4deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-67 {
  background-image: linear-gradient(-28.8deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-68 {
  background-image: linear-gradient(-25.2deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-69 {
  background-image: linear-gradient(-21.6deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-70 {
  background-image: linear-gradient(-18deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-71 {
  background-image: linear-gradient(-14.4deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-72 {
  background-image: linear-gradient(-10.8deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-73 {
  background-image: linear-gradient(-7.2deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-74 {
  background-image: linear-gradient(-3.6deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-75 {
  background-image: linear-gradient(0deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-76 {
  background-image: linear-gradient(3.6deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-77 {
  background-image: linear-gradient(7.2deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-78 {
  background-image: linear-gradient(10.8deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-79 {
  background-image: linear-gradient(14.4deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-80 {
  background-image: linear-gradient(18deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-81 {
  background-image: linear-gradient(21.6deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-82 {
  background-image: linear-gradient(25.2deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-83 {
  background-image: linear-gradient(28.8deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-84 {
  background-image: linear-gradient(32.4deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-85 {
  background-image: linear-gradient(36deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-86 {
  background-image: linear-gradient(39.6deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-87 {
  background-image: linear-gradient(43.2deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-88 {
  background-image: linear-gradient(46.8deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-89 {
  background-image: linear-gradient(50.4deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-90 {
  background-image: linear-gradient(54deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-91 {
  background-image: linear-gradient(57.6deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-92 {
  background-image: linear-gradient(61.2deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-93 {
  background-image: linear-gradient(64.8deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-94 {
  background-image: linear-gradient(68.4deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-95 {
  background-image: linear-gradient(72deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-96 {
  background-image: linear-gradient(75.6deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-97 {
  background-image: linear-gradient(79.2deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-98 {
  background-image: linear-gradient(82.8deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-99 {
  background-image: linear-gradient(86.4deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-100 {
  background-image: linear-gradient(90deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-1 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(93.6deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-2 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(97.2deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-3 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(100.8deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-4 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(104.4deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-6 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(111.6deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-7 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(115.2deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-8 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(118.8deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-9 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(122.4deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-11 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(129.6deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-12 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(133.2deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-13 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(136.8deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-14 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(140.4deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-16 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(147.6deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-17 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(151.2deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-18 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(154.8deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-19 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(158.4deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-21 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(165.6deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-22 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(169.2deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-23 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(172.8deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-24 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(176.4deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-26 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(183.6deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-27 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(187.2deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-28 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(190.8deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-29 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(194.4deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-31 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(201.6deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-32 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(205.2deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-33 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(208.8deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-34 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(212.4deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-36 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(219.6deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-37 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(223.2deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-38 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(226.8deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-39 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(230.4deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-41 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(237.6deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-42 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(241.2deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-43 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(244.8deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-44 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(248.4deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-46 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(255.6deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-47 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(259.2deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-48 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(262.8deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-49 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(266.4deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-50 {
  background-image: linear-gradient(-90deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-51 {
  background-image: linear-gradient(-86.4deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-52 {
  background-image: linear-gradient(-82.8deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-53 {
  background-image: linear-gradient(-79.2deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-54 {
  background-image: linear-gradient(-75.6deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-55 {
  background-image: linear-gradient(-72deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-56 {
  background-image: linear-gradient(-68.4deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-57 {
  background-image: linear-gradient(-64.8deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-58 {
  background-image: linear-gradient(-61.2deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-59 {
  background-image: linear-gradient(-57.6deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-60 {
  background-image: linear-gradient(-54deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-61 {
  background-image: linear-gradient(-50.4deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-62 {
  background-image: linear-gradient(-46.8deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-63 {
  background-image: linear-gradient(-43.2deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-64 {
  background-image: linear-gradient(-39.6deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-65 {
  background-image: linear-gradient(-36deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-66 {
  background-image: linear-gradient(-32.4deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-67 {
  background-image: linear-gradient(-28.8deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-68 {
  background-image: linear-gradient(-25.2deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-69 {
  background-image: linear-gradient(-21.6deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-70 {
  background-image: linear-gradient(-18deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-71 {
  background-image: linear-gradient(-14.4deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-72 {
  background-image: linear-gradient(-10.8deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-73 {
  background-image: linear-gradient(-7.2deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-74 {
  background-image: linear-gradient(-3.6deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-75 {
  background-image: linear-gradient(0deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-76 {
  background-image: linear-gradient(3.6deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-77 {
  background-image: linear-gradient(7.2deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-78 {
  background-image: linear-gradient(10.8deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-79 {
  background-image: linear-gradient(14.4deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-80 {
  background-image: linear-gradient(18deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-81 {
  background-image: linear-gradient(21.6deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-82 {
  background-image: linear-gradient(25.2deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-83 {
  background-image: linear-gradient(28.8deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-84 {
  background-image: linear-gradient(32.4deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-85 {
  background-image: linear-gradient(36deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-86 {
  background-image: linear-gradient(39.6deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-87 {
  background-image: linear-gradient(43.2deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-88 {
  background-image: linear-gradient(46.8deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-89 {
  background-image: linear-gradient(50.4deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-90 {
  background-image: linear-gradient(54deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-91 {
  background-image: linear-gradient(57.6deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-92 {
  background-image: linear-gradient(61.2deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-93 {
  background-image: linear-gradient(64.8deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-94 {
  background-image: linear-gradient(68.4deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-95 {
  background-image: linear-gradient(72deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-96 {
  background-image: linear-gradient(75.6deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-97 {
  background-image: linear-gradient(79.2deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-98 {
  background-image: linear-gradient(82.8deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-99 {
  background-image: linear-gradient(86.4deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-yellow.radial-bar-100 {
  background-image: linear-gradient(90deg, #FAD732 50%, transparent 50%, transparent), linear-gradient(270deg, #FAD732 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-1 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(93.6deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-2 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(97.2deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-3 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(100.8deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-4 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(104.4deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-6 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(111.6deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-7 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(115.2deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-8 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(118.8deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-9 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(122.4deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-11 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(129.6deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-12 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(133.2deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-13 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(136.8deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-14 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(140.4deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-16 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(147.6deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-17 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(151.2deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-18 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(154.8deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-19 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(158.4deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-21 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(165.6deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-22 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(169.2deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-23 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(172.8deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-24 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(176.4deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-26 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(183.6deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-27 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(187.2deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-28 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(190.8deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-29 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(194.4deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-31 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(201.6deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-32 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(205.2deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-33 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(208.8deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-34 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(212.4deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-36 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(219.6deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-37 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(223.2deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-38 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(226.8deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-39 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(230.4deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-41 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(237.6deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-42 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(241.2deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-43 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(244.8deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-44 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(248.4deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-46 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(255.6deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-47 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(259.2deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-48 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(262.8deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-49 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(266.4deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-50 {
  background-image: linear-gradient(-90deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-51 {
  background-image: linear-gradient(-86.4deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-52 {
  background-image: linear-gradient(-82.8deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-53 {
  background-image: linear-gradient(-79.2deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-54 {
  background-image: linear-gradient(-75.6deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-55 {
  background-image: linear-gradient(-72deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-56 {
  background-image: linear-gradient(-68.4deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-57 {
  background-image: linear-gradient(-64.8deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-58 {
  background-image: linear-gradient(-61.2deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-59 {
  background-image: linear-gradient(-57.6deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-60 {
  background-image: linear-gradient(-54deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-61 {
  background-image: linear-gradient(-50.4deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-62 {
  background-image: linear-gradient(-46.8deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-63 {
  background-image: linear-gradient(-43.2deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-64 {
  background-image: linear-gradient(-39.6deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-65 {
  background-image: linear-gradient(-36deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-66 {
  background-image: linear-gradient(-32.4deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-67 {
  background-image: linear-gradient(-28.8deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-68 {
  background-image: linear-gradient(-25.2deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-69 {
  background-image: linear-gradient(-21.6deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-70 {
  background-image: linear-gradient(-18deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-71 {
  background-image: linear-gradient(-14.4deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-72 {
  background-image: linear-gradient(-10.8deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-73 {
  background-image: linear-gradient(-7.2deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-74 {
  background-image: linear-gradient(-3.6deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-75 {
  background-image: linear-gradient(0deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-76 {
  background-image: linear-gradient(3.6deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-77 {
  background-image: linear-gradient(7.2deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-78 {
  background-image: linear-gradient(10.8deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-79 {
  background-image: linear-gradient(14.4deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-80 {
  background-image: linear-gradient(18deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-81 {
  background-image: linear-gradient(21.6deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-82 {
  background-image: linear-gradient(25.2deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-83 {
  background-image: linear-gradient(28.8deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-84 {
  background-image: linear-gradient(32.4deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-85 {
  background-image: linear-gradient(36deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-86 {
  background-image: linear-gradient(39.6deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-87 {
  background-image: linear-gradient(43.2deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-88 {
  background-image: linear-gradient(46.8deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-89 {
  background-image: linear-gradient(50.4deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-90 {
  background-image: linear-gradient(54deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-91 {
  background-image: linear-gradient(57.6deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-92 {
  background-image: linear-gradient(61.2deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-93 {
  background-image: linear-gradient(64.8deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-94 {
  background-image: linear-gradient(68.4deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-95 {
  background-image: linear-gradient(72deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-96 {
  background-image: linear-gradient(75.6deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-97 {
  background-image: linear-gradient(79.2deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-98 {
  background-image: linear-gradient(82.8deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-99 {
  background-image: linear-gradient(86.4deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-green.radial-bar-100 {
  background-image: linear-gradient(90deg, #1EE831 50%, transparent 50%, transparent), linear-gradient(270deg, #1EE831 50%, #fafafa 50%, #fafafa); }

.radial-bar-lg {
  width: 80px;
  height: 80px;
  font-size: 22px; }
  .radial-bar-lg:after,
  .radial-bar-lg > img {
    width: 64px;
    height: 64px;
    margin-left: 8px;
    margin-top: 8px;
    line-height: 64px; }

.radial-bar-sm {
  width: 40px;
  height: 40px;
  font-size: 12px; }
  .radial-bar-sm:after,
  .radial-bar-sm > img {
    width: 32px;
    height: 32px;
    margin-left: 4px;
    margin-top: 4px;
    line-height: 32px; }

.radial-bar-xs {
  width: 24px;
  height: 24px;
  font-size: 8px; }
  .radial-bar-xs:after,
  .radial-bar-xs > img {
    width: 19.2px;
    height: 19.2px;
    margin-left: 2.4px;
    margin-top: 2.4px;
    line-height: 19.2px; }

x:-o-prefocus,
.radial-bar {
  background-clip: content-box; }

.f-modal-alert {
  background-color: #fff;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 20px;
  padding: 50px; }

.f-modal-alert .f-modal-icon {
  border-radius: 50%;
  border: 4px solid gray;
  box-sizing: content-box;
  height: 80px;
  margin: 20px auto;
  padding: 0;
  position: relative;
  width: 80px; }
  .f-modal-alert .f-modal-icon.f-modal-success, .f-modal-alert .f-modal-icon.f-modal-error {
    border-color: #A5DC86; }
    .f-modal-alert .f-modal-icon.f-modal-success:after, .f-modal-alert .f-modal-icon.f-modal-success:before, .f-modal-alert .f-modal-icon.f-modal-error:after, .f-modal-alert .f-modal-icon.f-modal-error:before {
      background: #fff;
      content: '';
      height: 120px;
      position: absolute;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      width: 60px; }
    .f-modal-alert .f-modal-icon.f-modal-success:before, .f-modal-alert .f-modal-icon.f-modal-error:before {
      border-radius: 120px 0 0 120px;
      left: -33px;
      top: -7px;
      -webkit-transform-origin: 60px 60px;
              transform-origin: 60px 60px;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    .f-modal-alert .f-modal-icon.f-modal-success:after, .f-modal-alert .f-modal-icon.f-modal-error:after {
      border-radius: 0 120px 120px 0;
      left: 30px;
      top: -11px;
      -webkit-transform-origin: 0 60px;
              transform-origin: 0 60px;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    .f-modal-alert .f-modal-icon.f-modal-success .f-modal-placeholder, .f-modal-alert .f-modal-icon.f-modal-error .f-modal-placeholder {
      border-radius: 50%;
      border: 4px solid rgba(165, 220, 134, 0.2);
      box-sizing: content-box;
      height: 80px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 80px;
      z-index: 2; }
    .f-modal-alert .f-modal-icon.f-modal-success .f-modal-fix, .f-modal-alert .f-modal-icon.f-modal-error .f-modal-fix {
      background-color: #fff;
      height: 90px;
      left: 28px;
      position: absolute;
      top: 8px;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
      width: 5px;
      z-index: 1; }
    .f-modal-alert .f-modal-icon.f-modal-success .f-modal-line, .f-modal-alert .f-modal-icon.f-modal-error .f-modal-line {
      background-color: #A5DC86;
      border-radius: 2px;
      display: block;
      height: 5px;
      position: absolute;
      z-index: 2; }
      .f-modal-alert .f-modal-icon.f-modal-success .f-modal-line.f-modal-tip, .f-modal-alert .f-modal-icon.f-modal-error .f-modal-line.f-modal-tip {
        left: 14px;
        top: 46px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        width: 25px; }
      .f-modal-alert .f-modal-icon.f-modal-success .f-modal-line.f-modal-long, .f-modal-alert .f-modal-icon.f-modal-error .f-modal-line.f-modal-long {
        right: 8px;
        top: 38px;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
        width: 47px; }
  .f-modal-alert .f-modal-icon.f-modal-error {
    border-color: #F27474; }
    .f-modal-alert .f-modal-icon.f-modal-error .f-modal-x-mark {
      display: block;
      position: relative;
      z-index: 2; }
    .f-modal-alert .f-modal-icon.f-modal-error .f-modal-placeholder {
      border: 4px solid rgba(200, 0, 0, 0.2); }
    .f-modal-alert .f-modal-icon.f-modal-error .f-modal-line {
      background-color: #F27474;
      top: 37px;
      width: 47px; }
      .f-modal-alert .f-modal-icon.f-modal-error .f-modal-line.f-modal-left {
        left: 17px;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg); }
      .f-modal-alert .f-modal-icon.f-modal-error .f-modal-line.f-modal-right {
        right: 16px;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg); }
  .f-modal-alert .f-modal-icon.f-modal-warning {
    border-color: #F8BB86; }
    .f-modal-alert .f-modal-icon.f-modal-warning:before {
      -webkit-animation: pulseWarning 2s linear infinite;
              animation: pulseWarning 2s linear infinite;
      background-color: #fff;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 100%;
      opacity: 0;
      position: absolute;
      width: 100%; }
    .f-modal-alert .f-modal-icon.f-modal-warning:after {
      background-color: #fff;
      border-radius: 50%;
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 1; }
  .f-modal-alert .f-modal-icon.f-modal-warning .f-modal-body {
    background-color: #F8BB86;
    border-radius: 2px;
    height: 47px;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    top: 10px;
    width: 5px;
    z-index: 2; }
  .f-modal-alert .f-modal-icon.f-modal-warning .f-modal-dot {
    background-color: #F8BB86;
    border-radius: 50%;
    bottom: 10px;
    height: 7px;
    left: 50%;
    margin-left: -3px;
    position: absolute;
    width: 7px;
    z-index: 2; }
  .f-modal-alert .f-modal-icon + .f-modal-icon {
    margin-top: 50px; }

.animateSuccessTip {
  -webkit-animation: animateSuccessTip .75s;
          animation: animateSuccessTip .75s; }

.animateSuccessLong {
  -webkit-animation: animateSuccessLong .75s;
          animation: animateSuccessLong .75s; }

.f-modal-icon.f-modal-success.animate:after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
          animation: rotatePlaceholder 4.25s ease-in; }

.f-modal-icon.f-modal-error.animate:after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
          animation: rotatePlaceholder 4.25s ease-in; }

.animateErrorIcon {
  -webkit-animation: animateErrorIcon .5s;
          animation: animateErrorIcon .5s; }

.animateXLeft {
  -webkit-animation: animateXLeft .75s;
          animation: animateXLeft .75s; }

.animateXRight {
  -webkit-animation: animateXRight .75s;
          animation: animateXRight .75s; }

.scaleWarning {
  -webkit-animation: scaleWarning 0.75s infinite alternate;
          animation: scaleWarning 0.75s infinite alternate; }

.pulseWarningIns {
  -webkit-animation: pulseWarningIns 0.75s infinite alternate;
          animation: pulseWarningIns 0.75s infinite alternate; }

@-webkit-keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animateSuccessTip {
  0%, 54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@-webkit-keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@keyframes animateSuccessLong {
  0%, 65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@-webkit-keyframes rotatePlaceholder {
  0%, 5% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  100%, 12% {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg); } }

@keyframes rotatePlaceholder {
  0%, 5% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  100%, 12% {
    -webkit-transform: rotate(-405deg);
            transform: rotate(-405deg); } }

@-webkit-keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    opacity: 1; } }

@keyframes animateErrorIcon {
  0% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    opacity: 1; } }

@-webkit-keyframes animateXLeft {
  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0; }
  84% {
    left: 14px;
    top: 33px;
    width: 47px; }
  100% {
    left: 17px;
    top: 37px;
    width: 47px; } }

@keyframes animateXLeft {
  0%,
  65% {
    left: 82px;
    top: 95px;
    width: 0; }
  84% {
    left: 14px;
    top: 33px;
    width: 47px; }
  100% {
    left: 17px;
    top: 37px;
    width: 47px; } }

@-webkit-keyframes animateXRight {
  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0; }
  84% {
    right: 14px;
    top: 33px;
    width: 47px; }
  100% {
    right: 16px;
    top: 37px;
    width: 47px; } }

@keyframes animateXRight {
  0%,
  65% {
    right: 82px;
    top: 95px;
    width: 0; }
  84% {
    right: 14px;
    top: 33px;
    width: 47px; }
  100% {
    right: 16px;
    top: 37px;
    width: 47px; } }

@-webkit-keyframes scaleWarning {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes scaleWarning {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  30% {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes pulseWarning {
  0% {
    background-color: #fff;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5; }
  30% {
    background-color: #fff;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5; }
  100% {
    background-color: #F8BB86;
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0; } }

@keyframes pulseWarning {
  0% {
    background-color: #fff;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5; }
  30% {
    background-color: #fff;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.5; }
  100% {
    background-color: #F8BB86;
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0; } }

@-webkit-keyframes pulseWarningIns {
  0% {
    background-color: #F8D486; }
  100% {
    background-color: #F8BB86; } }

@keyframes pulseWarningIns {
  0% {
    background-color: #F8D486; }
  100% {
    background-color: #F8BB86; } }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  margin-left: -8px;
  position: absolute; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae; }

.react-datepicker-wrapper {
  display: inline-block; }

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.12rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.42rem;
  display: inline-block;
  position: relative; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 3; }

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px; }

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px; }

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px; }

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px; }

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px; }

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto; }

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
  padding-top: 8px;
  position: relative; }

.react-datepicker__header--time {
  padding-bottom: 8px; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px; }

.react-datepicker__current-month,
.react-datepicker-time__header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 1.3216rem; }

.react-datepicker__navigation {
  line-height: 2.38rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  border: 0.63rem solid transparent;
  z-index: 1; }

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc; }

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3; }

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc; }

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px; }

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3; }

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc; }

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3; }

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc; }

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3; }

.react-datepicker__month-container {
  float: left; }

.react-datepicker__month {
  margin: 0.56rem;
  text-align: center; }

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae; }

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.42rem;
  position: absolute;
  right: -72px;
  top: 0; }

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  overflow-y: scroll;
  padding-right: 30px;
  width: 100%; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 5px 10px; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent; }

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 2.38rem;
  line-height: 2.38rem;
  text-align: center;
  margin: 0.2324rem; }

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer; }

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.42rem;
  background-color: #f0f0f0; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 2.38rem;
  line-height: 2.38rem;
  text-align: center;
  margin: 0.2324rem; }

.react-datepicker__day {
  cursor: pointer; }

.react-datepicker__day:hover {
  border-radius: 0.42rem;
  background-color: #f0f0f0; }

.react-datepicker__day--today {
  font-weight: bold; }

.react-datepicker__day--highlighted {
  border-radius: 0.42rem;
  background-color: #3dcc4a;
  color: #fff; }

.react-datepicker__day--highlighted:hover {
  background-color: #32be3f; }

.react-datepicker__day--highlighted-custom-1 {
  color: magenta; }

.react-datepicker__day--highlighted-custom-2 {
  color: green; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  border-radius: 0.42rem;
  background-color: #216ba5;
  color: #fff; }

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: #1d5d90; }

.react-datepicker__day--keyboard-selected {
  border-radius: 0.42rem;
  background-color: #2a87d0;
  color: #fff; }

.react-datepicker__day--keyboard-selected:hover {
  background-color: #1d5d90; }

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(33, 107, 165, 0.5); }

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000; }

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc; }

.react-datepicker__day--disabled:hover {
  background-color: transparent; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
  border: 1px solid transparent;
  border-radius: 0.42rem; }

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover {
  cursor: pointer; }

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3; }

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.63rem; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.42rem;
  border: 1px solid #aeaeae; }

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover {
  cursor: pointer; }

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type {
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem; }

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem; }

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover {
  background-color: #ccc; }

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3; }

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3; }

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected {
  position: absolute;
  left: 15px; }

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle; }

.react-datepicker__close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\00d7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 7px;
  text-align: center;
  top: 50%; }

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 4.2rem;
  line-height: 4.2rem; }

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2.8rem;
    line-height: 2.8rem; } }

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 2.016rem; }

.react-datepicker__time-list-item {
  width: 50px; }

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.react-datepicker__portal .react-datepicker__navigation {
  border: 1.134rem solid transparent; }

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc; }

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3; }

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc; }

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3; }

/* ========================================================================
     Component: progress bar for big5
 ========================================================================== */
.progress {
  background-color: #f1f1f1; }

.progress.left .progress-bar {
  width: 50%; }

.progress.right .progress-bar {
  float: right;
  flex-direction: row-reverse;
  width: 50%; }

.big5-progress-margin-right {
  margin-right: 0;
  padding-right: 0; }

.big5-progress-margin-left {
  margin-left: 0;
  padding-left: 0;
  border-left: 1px #656565 dotted; }

/* ========================================================================
     Component: nestable
 ========================================================================== */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px; }

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none; }

.dd-list .dd-list {
  padding-left: 30px; }

.dd-collapsed .dd-list {
  display: none; }

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px; }

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 10px 10px;
  text-decoration: none;
  border: 1px solid #ebebeb;
  background: #fff;
  border-radius: 3px; }

.dd-handle:hover {
  background: #fff; }

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 30px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold; }

.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0; }

.dd-item > button[data-action="collapse"]:before {
  content: '-'; }

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px; }

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999; }

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0; }

.dd-dragel .dd-handle {
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1); }

.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd; }

@media only screen and (min-width: 700px) {
  .dd + .dd {
    margin-left: 2%; } }

.dd-hover > .dd-handle {
  background: #2ea8e5 !important; }

.dd3-content {
  display: block;
  margin: 5px 0;
  padding: 10px 10px 10px 50px;
  text-decoration: none;
  border: 1px solid #ebebeb;
  background: #fff;
  border-radius: 3px; }

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0; }

.dd3-item > button {
  margin-left: 40px; }

.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 40px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #ebebeb;
  background: #fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.dd3-handle:before {
  content: '≡';
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #ccc;
  font-size: 20px;
  font-weight: normal; }

.dd3-handle:hover {
  background: #f7f7f7; }

.Toastify__toast-container--top-right,
.Toastify__toast-container--top-left,
.Toastify__toast-container--top-center {
  top: 48px !important; }

@media (min-width: 768px) {
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--bottom-left {
    left: 220px !important; } }

/* ========================================================================
   Component: spinner
 ========================================================================== */
body .whirl {
  position: relative; }
  body .whirl:before {
    content: attr(data-spinner-text);
    display: block;
    background-color: #f0f0f0;
    opacity: 0.7; }
  body .whirl.solid {
    opacity: 1; }
  body .whirl:after {
    border-top-color: #ff5a8a; }

body .whirl.blade:after {
  border-bottom-color: #ff5a8a; }

body .whirl.double-up:after {
  border-right-color: #ff5a8a;
  border-top-color: #ff5a8a;
  border-left-color: #ff5a8a;
  border-bottom-color: #ff5a8a; }

body .whirl.duo:after {
  border-right-color: #ff5a8a;
  border-left-color: #ff5a8a;
  border-top-color: #CC14F5;
  border-bottom-color: #CC14F5; }

body .whirl.helicopter:after {
  background-color: #ff5a8a; }

body .whirl.ringed:after {
  border-right-color: #0092E4;
  border-top-color: #CC14F5;
  border-left-color: #CC14F5;
  border-bottom-color: #CC14F5; }

body .whirl.traditional:after {
  border-right-color: #0092E4;
  border-top-color: #0092E4;
  border-left-color: #CC14F5;
  border-bottom-color: #FAD732; }

body .whirl.line:after {
  box-shadow: inset -10px 0px 0px rgba(255, 90, 138, 0.5); }

.no-cssanimations body .whirl:before {
  opacity: .9; }

.no-cssanimations body .whirl:after {
  content: "Loading...";
  width: 40px;
  height: 40px;
  margin: -20px 0 -20px;
  line-height: 40px;
  color: #000;
  opacity: 1;
  border: 0;
  box-shadow: 0 0 0 #000;
  background-color: transparent;
  text-align: center; }

body .ball-pulse > div,
body .ball-grid-pulse > div,
body .square-spin > div,
body .ball-pulse-rise > div,
body .ball-rotate > div,
body .ball-rotate > div:before,
body .ball-rotate > div:after,
body .cube-transition > div,
body .ball-zig-zag > div,
body .ball-zig-zag-deflect > div,
body .ball-triangle-path > div,
body .ball-scale > div,
body .line-scale > div,
body .line-scale-party > div,
body .ball-scale-multiple > div,
body .ball-pulse-sync > div,
body .ball-beat > div,
body .line-scale-pulse-out > div,
body .line-scale-pulse-out-rapid > div,
body .ball-spin-fade-loader > div,
body .line-spin-fade-loader > div,
body .triangle-skew-spin > div,
body .ball-grid-beat > div,
body .semi-circle-spin > div {
  background-color: #ff5a8a; }

body .ball-clip-rotate-multiple {
  width: 1px; }
  body .ball-clip-rotate-multiple > div {
    border-color: #ff5a8a transparent #ff5a8a transparent; }
    body .ball-clip-rotate-multiple > div:last-child {
      border-color: transparent #ff5a8a transparent #ff5a8a; }

body .ball-clip-rotate > div {
  border-color: #ff5a8a;
  border-bottom-color: transparent; }

body .ball-clip-rotate-pulse > div:first-child {
  background-color: #ff5a8a;
  top: 5px;
  left: -8px; }

body .ball-clip-rotate-pulse > div:last-child {
  border-color: #ff5a8a transparent #ff5a8a transparent; }

body .square-spin > div,
body .ball-scale-ripple > div,
body .ball-scale-ripple-multiple > div {
  border-color: #ff5a8a; }

body .pacman > div:first-of-type {
  border-top-color: #ff5a8a;
  border-left-color: #ff5a8a;
  border-bottom-color: #ff5a8a; }

body .pacman > div:nth-child(2) {
  border-top-color: #ff5a8a;
  border-left-color: #ff5a8a;
  border-bottom-color: #ff5a8a; }

body .pacman > div:nth-child(3),
body .pacman > div:nth-child(4),
body .pacman > div:nth-child(5),
body .pacman > div:nth-child(6) {
  background-color: #ff5a8a; }

body .sk-rotating-plane.sk-spinner,
body .sk-double-bounce .sk-double-bounce1,
body .sk-double-bounce .sk-double-bounce2,
body .sk-wave .sk-rect,
body .sk-wandering-cubes .sk-cube1,
body .sk-wandering-cubes .sk-cube2,
body .sk-chasing-dots .sk-dot1,
body .sk-chasing-dots .sk-dot2,
body .sk-three-bounce div,
body .sk-circle .sk-child:before,
body .sk-cube-grid .sk-cube,
body .sk-fading-circle .sk-circle:before,
body .sk-spinner-pulse.sk-spinner,
body .sk-rotating-plane {
  background-color: #ff5a8a !important; }

body .sk-wordpress .sk-inner-circle {
  background-color: #fff; }

body .sk-wave .sk-rect {
  float: left;
  margin-right: 1px; }

/* just for loaders.css demo */
.loader-demo {
  height: 150px;
  padding-top: 50px;
  text-align: center; }
  .loader-demo > div {
    display: inline-block; }
  .loader-demo.loader-demo-sk {
    padding-top: 0; }

/* ========================================================================
     Component: radial-bar
 ========================================================================== */
/* -------------------------------------
 * Bar container
 * ------------------------------------- */
.radial-bar {
  position: relative;
  display: inline-block;
  font-size: 16px;
  border-radius: 50%;
  border: 2px solid #fafafa;
  background-color: transparent;
  margin-bottom: 10px;
  box-sizing: content-box;
  width: 64px;
  height: 64px;
  font-size: 18px; }
  .radial-bar:after,
  .radial-bar > img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    color: #a1a2a3; }
  .radial-bar:after {
    content: attr(data-label);
    background-color: #fff;
    z-index: 101; }
  .radial-bar > img {
    z-index: 102; }
  .radial-bar:after,
  .radial-bar > img {
    width: 51.2px;
    height: 51.2px;
    margin-left: 6.4px;
    margin-top: 6.4px;
    line-height: 51.2px; }
  .radial-bar.radial-bar-0 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-5 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-10 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-15 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-20 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-25 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-30 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-35 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-40 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-45 {
    background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-50 {
    background-image: linear-gradient(-90deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-55 {
    background-image: linear-gradient(-72deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-60 {
    background-image: linear-gradient(-54deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-65 {
    background-image: linear-gradient(-36deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-70 {
    background-image: linear-gradient(-18deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-75 {
    background-image: linear-gradient(0deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-80 {
    background-image: linear-gradient(18deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-85 {
    background-image: linear-gradient(36deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-90 {
    background-image: linear-gradient(54deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-95 {
    background-image: linear-gradient(72deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }
  .radial-bar.radial-bar-100 {
    background-image: linear-gradient(90deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-50 {
  background-image: linear-gradient(-90deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-55 {
  background-image: linear-gradient(-72deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-60 {
  background-image: linear-gradient(-54deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-65 {
  background-image: linear-gradient(-36deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-70 {
  background-image: linear-gradient(-18deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-75 {
  background-image: linear-gradient(0deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-80 {
  background-image: linear-gradient(18deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-85 {
  background-image: linear-gradient(36deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-90 {
  background-image: linear-gradient(54deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-95 {
  background-image: linear-gradient(72deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-primary.radial-bar-100 {
  background-image: linear-gradient(90deg, #ff5a8a 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5a8a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-50 {
  background-image: linear-gradient(-90deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-55 {
  background-image: linear-gradient(-72deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-60 {
  background-image: linear-gradient(-54deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-65 {
  background-image: linear-gradient(-36deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-70 {
  background-image: linear-gradient(-18deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-75 {
  background-image: linear-gradient(0deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-80 {
  background-image: linear-gradient(18deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-85 {
  background-image: linear-gradient(36deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-90 {
  background-image: linear-gradient(54deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-95 {
  background-image: linear-gradient(72deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-success.radial-bar-100 {
  background-image: linear-gradient(90deg, #00a97a 50%, transparent 50%, transparent), linear-gradient(270deg, #00a97a 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-50 {
  background-image: linear-gradient(-90deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-55 {
  background-image: linear-gradient(-72deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-60 {
  background-image: linear-gradient(-54deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-65 {
  background-image: linear-gradient(-36deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-70 {
  background-image: linear-gradient(-18deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-75 {
  background-image: linear-gradient(0deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-80 {
  background-image: linear-gradient(18deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-85 {
  background-image: linear-gradient(36deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-90 {
  background-image: linear-gradient(54deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-95 {
  background-image: linear-gradient(72deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-info.radial-bar-100 {
  background-image: linear-gradient(90deg, #0092E4 50%, transparent 50%, transparent), linear-gradient(270deg, #0092E4 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-50 {
  background-image: linear-gradient(-90deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-55 {
  background-image: linear-gradient(-72deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-60 {
  background-image: linear-gradient(-54deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-65 {
  background-image: linear-gradient(-36deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-70 {
  background-image: linear-gradient(-18deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-75 {
  background-image: linear-gradient(0deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-80 {
  background-image: linear-gradient(18deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-85 {
  background-image: linear-gradient(36deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-90 {
  background-image: linear-gradient(54deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-95 {
  background-image: linear-gradient(72deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-warning.radial-bar-100 {
  background-image: linear-gradient(90deg, #F57C00 50%, transparent 50%, transparent), linear-gradient(270deg, #F57C00 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-0 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(90deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-5 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(108deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-10 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(126deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-15 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(144deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-20 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(162deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-25 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(180deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-30 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(198deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-35 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(216deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-40 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(234deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-45 {
  background-image: linear-gradient(90deg, #fafafa 50%, transparent 50%, transparent), linear-gradient(252deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-50 {
  background-image: linear-gradient(-90deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-55 {
  background-image: linear-gradient(-72deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-60 {
  background-image: linear-gradient(-54deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-65 {
  background-image: linear-gradient(-36deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-70 {
  background-image: linear-gradient(-18deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-75 {
  background-image: linear-gradient(0deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-80 {
  background-image: linear-gradient(18deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-85 {
  background-image: linear-gradient(36deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-90 {
  background-image: linear-gradient(54deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-95 {
  background-image: linear-gradient(72deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-danger.radial-bar-100 {
  background-image: linear-gradient(90deg, #F44336 50%, transparent 50%, transparent), linear-gradient(270deg, #F44336 50%, #fafafa 50%, #fafafa); }

.radial-bar-lg {
  width: 80px;
  height: 80px;
  font-size: 22px; }
  .radial-bar-lg:after,
  .radial-bar-lg > img {
    width: 64px;
    height: 64px;
    margin-left: 8px;
    margin-top: 8px;
    line-height: 64px; }

.radial-bar-sm {
  width: 40px;
  height: 40px;
  font-size: 12px; }
  .radial-bar-sm:after,
  .radial-bar-sm > img {
    width: 32px;
    height: 32px;
    margin-left: 4px;
    margin-top: 4px;
    line-height: 32px; }

.radial-bar-xs {
  width: 24px;
  height: 24px;
  font-size: 8px; }
  .radial-bar-xs:after,
  .radial-bar-xs > img {
    width: 19.2px;
    height: 19.2px;
    margin-left: 2.4px;
    margin-top: 2.4px;
    line-height: 19.2px; }

x:-o-prefocus,
.radial-bar {
  background-clip: content-box; }

/* ========================================================================
     Component: chart-flot
 ======================================================================== */
.flot-chart {
  display: block;
  width: 100%;
  height: 250px; }
  .flot-chart .legend > table tr td {
    padding: 3px; }
  .flot-chart .legend > table tr td:first-child {
    padding-left: 3px; }
  .flot-chart .legend > table tr td:last-child {
    padding-right: 3px; }
  .flot-chart .legend > table tr + tr td {
    padding-top: 0; }
  .flot-chart .legend > div:first-child {
    border-color: rgba(0, 0, 0, 0.1) !important; }
  .flot-chart .legend .legendColorBox > div,
  .flot-chart .legend .legendColorBox > div > div {
    border-radius: 400px; }

.flot-chart-content {
  width: 100%;
  height: 100%; }

.flot-pie-label {
  padding: 3px 5px;
  font-size: 10px;
  text-align: center;
  color: #fff; }

#flotTip {
  position: relative;
  padding: 5px;
  font-size: 12px !important;
  border-radius: 2px !important;
  border-color: transparent !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  color: #f1f1f1;
  z-index: 5; }

/* ========================================================================
   Component: chart-easypie
 ========================================================================== */
.easypie-chart {
  display: inline-block;
  position: relative;
  padding: 0 6px; }
  .easypie-chart span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    margin-left: -50%;
    height: 30px;
    margin-top: -15px;
    font-size: 20px; }
  .easypie-chart canvas {
    max-width: 100%; }
  .easypie-chart.easypie-chart-lg span {
    font-size: 40px;
    margin-top: -25px;
    line-height: 40px; }
  .easypie-chart.easypie-chart-md span {
    font-size: 30px;
    margin-top: -25px;
    line-height: 40px; }

/* ========================================================================
     Component: form-validation
 ========================================================================== */
.input-group.with-focus .form-control {
  transition: none; }
  .input-group.with-focus .form-control:focus + .input-group-append > .input-group-text {
    border-color: #66afe9; }
  .input-group.with-focus .form-control.is-invalid + .input-group-append > .input-group-text {
    border-color: #F44336; }
  .input-group.with-focus .form-control.is-valid + .input-group-append > .input-group-text {
    border-color: #00a97a; }

/* ========================================================================
     Component: form-tags
 ========================================================================== */
/* bootstrap tag input */
.bootstrap-tagsinput {
  box-shadow: none;
  padding: 8px 7px;
  width: 100%;
  border-color: #DDE6E9;
  height: auto;
  min-height: 2.1875rem; }
  .bootstrap-tagsinput .label-info {
    background-color: #0092E4 !important;
    display: inline;
    padding: .2rem .6rem .3rem;
    font-size: 0.625rem;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem; }

.bootstrap-tagsinput .tag [data-role="remove"]:hover:active {
  box-shadow: 0 0 0 #000; }

.bootstrap-tagsinput .tag {
  color: #fff; }

/* ========================================================================
     Component: form-datepicker
 ========================================================================== */
.datepicker {
  padding: .5rem; }
  .datepicker th {
    font-size: 0.875rem !important; }

.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.selected, .datepicker table tr td.selected:hover, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover,
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-image: none; }

.datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active:hover.disabled, .datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled:hover.disabled, .datepicker table tr td span.active[disabled], .datepicker table tr td span.active:hover[disabled], .datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #ff5a8a; }

.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover {
  background-color: #ff5a8a !important;
  background-image: none;
  box-shadow: none;
  text-shadow: none; }

.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
  background-color: #e4eaec; }

.datepicker-inline {
  border: 0.125rem solid #e4eaec; }

.datepicker table tr td.active, .datepicker table tr td.active:hover, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover {
  background-color: #ff5a8a !important;
  background-image: none;
  box-shadow: none; }

.datepicker-dropdown {
  /*rtl:ignore*/
  right: auto; }

/* ========================================================================
     Component: form-imgcrop
 ========================================================================== */
.img-container,
.img-preview {
  background-color: #f7f7f7;
  overflow: hidden;
  width: 100%;
  text-align: center; }

.img-container {
  min-height: 200px;
  max-height: 340px;
  margin-bottom: 20px; }

.docs-preview {
  margin-right: -15px;
  margin-bottom: 10px; }

.img-preview {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px; }

.img-preview > img {
  max-width: 100%; }

.preview-lg {
  width: 263px;
  height: 148px; }

.preview-md {
  width: 139px;
  height: 78px; }

.preview-sm {
  width: 69px;
  height: 39px; }

.preview-xs {
  width: 35px;
  height: 20px;
  margin-right: 0; }

.cropper-container img {
  display: block;
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  height: 100%;
  min-height: 0 !important;
  max-height: none !important;
  image-orientation: 0deg !important; }

/* ========================================================================
     Component: dropzone
 ========================================================================== */
.dropzone {
  border: 0; }
  .dropzone .dz-default.dz-message {
    font-size: 35px; }
  .dropzone .dz-preview.dz-image-preview .dz-image {
    border-radius: 1px; }
  .dropzone .dz-preview.dz-image-preview .dz-details {
    position: relative;
    line-height: 1;
    padding: 6px 4px;
    margin: 0;
    opacity: 1;
    font-size: 14px; }
  .dropzone .dz-preview .dz-image img {
    transition: -webkit-transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease; }
  .dropzone .dz-preview:hover .dz-image img {
    -webkit-filter: none;
    filter: none;
    -webkit-transform: scale(2);
            transform: scale(2);
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease; }
  .dropzone .dz-preview.dz-file-preview .dz-image {
    border-radius: 1px;
    background-image: none;
    background-color: #F5F5F5; }
  .dropzone .dz-filename {
    display: none; }
  .dropzone .dz-preview .dz-remove {
    color: #515151;
    font-size: 12px; }
    .dropzone .dz-preview .dz-remove:hover {
      color: #F44336;
      text-decoration: none; }
  .dropzone .dz-preview .dz-error-message {
    top: 130px;
    border-radius: 2px;
    transition: all .2s ease; }
    .dropzone .dz-preview .dz-error-message:after {
      display: none; }
  .dropzone .dz-preview.dz-error:hover .dz-error-message {
    top: 100px; }

/* ========================================================================
    Component: plugins
    - Use this stylesheet to include single css styles for plugins
 ========================================================================== */
/**
 * Bootstrap Slider
 */
body {
  /**
   * Chosen
   */
  /**
   * Filestyle
   */ }
  body .slider-handle,
  body .slider-track,
  body .slider-selection {
    background-image: none;
    box-shadow: 0 0 0 #000; }
  body .slider-handle {
    background-color: #fff;
    opacity: 1; }
  body .slider-track {
    background-color: #fff; }
  body .slider-selection {
    background-color: #ff5a8a; }
  body .slider .tooltip.top .tooltip-arrow {
    border-top-color: #2c3037; }
  body .slider .tooltip.right .tooltip-arrow {
    border-right-color: #2c3037; }
  body .slider .tooltip-inner {
    background-color: #2c3037; }
  body .slider .tooltip.in {
    opacity: 1; }
  body .slider-fw .slider {
    width: 100%; }
  body .chosen-container {
    width: 100% !important;
    max-width: 100%; }
  body .chosen-container-multi .chosen-choices {
    display: block;
    width: 100%;
    height: 2.1875rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.52857;
    color: #495057;
    background-color: #fff;
    background-image: none;
    border: 1px solid #DDE6E9;
    border-radius: 0.25rem;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    font-size: 0.875rem; }
    @media (prefers-reduced-motion: reduce) {
      body .chosen-container-multi .chosen-choices {
        transition: none; } }
    body .chosen-container-multi .chosen-choices:focus {
      color: #495057;
      background-color: #fff;
      border-color: #66afe9;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.25); }
    body .chosen-container-multi .chosen-choices[disabled], body .chosen-container-multi .chosen-choices[readonly],
    fieldset[disabled] body .chosen-container-multi .chosen-choices {
      cursor: not-allowed;
      background-color: #F5F5F5;
      opacity: 1; }
  body .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    height: auto;
    padding: 0; }
  body .chosen-container-multi .chosen-choices li.search-choice {
    margin: 0 5px 3px 0;
    background-color: #f5f6f7;
    border: 1px solid #e7e9ec;
    background-image: none; }
  body .chosen-container .chosen-results li.highlighted {
    background-color: #a1a2a3;
    background-image: none; }
  body .chosen-container .chosen-drop {
    border-color: #ddd; }
  body .chosen-container .chosen-results li.group-result {
    color: #747576;
    letter-spacing: 0.02em; }
  body .chosen-container-single .chosen-single,
  body .chosen-container-active.chosen-with-drop .chosen-single {
    display: block;
    width: 100%;
    height: 2.1875rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.52857;
    color: #495057;
    background-color: #fff;
    background-image: none;
    border: 1px solid #DDE6E9;
    border-radius: 0.25rem;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    box-shadow: 0 0 0 #000; }
    @media (prefers-reduced-motion: reduce) {
      body .chosen-container-single .chosen-single,
      body .chosen-container-active.chosen-with-drop .chosen-single {
        transition: none; } }
    body .chosen-container-single .chosen-single:focus,
    body .chosen-container-active.chosen-with-drop .chosen-single:focus {
      color: #495057;
      background-color: #fff;
      border-color: #66afe9;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.25); }
    body .chosen-container-single .chosen-single[disabled], body .chosen-container-single .chosen-single[readonly],
    fieldset[disabled] body .chosen-container-single .chosen-single,
    body .chosen-container-active.chosen-with-drop .chosen-single[disabled],
    body .chosen-container-active.chosen-with-drop .chosen-single[readonly],
    fieldset[disabled]
    body .chosen-container-active.chosen-with-drop .chosen-single {
      cursor: not-allowed;
      background-color: #F5F5F5;
      opacity: 1; }
  body .chosen-container-active .chosen-single,
  body .chosen-container-active .chosen-choices {
    border: 1px solid #66afe9;
    box-shadow: 0 0 0 #000; }
  body .chosen-container-single .chosen-drop {
    margin-top: -3px; }
  body .chosen-container-single .chosen-single div {
    top: 6px;
    right: 5px; }
  body .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    color: #b7bac9; }
  body .bootstrap-filestyle {
    max-width: 380px; }
  body .select2-container {
    max-width: 100%; }
  body .select2-container--bootstrap .select2-selection--single,
  body .select2-container--bootstrap .select2-selection--multiple {
    border: 1px solid #DDE6E9;
    box-shadow: 0 0 0 #000 !important; }

/* ========================================================================
     Component: datatable
 ========================================================================== */
.dataTables_wrapper {
  clear: both; }

table.dataTable {
  border-collapse: collapse !important; }

.dataTables_filter {
  text-align: center !important; }
  @media (min-width: 768px) {
    .dataTables_filter {
      float: right;
      text-align: right !important; } }
  .dataTables_filter label > em {
    color: inherit; }
  .dataTables_filter input {
    border: 1px solid rgba(160, 160, 160, 0.25);
    margin-left: 10px;
    padding: 0.25rem .5rem;
    color: inherit; }

.dataTables_length {
  text-align: center; }
  @media (min-width: 768px) {
    .dataTables_length {
      float: left; } }
  .dataTables_length select {
    border: 1px solid rgba(160, 160, 160, 0.25);
    margin-right: 10px;
    padding: 0.25rem .5rem;
    color: inherit;
    width: 80px !important; }

.dt-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: .5rem; }
  @media (min-width: 768px) {
    .dt-buttons {
      margin-bottom: 0;
      justify-content: start;
      float: left; } }

.dataTables_info {
  text-align: center;
  padding-top: .75rem; }
  @media (min-width: 768px) {
    .dataTables_info {
      float: left; } }

.dataTables_wrapper .dataTables_paginate {
  display: flex;
  justify-content: center; }
  @media (min-width: 992px) {
    .dataTables_wrapper .dataTables_paginate {
      justify-content: flex-end; } }

.dataTables_wrapper .dataTables_paginate .paginate_button > a {
  box-shadow: 0 0 0 #000 !important; }

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled > a {
  color: #DDE6E9; }

div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 2px solid #DDE6E9;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 2100; }
  div.dt-button-info h2 {
    padding: 0.5em;
    margin: 0;
    font-weight: normal;
    border-bottom: 1px solid #ddd;
    background-color: #f3f3f3; }
    div.dt-button-info h2:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      color: #00a97a;
      margin-right: 8px; }
  div.dt-button-info > div {
    padding: 1em; }

table.dataTable th.focus, table.dataTable td.focus {
  outline: 3px solid #ff5a8a;
  outline-offset: -1px; }

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
  color: #474747;
  border: 0;
  box-shadow: 0 0 0 #000;
  background-color: transparent; }

/* ========================================================================
     Component: table-extras
 ========================================================================== */
.table > thead > tr > th {
  padding: 8px;
  color: #888; }

.table > tbody > tr > td {
  vertical-align: middle; }
  .table > tbody > tr > td > .media img {
    width: 36px;
    height: 36px;
    margin: 0 auto; }

.table .checkbox {
  margin: 0 auto;
  width: 20px; }

.table .progress {
  margin-bottom: 0; }

.table .radial-bar {
  margin-bottom: 0;
  margin: 0 auto; }

.jqstooltip {
  box-sizing: content-box; }

/*
#table-ext-1 {
    th {
        &:nth-child(1) {
            width: 3%;
        }

        &:nth-child(2) {
            width: 5%;
        }

        &:nth-child(7) {
            width: 5%;
        }

        &:nth-child(9) {
            width: 5%;
        }

    }

}
*/
#table-ext-2 th:nth-child(1) {
  width: 5%; }

#table-ext-2 th:nth-child(3) {
  width: 10%; }

/* ========================================================================
     Component: gmap
 ========================================================================== */
.gmap {
  height: 300px; }
  .gmap.gmap-sm {
    height: 150px; }

/* ========================================================================
     Component: vector-map
 ========================================================================== */
body .jvectormap-label {
  position: absolute;
  display: none;
  border: solid 1px #313232;
  border-radius: 2px;
  background: #313232;
  color: white;
  padding: 3px 6px;
  opacity: 0.9;
  z-index: 1100; }

body .jvectormap-zoomin, body .jvectormap-zoomout {
  position: absolute;
  left: 10px;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  background: #515253;
  padding: 5px;
  color: white;
  cursor: pointer;
  line-height: 10px;
  text-align: center; }

body .jvectormap-zoomin {
  top: 10px; }

body .jvectormap-zoomout {
  top: 30px; }

/* ========================================================================
     Component: timeline
 ========================================================================== */
.timeline,
.timeline-alt {
  list-style: none;
  padding: 0 0 20px;
  position: relative;
  z-index: 0; }
  .timeline:before,
  .timeline-alt:before {
    position: absolute;
    top: 0;
    bottom: 0;
    content: " ";
    width: 1px;
    background-color: #d1d2d3;
    left: 18px; }
  .timeline .timeline-end,
  .timeline-alt .timeline-end {
    float: none;
    clear: both; }
  .timeline .popover.left > .arrow:after,
  .timeline-alt .popover.left > .arrow:after {
    border-left-color: #fff; }
  .timeline .popover.right > .arrow:after,
  .timeline-alt .popover.right > .arrow:after {
    border-right-color: #fff; }
  .timeline .popover.left > .popover-title + .arrow:after,
  .timeline-alt .popover.left > .popover-title + .arrow:after {
    border-left-color: #fff; }
  .timeline .popover.right > .popover-title + .arrow:after,
  .timeline-alt .popover.right > .popover-title + .arrow:after {
    border-right-color: #fff; }
  .timeline > li,
  .timeline-alt > li {
    margin-bottom: 20px; }
    .timeline > li::after,
    .timeline-alt > li::after {
      display: block;
      clear: both;
      content: ""; }
    .timeline > li.timeline-separator,
    .timeline-alt > li.timeline-separator {
      position: relative;
      float: none;
      clear: both;
      margin: 0 auto;
      padding: 40px 0; }
      .timeline > li.timeline-separator:first-child,
      .timeline-alt > li.timeline-separator:first-child {
        padding-top: 0; }
      .timeline > li.timeline-separator:before,
      .timeline-alt > li.timeline-separator:before {
        content: attr(data-datetime);
        display: inline-block;
        width: 120px;
        text-align: center;
        padding: 0 20px;
        line-height: 30px;
        background-color: #b6c5da;
        color: #fff; }
  .timeline > li > .timeline-card,
  .timeline-alt > li > .timeline-card {
    margin-left: 60px; }
    .timeline > li > .timeline-card .popover,
    .timeline-alt > li > .timeline-card .popover {
      position: relative;
      display: block;
      margin: 0;
      width: 100%;
      max-width: none; }
      .timeline > li > .timeline-card .popover .arrow,
      .timeline-alt > li > .timeline-card .popover .arrow {
        top: 18px;
        display: none; }
      .timeline > li > .timeline-card .popover .popover-content .popover-title,
      .timeline-alt > li > .timeline-card .popover .popover-content .popover-title {
        background-color: transparent;
        border: 0;
        padding-left: 0;
        padding-top: 0; }
  .timeline > li > .timeline-date,
  .timeline-alt > li > .timeline-date {
    margin: -20px 0 0;
    display: block;
    height: 20px;
    line-height: 20px;
    font-size: 13px; }
    .timeline > li > .timeline-date > time:after,
    .timeline-alt > li > .timeline-date > time:after {
      content: attr(datetime); }
  .timeline > li > .timeline-badge,
  .timeline-alt > li > .timeline-badge {
    position: absolute;
    left: 0;
    width: 36px;
    height: 36px;
    border-radius: 500px;
    line-height: 36px;
    font-size: 1em;
    text-align: center;
    color: #fff;
    background-color: #999;
    z-index: 100; }
    .timeline > li > .timeline-badge.primary,
    .timeline-alt > li > .timeline-badge.primary {
      background-color: #ff5a8a; }
    .timeline > li > .timeline-badge.success,
    .timeline-alt > li > .timeline-badge.success {
      background-color: #00a97a; }
    .timeline > li > .timeline-badge.warning,
    .timeline-alt > li > .timeline-badge.warning {
      background-color: #F57C00; }
    .timeline > li > .timeline-badge.danger,
    .timeline-alt > li > .timeline-badge.danger {
      background-color: #F44336; }
    .timeline > li > .timeline-badge.info,
    .timeline-alt > li > .timeline-badge.info {
      background-color: #0092E4; }
    .timeline > li > .timeline-badge.inverse,
    .timeline-alt > li > .timeline-badge.inverse {
      background-color: #690E2C; }
    .timeline > li > .timeline-badge.green,
    .timeline-alt > li > .timeline-badge.green {
      background-color: #1EE831; }
    .timeline > li > .timeline-badge.pink,
    .timeline-alt > li > .timeline-badge.pink {
      background-color: #EC6592; }
    .timeline > li > .timeline-badge.purple,
    .timeline-alt > li > .timeline-badge.purple {
      background-color: #CC14F5; }
    .timeline > li > .timeline-badge.dark,
    .timeline-alt > li > .timeline-badge.dark {
      background-color: #474747; }
    .timeline > li > .timeline-badge.yellow,
    .timeline-alt > li > .timeline-badge.yellow {
      background-color: #FAD732; }

.timeline-alt .popover .arrow {
  display: block !important; }

.timeline-title {
  margin-top: 0;
  color: inherit; }

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0; }

.timeline-body > p + p {
  margin-top: 5px; }

@media (min-width: 992px) {
  .timeline:before {
    left: 50%;
    margin-left: 18px; }
  .timeline > li {
    float: left;
    clear: left;
    width: 45%; }
    .timeline > li.timeline-inverted {
      float: right;
      clear: right;
      margin-top: 50px; }
  .timeline > li.timeline-separator {
    text-align: center; }
    .timeline > li.timeline-separator:before {
      margin-left: 36px; }
  .timeline > li > .timeline-badge {
    left: 50%;
    margin-left: 0; }
  .timeline > li > .timeline-date {
    float: none;
    position: absolute;
    width: 40%;
    left: 50%;
    top: 18px;
    margin-left: 54px;
    margin-top: -10px; }
  .timeline > li > .timeline-card {
    margin: 0; }
    .timeline > li > .timeline-card:before {
      border-left-width: 15px;
      border-right-width: 0;
      top: 26px;
      right: -15px;
      left: auto; }
    .timeline > li > .timeline-card:after {
      border-left-width: 14px;
      border-right-width: 0;
      top: 27px;
      right: -14px;
      left: auto; }
    .timeline > li > .timeline-card .popover .arrow {
      display: block; }
  .timeline > li.timeline-inverted > .timeline-card {
    padding-left: 36px; }
  .timeline > li.timeline-inverted .timeline-date {
    left: auto;
    right: 50%;
    width: auto;
    margin-left: 0;
    margin-right: 36px; } }

/* ========================================================================
     Component: todo
 ========================================================================== */
.todo .todo-item-list {
  position: relative; }

.todo .todo-item .todo-edit {
  display: none;
  cursor: pointer; }

.todo .todo-item:hover .todo-edit {
  display: inline; }

.todo .todo-item.todo-complete .todo-edit {
  display: none; }

.todo .close {
  font-size: 1rem; }

.todo textarea {
  resize: none;
  max-width: 100%;
  min-width: 100%; }

.todo .todo-complete {
  background: #F5F5F5;
  opacity: .6;
  color: #474747; }
  .todo .todo-complete .todo-title {
    text-decoration: line-through; }

/* ========================================================================
     Component: calendar
 ========================================================================== */
.calendar-app .fc-button {
  height: auto;
  background-image: none;
  padding: 5px 10px; }
  .calendar-app .fc-button:active, .calendar-app .fc-button.active, .calendar-app .fc-button.fc-state-active {
    outline: 0;
    background-image: none; }

.calendar-app .fc-button .fc-icon {
  margin: 0 .1em;
  font-size: inherit;
  font-family: "Font Awesome 5 Free" !important;
  vertical-align: baseline;
  color: #555;
  line-height: 1.5; }
  .calendar-app .fc-button .fc-icon:after {
    display: none; }

.calendar-app .fc-state-highlight,
.calendar-app .fc-day:hover {
  background-color: #f7f8f9; }

.calendar-app .fc-day-number {
  font-size: 18px;
  font-weight: bold;
  color: #a1a2a3; }

.calendar-app .fc-event {
  color: #fff !important; }

.calendar-app .fc-view {
  overflow: visible; }

.calendar-app .external-events {
  margin: 0; }
  .calendar-app .external-events > div {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 6px 10px;
    color: #fff;
    font-size: 11px;
    border-radius: 2px;
    cursor: move; }
  .calendar-app .external-events:empty {
    content: "EMRTPUY"; }

.calendar-app .external-event-color-selector .circle.selected {
  border: 3px solid rgba(255, 255, 255, 0.5); }

/* ========================================================================
     Component: mailbox
 ========================================================================== */
.mb-boxes {
  overflow: hidden; }
  .mb-boxes .nav > li > a {
    font-weight: 600;
    color: #474747;
    margin: 5px 0;
    transition: background-color 0.3s ease; }
    @media (prefers-reduced-motion: reduce) {
      .mb-boxes .nav > li > a {
        transition: none; } }
    .mb-boxes .nav > li > a:hover, .mb-boxes .nav > li > a:focus {
      background-color: #C0D0D3; }
  .mb-boxes .nav > li.active > a {
    background-color: #ff5a8a !important;
    color: #fff; }
  @media (min-width: 992px) {
    .mb-boxes {
      height: auto !important;
      visibility: visible !important;
      display: block !important; } }

@media (min-width: 992px) {
  .mb-compose-button {
    display: block;
    width: 100%; }
  .mb-toggle-button {
    display: none; } }

.mb-mails > tbody > tr > td {
  border-top-color: transparent;
  cursor: pointer; }

.mb-mails .mb-mail-active {
  background-color: #f7f8f9 !important; }

.mb-mails .mb-mail-avatar {
  float: left;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 3px; }

.mb-mails .mb-mail-date {
  display: block;
  float: right;
  color: #515151;
  font-size: 12px;
  font-weight: bold; }

.mb-mails .mb-mail-from {
  font-size: 14px;
  font-weight: 600;
  line-height: 1; }

.mb-mails .mb-mail-meta {
  overflow: hidden; }

.mb-mails .mb-mail-subject {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 6px; }

.mb-mails .mb-mail-preview {
  clear: both;
  margin-top: 10px;
  color: #515151;
  margin-top: 2px; }

.mb-mails .mb-attachment {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #e4eaec; }

.mb-view > tbody > tr > td {
  cursor: auto; }

.mb-view .mb-mail-to-list > a {
  margin-right: 5px;
  color: #515151; }

/* ========================================================================
   Component: Plan table
 ========================================================================== */
.plan {
  background-color: #F5F5F5;
  overflow: auto;
  border-radius: 0.25rem;
  padding-bottom: 30px; }
  .plan .plan-header {
    background-color: #0092E4;
    color: #fff;
    margin: 0;
    padding: 20px 10px;
    text-align: center; }
  .plan .plan-price {
    background: #fff;
    padding: 16px 8px;
    text-align: center; }
    .plan .plan-price h2 {
      margin: 0; }
  .plan .plan-period {
    font-size: 16px; }
  .plan hr {
    display: block;
    margin: 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    height: 0; }
  .plan .plan-description {
    padding: 0 24px;
    text-align: center; }
  .plan .plan-features {
    list-style-type: none;
    padding-left: 0;
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: left; }
    .plan .plan-features > li {
      padding: 8px 24px; }
      .plan .plan-features > li > em {
        color: #0092E4;
        margin-right: 7px; }
      .plan .plan-features > li:last-child {
        padding-bottom: 0; }
  .plan .plan-feature-disable {
    color: #F44336 !important; }

.mol-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 1.5rem;
  min-width: 600px; }
  @media (max-width: 600px) {
    .mol-modal-box {
      border: 1px solid red;
      min-width: 90vw; } }

.codex-editor {
  width: 100%;
  color: black; }

.ce-block__content {
  max-width: calc(100% - 4rem); }

.ce-toolbar__content {
  position: absolute;
  left: 2rem; }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: rgba(0, 0, 0, 0.26);
  cursor: default;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga'; }

.md-gender {
  font-size: 28px; }

.md-pso {
  font-size: 20px;
  color: #666; }

.md-active {
  font-family: 'Material Icons';
  color: #ff5a8a; }

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
  color: #666;
  cursor: default; }

.material-icons-two-tone {
  font-family: 'Material Icons Two Tone';
  cursor: default; }

.md-dashboard {
  color: rgba(0, 0, 0, 0.87); }

