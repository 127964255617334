.swal-modal {
  width: 380px;
  border-radius: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.swal-title {
  color: $gray-darker-medium;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Roboto';
  padding: 0px;
}

.swal-title:not(:last-child) {
  margin-bottom: 16px
}

.swal-text {
  color: $gray-darker-medium;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
}

.swal-footer {
  text-align: center;
  padding: 0px;
  margin-top: 24px;
}

.swal-button {
  border-radius: 50px;
}

.swal-button--cancel,
.swal-button--close {
  background-color: transparent;
  color: $gray-darker-light;
  border: 1px solid $gray-darker-light;
  min-width: 100px;
  height: 43px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: transparent !important;
    color: $gray-darker-light !important;
    border: 1px solid $gray-darker-light !important;
    opacity: 0.9;
  }

}

.swal-button--confirm {
  background-color: $primary !important;
  min-width: 100px;
  height: 43px;

  &:hover {
    background-color: $primary !important;
    opacity: 0.9;
  }
}


.swal-icon--info {
  bottom: 4px;
  border-color: $gray-darker-medium;
  width: 40px;
  height: 40px;
}

.swal-icon--info:before {
  width: 4px;
  height: 22px;
  bottom: 4px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: $gray-darker-medium;
}

.swal-icon--info:after {
  top: 4px;
  left: 21px;
  width: 4px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  background-color: $gray-darker-medium;
}

.swal-icon {
  margin: 0 auto;
  margin-bottom: 16px;
  border-width: 2px;
}

.swal-icon:first-child,
.swal-text:first-child,
.swal-title:first-child {
  margin-top: 0px;
}

.swal-icon--error__line--left {
  left: 1px;
  transform: rotate(45deg);

}

.swal-icon--error__line {
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: $danger-light;
  border-radius: 2px;
  top: 38px;
}

.swal-icon--error__x-mark {
  position: relative;
  display: block;
  bottom: 19px;
  left: 7px;
}

.swal-icon--error {
  border-color: $danger-light;
  width: 40px;
  height: 40px;
}


.swal-icon--warning {
  border-color: $warning-light;
  width: 40px;
  height: 40px;
}

.swal-icon--warning__dot,
.swal-icon--warning__body {
  background-color: $warning-light;
}

.swal-icon--warning__dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: -1.825px;
  bottom: -11px;
}

.swal-icon--warning__body {
  top: 6px;
  height: 18px;
  width: 3px;
}

.swal-icon--success {
  margin-bottom: 0;
  z-index: 0;
}

.swal-icon--success__ring {
  width: 40px;
  height: 40px;
  top: 16px;
  left: 16px;
  border: 3px solid $success;
}

.swal-icon--success__line--long {
  width: 24px;
  top: 38px;
  right: 24px;
  background-color: $success;
}

.swal-icon--success__line--tip {
  width: 14px;
  left: 24px;
  top: 41px;
  background-color: $success;
}

.swal-icon--success__line {
  height: 3px;
}