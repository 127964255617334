.mol-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #000;
  box-shadow: 24;
  padding: 1.5rem;
  min-width: 600px;

  @media (max-width: 600px){
    border: 1px solid red;
    min-width: 90vw;
  }
}