/* ========================================================================
     Component: progress bar for big5
 ========================================================================== */

.progress {
  background-color: #f1f1f1;
}

.progress.left .progress-bar {
  width: 50%;
}

.progress.right .progress-bar {
  float: right;
  flex-direction: row-reverse;
  width: 50%;
}

.big5-progress-margin-right {
  margin-right: 0;
  padding-right: 0;
}

.big5-progress-margin-left {
  margin-left: 0;
  padding-left: 0;
  border-left: 1px #656565 dotted;
}