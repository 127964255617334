/* ========================================================================
     Component: radial-bar
 ========================================================================== */

//
// Pure CSS Radial Progress Bar
// Colors
$bar-color: $primary;

$radial-primary: $primary;
$radial-success: $success;
$radial-info: $info;
$radial-warning: $warning;
$radial-danger: $danger;

$overlay-bg: #fff;
$back-color: #fafafa;
$radial-size-base: 4;

$radial-font-size-lg: 20px;
$radial-font-size: 16px;
$radial-font-size-sm: 10px;
$radial-font-size-xs: 6px;

// step of % for created classes
$progress-step: 5;

// Mixins
// -----------------------
@mixin radial-progress-size($font-size) {
  $size: $font-size * $radial-size-base;
  $bar-size: $size * 0.2;
  width: $size;
  height: $size;
  font-size: $font-size + 2;
  &:after,
  > img {
    width: $size - $bar-size;
    height: $size - $bar-size;
    margin-left: $bar-size / 2;
    margin-top: $bar-size / 2;
    line-height: $size - $bar-size;
  }
}

@mixin radial-progress-variant($step, $color) {
  $loops: round(100 / $step);
  $increment: 360 / $loops;
  $half: round($loops / 2);
  @for $i from 0 through $loops {
    &.radial-bar-#{$i*$step} {
      @if $i < $half {
        $nextdeg: 90deg + ($increment * $i);
        background-image: linear-gradient(
            90deg,
            $back-color 50%,
            transparent 50%,
            transparent
          ),
          linear-gradient($nextdeg, $color 50%, $back-color 50%, $back-color);
      } @else {
        $nextdeg: -90deg + ($increment * ($i - $half));
        background-image: linear-gradient(
            $nextdeg,
            $color 50%,
            transparent 50%,
            transparent
          ),
          linear-gradient(270deg, $color 50%, $back-color 50%, $back-color);
      }
    }
  }
}

/* -------------------------------------
 * Bar container
 * ------------------------------------- */

.radial-bar {
  position: relative;
  display: inline-block;
  font-size: $radial-font-size; //width: $radial-size;
  //height: $radial-size;
  border-radius: 50%;
  border: 2px solid $back-color; // remove gradient color
  background-color: transparent; // default 100%
  margin-bottom: 10px; // margin-right: 20px;
  box-sizing: content-box; // Optional centered circle w/text
  &:after,
  > img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0; // width: $radial-size - $radial-bar-size;
    // height: $radial-size - $radial-bar-size;
    border-radius: 50%; // margin-left: $radial-bar-size / 2;
    // margin-top: $radial-bar-size / 2;
    text-align: center; // line-height: $radial-size - $radial-bar-size;
    font-weight: bold;
    color: #a1a2a3;
  }
  &:after {
    content: attr(data-label);
    background-color: $overlay-bg;
    z-index: 101;
  }
  > img {
    z-index: 102;
  }

  @include radial-progress-size($radial-font-size);
  @include radial-progress-variant($progress-step, $radial-primary);
}

// Variant
// -----------------------
.radial-bar-primary {
  @include radial-progress-variant($progress-step, $primary);
}

.radial-bar-success {
  @include radial-progress-variant($progress-step, $success);
}

.radial-bar-info {
  @include radial-progress-variant($progress-step, $info);
}

.radial-bar-warning {
  @include radial-progress-variant($progress-step, $warning);
}

.radial-bar-danger {
  @include radial-progress-variant($progress-step, $danger);
}

// Sizes
// -----------------------
.radial-bar-lg {
  @include radial-progress-size($radial-font-size-lg);
}

.radial-bar-sm {
  @include radial-progress-size($radial-font-size-sm);
}

.radial-bar-xs {
  @include radial-progress-size($radial-font-size-xs);
}

// hide excess of gradient in opera
x:-o-prefocus,
.radial-bar {
  background-clip: content-box;
}
