.codex-editor {
  width: 100%;
  color: black;
}
.ce-block__content {
  max-width: calc(100% - 4rem);
} 
.ce-toolbar__content {
  position: absolute;
  left: 2rem;
}