// Cutoms Gray colors for theme
$gray-darker: #474747; 
$gray: #DDE6E9;
$gray-light: #e4eaec;
$gray-lighter: #F5F5F5;
$gray-darker-medium:#6A6A6A;
$gray-darker-regular:#979797;
$gray-darker-light:#BDBDBD;
$gray-bg-lighter: #FAFBFC;

// black and white 
$black: #000;
$white: #FFFFFF;

// Custom brand colors for theme
$inverse: #690E2C;
$purple: #CC14F5;
$purple-dark: #9C27B0;
$pink: #EC6592;
$yellow: #FAD732;

$green: #1ee831;

$primary: #ff5a8a;
$secondary: #6a6a6a;

$success: #00a97a;
$success-light: #00a97a15;

$info: #0092E4;
$info-light: #F0FAFF;

$warning: #F57C00;
$warning-dark: #CB6700;
$warning-light: #FFF3E0;

$danger: #F44336;
$danger-dark:#D32F2F;
$danger-light: #FFCDD2;
$danger-lighter: #FFF4F6;

$green-dark: #04B000;
$green: #1EE831;
$green-light: #C8E6C9;
$green-lighter: #EBF7EB;

$blue: #55A8E6;

// Custom color variants
$amountOfLight: 10%;

$primary-light: lighten($primary, $amountOfLight);
$secondary-light: lighten($secondary, $amountOfLight);
$success-light: lighten($success, $amountOfLight);
$info-light: lighten($info, $amountOfLight);
$warning-light: lighten($warning, $amountOfLight);
$danger-light: lighten($danger, $amountOfLight);
$inverse-light: lighten($inverse, $amountOfLight);
$green-light: lighten($green, $amountOfLight);
$pink-light: lighten($pink, $amountOfLight);
$purple-light: lighten($purple, $amountOfLight);
$yellow-light: lighten($yellow, $amountOfLight);
$primary-dark: darken($primary, $amountOfLight);
$secondary-dark: darken($secondary, $amountOfLight);
$success-dark: darken($success, $amountOfLight);
$info-dark: darken($info, $amountOfLight);
$warning-dark: darken($warning, $amountOfLight);
$danger-dark: darken($danger, $amountOfLight);
$inverse-dark: darken($inverse, $amountOfLight);
$green-dark: darken($green, $amountOfLight);
$pink-dark: darken($pink, $amountOfLight);
$purple-dark: darken($purple, $amountOfLight);
$yellow-dark: darken($yellow, $amountOfLight);

// Theme variables

// used for sidebars and content spacing
$navbar-height: 48px;

// Button secondary redefinition style for theme
$btn-secondary-color: #333;
$btn-secondary-bg: #fff;
$btn-secondary-border: #eaeaea;

// Top Border color for custom default cards (.card-default)
$card-default-border-color: #cfdbe2;

// ---------------------
// Utilities variables
// ---------------------

$text-alpha: rgba(255, 255, 255, 0.5);
$text-alpha-inverse: rgba(0, 0, 0, 0.5);

$font-size-base: 1rem;
$font-size-h1: floor(($font-size-base * 2.15)); // ~36px
$font-size-h2: floor(($font-size-base * 1.7)); // ~30px
$font-size-h3: 1.5rem; // ~24px
$font-size-h4: ceil(($font-size-base * 1)); // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * 0.5)); // ~12px
$text-sm: $font-size-base * 0.74375;
$text-md: $font-size-base * 1.6625;
$text-lg: $font-size-base * 2.625;

$border-color: rgba(0, 0, 0, 0.12);

$wd-xxs: 60px;
$wd-xs: 90px;
$wd-sm: 150px;
$wd-sd: 200px; // sd == standard
$wd-md: 240px;
$wd-lg: 280px;
$wd-xl: 320px;
$wd-xxl: 360px;
$wd-wide: 100%;
$wd-auto: auto;
$wd-zero: 0;

// ---------------------
// Bootstrap 4 Variables Override
// ---------------------

// Color system

$colors: (
  "purple": $purple,
  "pink": $pink,
  "yellow": $yellow,
  "green": $green,
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "inverse": $inverse,
  "primary-light": $primary-light,
  "primary-dark": $primary-dark,
  "secondary-light": $secondary-light,
  "secondary-dark": $secondary-dark,
  "success-light": $success-light,
  "success-dark": $success-dark,
  "info-light": $info-light,
  "info-dark": $info-dark,
  "warning-light": $warning-light,
  "warning-dark": $warning-dark,
  "danger-light": $danger-light,
  "danger-dark": $danger-dark,
  "green-light": $green-light,
  "green-dark": $green-dark,
  "pink-light": $pink-light,
  "pink-dark": $pink-dark,
  "purple-light": $purple-light,
  "purple-dark": $purple-dark,
  "inverse-light": $inverse-light,
  "inverse-dark": $inverse-dark,
  "yellow-light": $yellow-light,
  "yellow-dark": $yellow-dark
);

$theme-colors: (
  "primary-light": $primary-light,
  "primary": $primary,
  "primary-dark": $primary-dark,
  "secondary-light": $secondary-light,
  "secondary": $secondary,
  "secondary-dark": $secondary-dark,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "purple-light": $purple-light,
  "purple": $purple,
  "purple-dark": $purple-dark,
  "inverse": $inverse,
  "pink": $pink,
  "green": $green,
  "blue": $blue,
);

// Body

$body-bg: #fafbfc;
$body-color: #656565;

$border-radius: 0.25rem;
$border-radius-lg: 0.375rem;

$line-height-base: 1.528571429;

$headings-line-height: 1.1;

$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

//$text-muted: #909fa7;
$text-muted: $secondary-dark;

// Tables

$table-accent-bg: #fafbfc;
$table-hover-bg: #fafbfc;

$table-border-color: #eee;

// Buttons + Forms

$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: 0.3125rem;
$input-btn-padding-x-sm: 0.625rem;

$input-btn-padding-y-lg: 0.625rem;
$input-btn-padding-x-lg: 1.25rem;

$input-height: 2.1875rem; // 35px

// Forms

$input-disabled-bg: $gray-lighter;
$input-border-color: $gray;
$input-focus-border-color: #66afe9;
$input-placeholder-color: #b7bac9;

$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;

$custom-control-indicator-active-bg: lighten($primary, 25%);

// Dropdowns

$dropdown-border-color: #e1e1e1;

// Navs

$nav-tabs-link-active-bg: #fff;

// Jumbotron

$jumbotron-bg: #fcfcfc;

// List group

$list-group-border-color: #e4eaec;

// Image thumbnails

$thumbnail-padding: 0.125rem;

// Breadcrumbs

$breadcrumb-bg: #fafafa;
$breadcrumb-divider-color: #c1c2c3;
$breadcrumb-active-color: $body-color;

//== Panels
//
//##
$panel-global-border: #cfdbe2;

$panel-bg: #fff;
$panel-body-padding: 15px;
$panel-heading-padding: 10px 15px;
$panel-footer-padding: $panel-heading-padding;
$panel-border-radius: $border-radius;

$panel-footer-bg: #fafafa;
$panel-inner-border: #eee;

$panel-default-text: #444;
$panel-default-border: $panel-global-border;
$panel-default-heading-bg: #fff;
$panel-primary-border: $panel-global-border;
$panel-primary-text: #fff;
$panel-primary-heading-bg: $primary;

$panel-info-text: #fff;
$panel-info-border: $panel-global-border;
$panel-info-heading-bg: $info;

$panel-inner-border: #eee;
$panel-footer-bg: #fafafa;

$panel-default-text: #444;
$panel-default-border: $panel-global-border;
$panel-default-heading-bg: #fff;

$panel-primary-text: #fff;
$panel-primary-border: $panel-global-border;
$panel-primary-heading-bg: $primary;

$panel-success-text: #fff;
$panel-success-border: $panel-global-border;
$panel-success-heading-bg: $success;

$panel-warning-text: #fff;
$panel-warning-border: $panel-global-border;
$panel-warning-heading-bg: $warning;

$panel-danger-text: #fff;
$panel-danger-border: $panel-global-border;
$panel-danger-heading-bg: $danger;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: (
  $screen-sm-min - 1
);
$screen-sm-max: (
  $screen-md-min - 1
);
$screen-md-max: (
  $screen-lg-min - 1
);