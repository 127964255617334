$urlFont: "https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp";
@import url($urlFont);

@import "../common/variables.scss";

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    color: rgba(0, 0, 0, 0.26);
    cursor: default;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';

  }

.md-gender {
  font-size: 28px; 

}

 .md-pso {
    font-size: 20px; 
    color: #666;
  }

.md-active {
    font-family: 'Material Icons';
    color: $primary;
  }

.material-icons-outlined {
    font-family: 'Material Icons Outlined';
    color: #666;
    cursor: default;
  }

.material-icons-two-tone {
  font-family: 'Material Icons Two Tone';
  cursor: default;
}

.md-dashboard {
  color: rgba(0, 0, 0, 0.87);
}